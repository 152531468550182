import { collection, doc, getDoc, getDocs, writeBatch, serverTimestamp, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import { LootDrop } from "../interfaces";
import { LogActions, logAction } from "../services/logginServices";
import { determineRollWinner, determineDKPWinner } from "./handleDistributeNew";


interface HandleAutoDistributeProps {
    sessionId: string;
    user: any;
    selectedGuild: string;
    setSuccess: (message: string) => void;
}

export const handleAutoDistribute = async ({
    sessionId,
    user,
    selectedGuild,
    setSuccess
}: HandleAutoDistributeProps) => {
    if (!sessionId || !user || !selectedGuild) return;

    const lootDropsRef = collection(db, "sessions", sessionId, "lootDrops");
    const lootDropsSnapshot = await getDocs(lootDropsRef);
    const batch = writeBatch(db);
    const aggregateRef = doc(db, "sessions", sessionId, "aggregates", "distribution");
    const aggregateDoc = await getDoc(aggregateRef);

    for (const lootDoc of lootDropsSnapshot.docs) {
        const lootData = lootDoc.data() as LootDrop;
        if (lootData.status === "open") {
            let winner;

            if (lootData.lootMethod === "roll") {
                winner = await determineRollWinner(sessionId, lootDoc.id, batch);
            } else if (lootData.lootMethod === "dkp") {
                winner = await determineDKPWinner(sessionId, lootDoc.id, selectedGuild, batch);
            }

            if (winner) {
                const winnerRef = doc(db, "userGuilds", `${winner.uid}_${selectedGuild}`);
                batch.update(winnerRef, {
                    wonItems: arrayUnion(lootData.itemName)
                });

                const distributionData = {
                    winnerId: winner.uid,
                    winnerName: winner.name,
                    method: winner.method,
                    timestamp: serverTimestamp()
                };

                if (!aggregateDoc.exists()) {
                    batch.set(aggregateRef, {
                        distributedItems: {
                            [lootDoc.id]: distributionData
                        }
                    });
                } else {
                    batch.update(aggregateRef, {
                        [`distributedItems.${lootDoc.id}`]: distributionData
                    });
                }

                batch.update(doc(lootDropsRef, lootDoc.id), {
                    status: "distributed",
                    winnerName: winner.name,
                    distributedAt: serverTimestamp(),
                    distributedBy: user.uid
                });

            }
        }
    }

    await batch.commit();
    setSuccess("Auto-distribution completed successfully");
};
