import { doc, getDoc, getDocs, collection, updateDoc, DocumentData } from 'firebase/firestore';
import { db } from '../firebase';
import { EventData } from '../components/events/Event';

export const checkEventRegistration = async (
  sessionId: string,
  userId: string,
  selectedGuild: string
): Promise<boolean> => {
  try {
    const sessionRef = doc(db, 'sessions', sessionId);
    const sessionDoc = await getDoc(sessionRef);
    const sessionData = sessionDoc.data();

    if (!sessionData?.eventId) {
      return true;
    }

    const eventRef = doc(db, 'events', selectedGuild, 'guildEvents', sessionData.eventId);
    const eventDoc = await getDoc(eventRef);
    const eventData = eventDoc.data() as EventData;

    return eventData?.registeredUsers?.includes(userId) || false;
  } catch (error) {
    console.error('Error checking event registration:', error);
    return false;
  }
};

export const getAvailableEvents = async (guildId: string) => {
  const eventsRef = collection(db, 'events', guildId, 'guildEvents');
  const snapshot = await getDocs(eventsRef);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...(doc.data() as DocumentData)
  }));
};

export const linkEventToSession = async (
  sessionId: string,
  eventId: string
) => {
  const sessionRef = doc(db, 'sessions', sessionId);
  await updateDoc(sessionRef, {
    eventId: eventId
  });
};

export const getLinkedEvent = async (
  guildId: string,
  eventId: string
) => {
  const eventRef = doc(db, 'events', guildId, 'guildEvents', eventId);
  const eventDoc = await getDoc(eventRef);

  return eventDoc.exists() ? { id: eventDoc.id, ...(eventDoc.data() as DocumentData) } : null;
};
