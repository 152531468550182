
//src\services\logginServices.ts
import { setDoc, doc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

interface LogEntry {
  action: string;
  actor: string;
  details: string;
  sessionId: string;
  guild: string;
}

export const logAction = async (logEntry: LogEntry) => {
  if (!logEntry.guild) {
    console.error('Guild ID is required for logging');
    return;
  }

  const logId = crypto.randomUUID();
  const logPath = `adminLogs/${logEntry.guild}/${logEntry.action}/${logId}`;
  
  await setDoc(doc(db, logPath), {
    ...logEntry,
    timestamp: serverTimestamp(),
  });
};

export const LogActions = {
  ITEM_ADDED: 'ITEM_ADDED',
  ITEM_REMOVED: 'ITEM_REMOVED',
  BID_PLACED: 'BID_PLACED',
  ITEM_ROLLED: 'ITEM_ROLLED',
  ITEM_DISTRIBUTED: 'ITEM_DISTRIBUTED',
  PARTICIPANT_REGISTERED: 'PARTICIPANT_REGISTERED',
  DKP_AWARDED: 'DKP_AWARDED',
  BID_REFUNDED: 'BID_REFUNDED',
  USER_ROLE_UPDATED: 'USER_ROLE_UPDATED',
  USER_REMOVED: 'USER_REMOVED',
  USER_APPROVED: 'USER_APPROVED',
  USER_DETAILS_UPDATED: 'USER_DETAILS_UPDATED',
  DKP_REMOVED: "DKP_REMOVED",
  USER_DECLINED: "USER_DECLINED",
} as const;
