import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";
import { db, functions } from "../firebase";
import useUser from "../hooks/useUser";
import { CSSProperties } from 'react';
import { logAction, LogActions } from "../services/logginServices"
import useSessionGuild from "../hooks/useSessionGuild";
import {
  Box,
  Button,
  Container,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SelectChangeEvent
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ManageUserWishlist from './Wishlist/ManageUserWishlist';

const weapons = [
  "Longbow",
  "Dagger",
  "Crossbow",
  "Greatsword",
  "Staff",
  "Wand",
  "Sword and Shield",
];

const inGameRoles = [
  "Tank",
  "Healer",
  "DPS",
];

interface User {
  id: string;
  uid: string;
  username: string;
  inGameName?: string;
  role: 'admin' | 'member' | 'officer';
  status: string;
  dkp: number;
  guildId: string;
  userGuildId: string;
  primaryWeapon?: string;
  secondaryWeapon?: string;
  gearScore?: number;
  inGameRole?: string[];
}

interface PendingRegistration {
  id: string;
  username: string;
  guildId: string;
  status: string;
  timestamp: Date;
}

const UserManagement: React.FC = () => {
  const [isWishlistModalOpen, setIsWishlistModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { user, selectedGuild } = useUser();
  const { guildId } = useSessionGuild("user-management");
  const [users, setUsers] = useState<User[]>([]);
  const [pendingRegistrations, setPendingRegistrations] = useState<PendingRegistration[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [sortField, setSortField] = useState<keyof User | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentEditingUser, setCurrentEditingUser] = useState<User | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const theme = useTheme();

  // Add it here
  const generateInviteLink = (guildName: string) => {
    const baseUrl = window.location.origin;
    const inviteLink = `${baseUrl}/guild/${encodeURIComponent(guildName)}`;

    navigator.clipboard.writeText(inviteLink);
    alert('Invite link copied to clipboard!');
  };

  const openWishlistModal = (user: User) => {
    setSelectedUser(user);
    setIsWishlistModalOpen(true);
  };

  const [formData, setFormData] = useState<{
    inGameName: string;
    primaryWeapon: string;
    secondaryWeapon: string;
    gearScore: number;
    role: 'admin' | 'member' | 'officer';
    inGameRole: string[];
  }>({
    inGameName: "",
    primaryWeapon: "",
    secondaryWeapon: "",
    gearScore: 0,
    role: 'member',
    inGameRole: []
  });

  const sortableHeaderStyle: CSSProperties = {
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  } as const;

  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedFilter = useRef(
    debounce((query: string, usersList: User[]) => {
      if (query) {
        const names = query.split(',').map(name => name.trim().toLowerCase()).filter(name => name);
        const filtered = usersList.filter(userItem =>
          names.some(name =>
            userItem.username.toLowerCase().includes(name) ||
            (userItem.inGameName?.toLowerCase().includes(name))
          )
        );
        setFilteredUsers(filtered);
      } else {
        setFilteredUsers(usersList);
      }
    }, 300)
  ).current;

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedFilter(query, users);
  };

  const handleSort = (field: keyof User) => {
    const isAsc = sortField === field && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortField(field);

    const sortedUsers = [...filteredUsers].sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return isAsc ? bValue - aValue : aValue - bValue;
      }

      const aString = String(aValue || '').toLowerCase();
      const bString = String(bValue || '').toLowerCase();

      return isAsc
        ? bString.localeCompare(aString)
        : aString.localeCompare(bString);
    });

    setFilteredUsers(sortedUsers);
  };

  const fetchUsers = useCallback(async () => {
    if (!selectedGuild) {
      setUsers([]);
      return;
    }

    try {
      const userGuildsQuery = query(
        collection(db, "userGuilds"),
        where("guildId", "==", selectedGuild),
        where("status", "==", "approved")
      );
      const userGuildsSnapshot = await getDocs(userGuildsQuery);
      const userGuilds = userGuildsSnapshot.docs.map((doc) => ({
        id: doc.id,
        uid: doc.data().uid,
        guildId: doc.data().guildId,
        role: doc.data().role,
        status: doc.data().status,
        dkp: doc.data().dkp,
        username: doc.data().username || "", // Include username
        inGameName: doc.data().inGameName || "",
        primaryWeapon: doc.data().primaryWeapon || "",
        secondaryWeapon: doc.data().secondaryWeapon || "",
        gearScore: doc.data().gearScore || 0,
        inGameRole: doc.data().inGameRole || [],
        userGuildId: doc.id
      }));

      setUsers(userGuilds);
    } catch (error: any) {
      console.error("Error fetching users:", error);
      setError(error.message);
    }
  }, [selectedGuild]);

  const fetchPendingRegistrations = useCallback(async () => {
    if (!selectedGuild) {
      setPendingRegistrations([]);
      return;
    }

    try {
      const q = query(
        collection(db, "userGuilds"),
        where("guildId", "==", selectedGuild),
        where("status", "==", "pending")
      );

      const snapshot = await getDocs(q);
      const registrations = snapshot.docs.map((doc) => ({
        id: doc.id,
        username: doc.data().username || "Unknown",
        guildId: doc.data().guildId,
        status: doc.data().status,
        timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : new Date(),
      }));

      setPendingRegistrations(registrations);
    } catch (error: any) {
      console.error("Error fetching pending registrations:", error);
      setError("An error occurred while fetching pending registrations.");
    }
  }, [selectedGuild]);

  useEffect(() => {
    if (window.location.hostname === "localhost") {
      connectFunctionsEmulator(functions, "localhost", 5001);
    }
    fetchUsers();
    fetchPendingRegistrations();
  }, [fetchUsers, fetchPendingRegistrations]);

  // Update the handleApproveUser function
  const handleApproveUser = async (userGuildId: string) => {
    try {
      const userGuildRef = doc(db, "userGuilds", userGuildId);
      const userGuildDoc = await getDoc(userGuildRef);
      const userGuildData = userGuildDoc.data();

      await updateDoc(userGuildRef, { status: "approved" });

      setPendingRegistrations((prev) => prev.filter((reg) => reg.id !== userGuildId));
      fetchUsers();

      await logAction({
        action: LogActions.USER_APPROVED,
        actor: user?.uid || "system",
        details: `Approved user registration for ${userGuildData?.username || 'Unknown User'}`,
        sessionId: "system",
        guild: selectedGuild || "Unknown Guild"
      });

      alert("User registration approved successfully.");
    } catch (error: any) {
      console.error("Error approving user:", error);
      setError("An error occurred while approving the user.");
    }
  };

  const handleDeclineUser = async (userGuildId: string, username: string) => {
    if (!window.confirm("Are you sure you want to decline this user's registration?")) return;

    try {
      await deleteDoc(doc(db, "userGuilds", userGuildId));
      setPendingRegistrations((prev) => prev.filter((reg) => reg.id !== userGuildId));

      await logAction({
        action: LogActions.USER_DECLINED,
        actor: user?.uid || "system",
        details: `Declined user registration for ${username}`,
        sessionId: "system",
        guild: selectedGuild || "Unknown Guild"
      });

      alert("User registration declined successfully.");
    } catch (error: any) {
      console.error("Error declining user:", error);
      setError("An error occurred while declining the user.");
    }
  };

  // Update the handleDeleteUser function
  const handleDeleteUser = async (userGuildId: string, username: string) => {
    if (!window.confirm("Are you sure you want to remove this user from the guild?")) return;

    try {
      await deleteDoc(doc(db, "userGuilds", userGuildId));
      setUsers(users.filter((u) => u.userGuildId !== userGuildId));
      setFilteredUsers(filteredUsers.filter((u) => u.userGuildId !== userGuildId));

      await logAction({
        action: LogActions.USER_REMOVED,
        actor: user?.uid || "system",
        details: `Removed user ${username} from guild`,
        sessionId: "system",
        guild: selectedGuild || "Unknown Guild"
      });

    } catch (error: any) {
      console.error("Error removing user from guild:", error);
      setError(error.message);
    }
  };

  const openEditModal = (user: User) => {
    setCurrentEditingUser(user);
    setFormData({
      inGameName: user.inGameName || "",
      primaryWeapon: user.primaryWeapon || "",
      secondaryWeapon: user.secondaryWeapon || "",
      gearScore: user.gearScore || 0,
      role: user.role,
      inGameRole: user.inGameRole || []
    });
    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
    setCurrentEditingUser(null);
    setFormData({
      inGameName: "",
      primaryWeapon: "",
      secondaryWeapon: "",
      gearScore: 0,
      role: 'member',
      inGameRole: []
    });
  };

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "gearScore" ? (value === "" ? 0 : parseInt(value)) : value,
    }));
  };

  // For the role select specifically, create a separate handler
  const handleRoleChange = (event: SelectChangeEvent<'admin' | 'member' | 'officer'>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    if (!currentEditingUser || !selectedGuild || !user) return;

    if (formData.inGameRole.length === 0) {
      setError("Please select at least one in-game role.");
      return;
    }

    if (formData.primaryWeapon === formData.secondaryWeapon && formData.primaryWeapon !== "") {
      setError("Primary and Secondary Weapons cannot be the same.");
      return;
    }

    if (currentEditingUser.uid === user?.uid && formData.role !== 'admin') {
      setError("You cannot change your own role.");
      return;
    }

    const adminCount = users.filter((u) => u.role === "admin").length;
    if (currentEditingUser.role === 'admin' && formData.role !== 'admin' && adminCount <= 1) {
      setError("There must be at least one admin remaining.");
      return;
    }

    try {
      const userGuildRef = doc(db, "userGuilds", currentEditingUser.userGuildId);

      if (currentEditingUser.role !== formData.role) {
        await logAction({
          action: LogActions.USER_ROLE_UPDATED,
          actor: user.uid,
          details: `Changed ${currentEditingUser.username}'s role from ${currentEditingUser.role} to ${formData.role}`,
          sessionId: "user-management",
          guild: selectedGuild
        });
      }

      if (currentEditingUser.inGameName !== formData.inGameName) {
        await logAction({
          action: LogActions.USER_DETAILS_UPDATED,
          actor: user.uid,
          details: `Updated ${currentEditingUser.username}'s in-game name from ${currentEditingUser.inGameName || 'none'} to ${formData.inGameName}`,
          sessionId: "user-management",
          guild: selectedGuild
        });
      }

      if (currentEditingUser.primaryWeapon !== formData.primaryWeapon) {
        await logAction({
          action: LogActions.USER_DETAILS_UPDATED,
          actor: user.uid,
          details: `Updated ${currentEditingUser.username}'s primary weapon from ${currentEditingUser.primaryWeapon || 'none'} to ${formData.primaryWeapon}`,
          sessionId: "user-management",
          guild: selectedGuild
        });
      }

      if (currentEditingUser.secondaryWeapon !== formData.secondaryWeapon) {
        await logAction({
          action: LogActions.USER_DETAILS_UPDATED,
          actor: user.uid,
          details: `Updated ${currentEditingUser.username}'s secondary weapon from ${currentEditingUser.secondaryWeapon || 'none'} to ${formData.secondaryWeapon}`,
          sessionId: "user-management",
          guild: selectedGuild
        });
      }

      if (currentEditingUser.gearScore !== formData.gearScore) {
        await logAction({
          action: LogActions.USER_DETAILS_UPDATED,
          actor: user.uid,
          details: `Updated ${currentEditingUser.username}'s gear score from ${currentEditingUser.gearScore || 0} to ${formData.gearScore}`,
          sessionId: "user-management",
          guild: selectedGuild
        });
      }

      const currentRoles = currentEditingUser.inGameRole?.join(', ') || 'none';
      const newRoles = formData.inGameRole.join(', ');
      if (currentRoles !== newRoles) {
        await logAction({
          action: LogActions.USER_DETAILS_UPDATED,
          actor: user.uid,
          details: `Updated ${currentEditingUser.username}'s in-game roles from ${currentRoles} to ${newRoles}`,
          sessionId: "user-management",
          guild: selectedGuild
        });
      }

      // Update Firestore with new user details
      await updateDoc(userGuildRef, {
        inGameName: formData.inGameName,
        primaryWeapon: formData.primaryWeapon,
        secondaryWeapon: formData.secondaryWeapon,
        gearScore: formData.gearScore,
        role: formData.role,
        inGameRole: formData.inGameRole
      });

      const updatedUser = {
        ...currentEditingUser,
        inGameName: formData.inGameName,
        primaryWeapon: formData.primaryWeapon,
        secondaryWeapon: formData.secondaryWeapon,
        gearScore: formData.gearScore,
        role: formData.role,
        inGameRole: formData.inGameRole
      };

      setUsers(prevUsers =>
        prevUsers.map(u => u.userGuildId === currentEditingUser.userGuildId ? updatedUser : u)
      );
      setFilteredUsers(prevUsers =>
        prevUsers.map(u => u.userGuildId === currentEditingUser.userGuildId ? updatedUser : u)
      );

      alert("User details updated successfully.");
      closeEditModal();
    } catch (error: any) {
      console.error("Error saving user details:", error);
      setError("An error occurred while saving user details.");
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ pt: 3, pb: 2, color: 'white' }}>
          User Management for Guild: {selectedGuild}
        </Typography>

        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
          <TextField
            fullWidth
            placeholder="Search by username, in-game name(comma-separated)..."
            value={searchQuery}
            onChange={handleSearchQueryChange}
            variant="outlined"
          />
          <Button
            variant="contained"
            onClick={() => generateInviteLink(selectedGuild || '')}
          >
            Generate Invite Link
          </Button>
        </Box>

        <Typography variant="h5" sx={{ mb: 2, color: 'white' }}>
          Pending Registrations
        </Typography>

        {pendingRegistrations.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Guild</TableCell>
                  <TableCell>Requested At</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingRegistrations.map((reg) => (
                  <TableRow key={reg.id}>
                    <TableCell>{reg.username}</TableCell>
                    <TableCell>{reg.guildId}</TableCell>
                    <TableCell>{reg.timestamp.toLocaleString()}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleApproveUser(reg.id)}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDeclineUser(reg.id, reg.username)}
                        >
                          Decline
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1" sx={{ color: 'white' }}>
            No pending registrations.
          </Typography>
        )}

        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSort('username')} sx={{ cursor: 'pointer' }}>
                  Username {sortField === 'username' && (
                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                  )}
                </TableCell>
                <TableCell onClick={() => handleSort('inGameName')} sx={{ cursor: 'pointer' }}>
                  In-Game Name {sortField === 'inGameName' && (
                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                  )}
                </TableCell>
                <TableCell onClick={() => handleSort('role')} sx={{ cursor: 'pointer' }}>
                  Role {sortField === 'role' && (
                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                  )}
                </TableCell>
                <TableCell onClick={() => handleSort('primaryWeapon')} sx={{ cursor: 'pointer' }}>
                  Primary Weapon {sortField === 'primaryWeapon' && (
                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                  )}
                </TableCell>
                <TableCell onClick={() => handleSort('secondaryWeapon')} sx={{ cursor: 'pointer' }}>
                  Secondary Weapon {sortField === 'secondaryWeapon' && (
                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                  )}
                </TableCell>
                <TableCell onClick={() => handleSort('gearScore')} sx={{ cursor: 'pointer' }}>
                  Gear Score {sortField === 'gearScore' && (
                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                  )}
                </TableCell>
                <TableCell onClick={() => handleSort('inGameRole')} sx={{ cursor: 'pointer' }}>
                  In-Game Roles {sortField === 'inGameRole' && (
                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                  )}
                </TableCell>
                <TableCell onClick={() => handleSort('dkp')} sx={{ cursor: 'pointer' }}>
                  DKP {sortField === 'dkp' && (
                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                  )}
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((u) => (
                <TableRow key={u.id}>
                  <TableCell>{u.username}</TableCell>
                  <TableCell>{u.inGameName}</TableCell>
                  <TableCell>{u.role.charAt(0).toUpperCase() + u.role.slice(1)}</TableCell>
                  <TableCell>{u.primaryWeapon}</TableCell>
                  <TableCell>{u.secondaryWeapon}</TableCell>
                  <TableCell>{u.gearScore}</TableCell>
                  <TableCell>{u.inGameRole?.join(', ') || 'Not set'}</TableCell>
                  <TableCell>{u.dkp}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {u.uid !== user?.uid ? (
                        <>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDeleteUser(u.userGuildId, u.username)}
                          >
                            Remove
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => openEditModal(u)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => openWishlistModal(u)}
                            sx={{ ml: 1 }}
                          >
                            Manage Wishlist
                          </Button>
                        </>
                      ) : (
                        <>
                          <Typography variant="body2" sx={{ mr: 1 }}>(You)</Typography>
                          <Button
                            variant="contained"
                            onClick={() => openEditModal(u)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </Box>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={isModalOpen && !!currentEditingUser}
          onClose={closeEditModal}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            Edit User: {currentEditingUser?.username}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <FormControl>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>In-Game Roles</Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {inGameRoles.map((role) => (
                    <FormControlLabel
                      key={role}
                      control={
                        <Checkbox
                          checked={formData.inGameRole?.includes(role)}
                          onChange={(e) => {
                            const updatedRoles = e.target.checked
                              ? [...(formData.inGameRole || []), role]
                              : (formData.inGameRole || []).filter(r => r !== role);
                            setFormData(prev => ({ ...prev, inGameRole: updatedRoles }));
                          }}
                        />
                      }
                      label={role}
                    />
                  ))}
                </Box>
              </FormControl>

              <TextField
                fullWidth
                label="In-Game Name"
                name="inGameName"
                value={formData.inGameName}
                onChange={handleFormChange}
                placeholder="Enter In-Game Name"
              />

              <FormControl fullWidth>
                <InputLabel>Primary Weapon</InputLabel>
                <Select
                  name="primaryWeapon"
                  value={formData.primaryWeapon}
                  onChange={handleFormChange as (event: SelectChangeEvent<string>) => void}
                  label="Primary Weapon"
                >
                  <MenuItem value="">Select Primary Weapon</MenuItem>
                  {weapons.map((weapon) => (
                    <MenuItem key={weapon} value={weapon}>
                      {weapon}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>Secondary Weapon</InputLabel>
                <Select
                  name="secondaryWeapon"
                  value={formData.secondaryWeapon}
                  onChange={handleFormChange as (event: SelectChangeEvent<string>) => void}
                  label="Secondary Weapon"
                >
                  <MenuItem value="">Select Secondary Weapon</MenuItem>
                  {weapons.map((weapon) => (
                    <MenuItem key={weapon} value={weapon}>
                      {weapon}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                type="number"
                label="Gear Score"
                name="gearScore"
                value={formData.gearScore}
                onChange={handleFormChange}
                placeholder="Enter Gear Score"
                inputProps={{ min: "0" }}
              />

              <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  name="role"
                  value={formData.role}
                  onChange={handleRoleChange}
                  label="Role"
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="officer">Officer</MenuItem>
                  <MenuItem value="member">Member</MenuItem>
                </Select>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {formData.role === 'admin' &&
                    'Admin: Full access, can manage users, DKP, create loot sessions, etc.'}
                  {formData.role === 'officer' &&
                    'Officer: Can create loot sessions, roll/bid on items.'}
                  {formData.role === 'member' &&
                    'Member: Can participate in loot sessions and roll/bid on items.'}
                </Typography>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeEditModal}>Cancel</Button>
            <Button onClick={handleSaveChanges} variant="contained" color="primary">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
        <ManageUserWishlist
          open={isWishlistModalOpen}
          onClose={() => {
            setIsWishlistModalOpen(false);
            setSelectedUser(null);
          }}
          user={selectedUser ? {
            uid: selectedUser.uid,
            username: selectedUser.username,
            userGuildId: selectedUser.userGuildId
          } : undefined}
          guildId={selectedGuild || ''}
        />
      </Container>
    </Box>
  );

};

export default UserManagement;
