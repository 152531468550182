import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";
import { db } from "../firebase";
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Button,
  Paper,
  CircularProgress,
  Alert,
  Stack
} from "@mui/material";

const SelectGuild: React.FC = () => {
  const { user, selectedGuild, setSelectedGuild } = useUser();
  const navigate = useNavigate();
  const [guilds, setGuilds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchGuilds = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      const approvedGuilds = user.memberships
        .filter((m) => m.status === "approved")
        .map((m) => m.guildId);

      setGuilds(approvedGuilds);
      setLoading(false);
    };

    fetchGuilds();
  }, [user, navigate]);

  const handleSelectGuild = (guildId: string) => {
    setSelectedGuild(guildId);
    navigate('/dashboard');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (guilds.length === 0) {
    return (
      <Container maxWidth="sm">
        <Box my={4}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Stack spacing={2}>
              <Typography variant="h4" component="h2" gutterBottom>
                No Approved Guilds Found
              </Typography>
              <Alert severity="info">
                Your request to join a guild is pending approval by an administrator of this guild.
              </Alert>
              <Typography variant="body1">
                If you wish to create a new guild, please logout and follow the instructions on the login page to create a guild.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                For further assistance, please contact an admin.
              </Typography>
            </Stack>
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom align="center">
            Select Your Guild
          </Typography>
          <List>
            {guilds.map((guildId) => (
              <ListItem key={guildId} sx={{ justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSelectGuild(guildId)}
                  fullWidth
                  sx={{ py: 1.5, textTransform: 'none' }}
                >
                  {guildId}
                </Button>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    </Container>
  );
};

export default SelectGuild;
