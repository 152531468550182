//src\hooks\useSessionGuild.ts

import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const useSessionGuild = (sessionId: string) => {
  const [guildId, setGuildId] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSessionGuild = async () => {
      if (!sessionId) {
        setLoading(false);
        return;
      }

      const sessionRef = doc(db, "sessions", sessionId);
      const sessionDoc = await getDoc(sessionRef);
      const guildData = sessionDoc.data()?.guildId;
      
      setGuildId(guildData || "");
      setLoading(false);
    };

    fetchSessionGuild();
  }, [sessionId]);

  return { guildId, loading };
};

export default useSessionGuild;
