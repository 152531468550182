// src/components/Dashboard.tsx

import React, { useState, useEffect } from "react";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  FieldValue,
  limit
} from "firebase/firestore";
import useUser from "../hooks/useUser";
import { UserProfile } from "../interfaces";
import { Timestamp } from "firebase/firestore";
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  useTheme,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Link,
} from '@mui/material';
import UserDKPList from '../DKPManagement/UserDKPList';

interface UserGuild {
  inGameName?: string;
  primaryWeapon?: string;
  secondaryWeapon?: string;
  gearScore?: number;
  inGameRole?: string[];
  username?: string;
}

const Dashboard: React.FC = () => {
  const [trialStatus, setTrialStatus] = useState<GuildTrial | null>(null);
  const weapons = [
    "Longbow",
    "Dagger",
    "Crossbow",
    "Greatsword",
    "Staff",
    "Wand",
    "Sword and Shield",
  ];

  const inGameRoles = ["DPS", "Healer", "Tank"];

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [newPrimaryWeapon, setNewPrimaryWeapon] = useState("");
  const [newSecondaryWeapon, setNewSecondaryWeapon] = useState("");
  const [newGearScore, setNewGearScore] = useState<number>(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading, selectedGuild } = useUser();
  const [authenticating, setAuthenticating] = useState<boolean>(false);

  // Modal State
  const [showInGameNameModal, setShowInGameNameModal] = useState<boolean>(false);
  const [newInGameName, setNewInGameName] = useState<string>("");
  const [savingInGameName, setSavingInGameName] = useState<boolean>(false);
  const [userGuildId, setUserGuildId] = useState<string>("");

  // Username State
  const [currentUsername, setCurrentUsername] = useState<string>("");

  // User Profile State
  const [currentUserProfile, setCurrentUserProfile] = useState<{
    inGameName: string;
    primaryWeapon: string;
    secondaryWeapon: string;
    gearScore: number;
    inGameRole: string[];
  }>({
    inGameName: "",
    primaryWeapon: "",
    secondaryWeapon: "",
    gearScore: 0,
    inGameRole: [],
  });

  const theme = useTheme();

  // GuildTrial Interface
  interface GuildTrial {
    activatedAt: Timestamp | FieldValue;
    activatedBy: string;
    expiresAt: Timestamp;
    hasUsedTrial: boolean;
  }

  // Token check effect
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token) {
      setAuthenticating(true);
      navigate("/dashboard", { replace: true });
    }
  }, [location, navigate]);

  // Logout handler
  const handleLogout = async () => {
    await signOut(auth);
    navigate("/login");
  };

  // Main data loading effect
  useEffect(() => {
    if (!user || !selectedGuild) return;

    const loadDashboardData = async () => {
      try {
        // Directly access the userGuild document using its ID
        const userGuildDocRef = doc(db, "userGuilds", `${user.uid}_${selectedGuild}`);
        const userGuildDoc = await getDoc(userGuildDocRef);

        if (userGuildDoc.exists()) {
          const userGuildData = userGuildDoc.data() as UserGuild;

          // Set user guild ID
          setUserGuildId(userGuildDoc.id);

          // Set username
          setCurrentUsername(userGuildData.username || "Unknown User");

          // Set profile data
          const profileData = {
            inGameName: userGuildData.inGameName || "",
            primaryWeapon: userGuildData.primaryWeapon || "",
            secondaryWeapon: userGuildData.secondaryWeapon || "",
            gearScore: userGuildData.gearScore || 0,
            inGameRole: userGuildData.inGameRole || [],
          };

          setCurrentUserProfile(profileData);
          setNewInGameName(profileData.inGameName);
          setNewPrimaryWeapon(profileData.primaryWeapon);
          setNewSecondaryWeapon(profileData.secondaryWeapon);
          setNewGearScore(profileData.gearScore);
          setSelectedRoles(profileData.inGameRole);

          // Check if profile needs completion
          if (
            !userGuildData.inGameName?.trim() ||
            !userGuildData.primaryWeapon?.trim() ||
            !userGuildData.gearScore ||
            !userGuildData.inGameRole?.length
          ) {
            setShowInGameNameModal(true);
          }
        } else {
          console.error("UserGuild document does not exist.");
          // Handle the case where the document doesn't exist
        }

        // Fetch trial status
        const guildDoc = await getDoc(doc(db, "guilds", selectedGuild));
        const trialData = guildDoc.data()?.trial as GuildTrial | undefined;
        const currentTime = Timestamp.now();

        if (trialData && trialData.expiresAt.toDate() > currentTime.toDate()) {
          setTrialStatus(trialData);
        } else {
          setTrialStatus(null);
        }
      } catch (error) {
        console.error("Error loading dashboard data:", error);
      }
    };

    loadDashboardData();
  }, [user, selectedGuild]);

  // Profile modal effect
  useEffect(() => {
    if (showInGameNameModal) {
      const userGuildData = currentUserProfile;
      setNewInGameName(userGuildData.inGameName);
      setNewPrimaryWeapon(userGuildData.primaryWeapon);
      setNewSecondaryWeapon(userGuildData.secondaryWeapon);
      setNewGearScore(userGuildData.gearScore);
      setSelectedRoles(userGuildData.inGameRole);
    }
  }, [showInGameNameModal, currentUserProfile]);

  const activateTrial = async () => {
    if (!user || !selectedGuild) return;

    const guildRef = doc(db, "guilds", selectedGuild);
    const guildDoc = await getDoc(guildRef);
    const guildData = guildDoc.data();

    if (guildData?.trial) {
      await updateDoc(guildRef, {
        trial: {
          ...guildData.trial,
          hasUsedTrial: true,
        },
      });

      alert("This guild has already used its trial period.");
      return;
    }

    const expiresAt = Timestamp.fromMillis(Date.now() + 48 * 60 * 60 * 1000);

    const trialData: GuildTrial = {
      activatedAt: serverTimestamp(),
      activatedBy: user.uid,
      expiresAt,
      hasUsedTrial: true,
    };

    await updateDoc(guildRef, {
      trial: trialData,
    });

    setTrialStatus(trialData);
  };

  // Function to fetch the user's profile
  const fetchUserProfile = async () => {
    if (!user || !selectedGuild) return;

    try {
      // Directly access the userGuild document using its ID
      const userGuildDocRef = doc(db, "userGuilds", `${user.uid}_${selectedGuild}`);
      const userGuildDoc = await getDoc(userGuildDocRef);

      if (userGuildDoc.exists()) {
        const userGuildData = userGuildDoc.data() as UserGuild;

        setUserGuildId(userGuildDoc.id);

        const profileData = {
          inGameName: userGuildData.inGameName || "",
          primaryWeapon: userGuildData.primaryWeapon || "",
          secondaryWeapon: userGuildData.secondaryWeapon || "",
          gearScore: userGuildData.gearScore || 0,
          inGameRole: userGuildData.inGameRole || [],
        };

        setCurrentUserProfile(profileData);
        setNewInGameName(profileData.inGameName);
        setNewPrimaryWeapon(profileData.primaryWeapon);
        setNewSecondaryWeapon(profileData.secondaryWeapon);
        setNewGearScore(profileData.gearScore);
        setSelectedRoles(profileData.inGameRole);
      } else {
        console.error("UserGuild document does not exist.");
        // Handle the case where the document doesn't exist
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    if (user && selectedGuild) {
      fetchUserProfile();
    }
  }, [user, selectedGuild]);

  useEffect(() => {
    if (loading || authenticating) {
      return;
    }
  }, [loading, authenticating, showInGameNameModal]);

  const [showTrialModal, setShowTrialModal] = useState<boolean>(false);

  const handleTrialButtonClick = () => {
    setShowTrialModal(true);
  };

  const handleSaveInGameName = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newInGameName.trim()) {
      alert("In-Game Name cannot be empty.");
      return;
    }

    if (selectedRoles.length === 0) {
      alert("Please select at least one in-game role.");
      return;
    }

    setSavingInGameName(true);
    try {
      const userGuildRef = doc(db, "userGuilds", userGuildId);
      const updateData = {
        inGameName: newInGameName.trim(),
        primaryWeapon: newPrimaryWeapon,
        secondaryWeapon: newSecondaryWeapon,
        gearScore: newGearScore,
        inGameRole: selectedRoles,
        timestamp: serverTimestamp(),
      };

      await updateDoc(userGuildRef, updateData);

      setCurrentUserProfile((prev) => ({
        ...prev,
        inGameName: newInGameName.trim(),
        primaryWeapon: newPrimaryWeapon,
        secondaryWeapon: newSecondaryWeapon,
        gearScore: newGearScore,
        inGameRole: selectedRoles,
      }));

      await fetchUserProfile();
      setShowInGameNameModal(false);
      alert("Profile updated successfully.");
    } catch (error) {
      console.error("Dashboard - Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    } finally {
      setSavingInGameName(false);
    }
  };

  if (loading || authenticating) {
    return <div className="loading">Loading...</div>;
  }

  const isApproved = user?.status === "approved";

  if (!isApproved) {
    navigate("/pending-approval");
    return null;
  }

  // Update the return statement:
  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
      {/* Hero Header */}
      <Box
        sx={{
          position: 'relative',
          height: '200px',
          overflow: 'hidden',
        }}
      >
        {/* Background Image */}
        <Box
          sx={{
            backgroundImage: 'url(/images/newsite/bkg.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            filter: 'blur(1px)',
            zIndex: 1,
          }}
        />

        {/* Dark Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 2,
          }}
        />

        {/* Content */}
        <Container maxWidth="lg" sx={{ height: '100%', position: 'relative', zIndex: 3 }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pt: 3
          }}>
            <Typography variant="h4" sx={{ color: 'white', fontWeight: 700 }}>
              {selectedGuild}
            </Typography>
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleLogout}
              sx={{ color: 'white', borderColor: 'white' }}
            >
              Logout
            </Button>
          </Box>
        </Container>
      </Box>


      {/* Main Content */}
      <Container maxWidth="lg" sx={{ mt: -2, pt: 4, mb: 4, position: 'relative' }}>

        <Grid container spacing={3}>
          {/* Profile Card */}
          <Grid item xs={12} md={4}>
            <Card sx={{
              height: '100%',
              background: 'rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
            }}>
              <CardContent>
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                  <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
                    {currentUsername}
                  </Typography>
                  <Typography color="primary" sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
                    {user?.dkp} DKP
                  </Typography>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  p: 2,
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 1
                }}>
                  <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Role</span>
                    <strong>{user?.role || "Member"}</strong>
                  </Typography>
                  <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Gear Score</span>
                    <strong>{currentUserProfile.gearScore}</strong>
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Character Details */}
          <Grid item xs={12} md={8}>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 3 }}>Character Details</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle2" color="textSecondary">Primary Weapon</Typography>
                      <Typography variant="body1">{currentUserProfile.primaryWeapon || "Not Set"}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" color="textSecondary">Secondary Weapon</Typography>
                      <Typography variant="body1">{currentUserProfile.secondaryWeapon || "Not Set"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography variant="subtitle2" color="textSecondary">In-Game Roles</Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                        {currentUserProfile.inGameRole.map((role) => (
                          <Box
                            key={role}
                            sx={{
                              px: 2,
                              py: 0.5,
                              borderRadius: '16px',
                              backgroundColor: theme.palette.primary.main,
                              color: 'white',
                              fontSize: '0.875rem'
                            }}
                          >
                            {role}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Action Buttons */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowInGameNameModal(true)}
                fullWidth
                sx={{ py: 1.5 }}
              >
                Edit Profile
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate('/sessions')}
                fullWidth
                sx={{ py: 1.5 }}
              >
                View Sessions
              </Button>
            </Box>
          </Grid>

          {/* Admin Trial Section */}
          {user?.role === "admin" && (
            <Grid item xs={12}>
              <Card sx={{
                background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
                color: 'white'
              }}>
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="h6" sx={{ mb: 1 }}>Discord Bot Trial</Typography>
                    <Typography variant="body2">
                      {trialStatus ? "Trial period active" : "Activate your 48-hour trial period"}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleTrialButtonClick}
                    disabled={!!trialStatus?.hasUsedTrial}
                  >
                    {trialStatus
                      ? `${Math.ceil(
                        (trialStatus.expiresAt.toDate().getTime() -
                          Timestamp.now().toDate().getTime()) /
                        (1000 * 60 * 60)
                      )}h remaining`
                      : "Activate Trial"}

                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>

      <UserDKPList />


      {/* Edit Profile Modal */}
      <Dialog open={showInGameNameModal} onClose={() => setShowInGameNameModal(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Profile</DialogTitle>
        <form onSubmit={handleSaveInGameName}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="In-Game Name"
                  value={newInGameName}
                  onChange={(e) => setNewInGameName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Primary Weapon</InputLabel>
                  <Select
                    value={newPrimaryWeapon}
                    onChange={(e) => setNewPrimaryWeapon(e.target.value)}
                    label="Primary Weapon"
                    required
                  >
                    {weapons.map((weapon) => (
                      <MenuItem key={weapon} value={weapon}>{weapon}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Secondary Weapon</InputLabel>
                  <Select
                    value={newSecondaryWeapon}
                    onChange={(e) => setNewSecondaryWeapon(e.target.value)}
                    label="Secondary Weapon"
                  >
                    <MenuItem value="">None</MenuItem>
                    {weapons.map((weapon) => (
                      <MenuItem key={weapon} value={weapon}>{weapon}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label="Gear Score"
                  value={newGearScore}
                  onChange={(e) => setNewGearScore(Number(e.target.value))}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>In-Game Roles</Typography>
                <FormGroup row>
                  {inGameRoles.map((role) => (
                    <FormControlLabel
                      key={role}
                      control={
                        <Checkbox
                          checked={selectedRoles.includes(role)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedRoles([...selectedRoles, role]);
                            } else {
                              setSelectedRoles(selectedRoles.filter((r) => r !== role));
                            }
                          }}
                        />
                      }
                      label={role}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowInGameNameModal(false)}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={savingInGameName}>
              {savingInGameName ? 'Saving...' : 'Save Changes'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

    </Box>
  );


};

export default Dashboard;