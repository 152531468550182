// SessionDetail.tsx
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useSessionFirestore from "../hooks/useSessionFirestore";
import useUser from "../hooks/useUser";
import useSessionGuild from "../hooks/useSessionGuild";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  writeBatch,
  serverTimestamp,
} from "firebase/firestore";
import ConfirmationModal from "./ConfirmationModal";
import itemsDataNew from "../items.json";
import { LootDrop } from "../interfaces";
import { handleBid } from "../handlers/handleBid";
import { handleRollLoot } from "../handlers/handleRollLoot";
import { HandleRollLootProps } from "../interfaces/handlers";
import LootMethodModal from "./LootMethodModal";
import { logAction, LogActions } from "../services/logginServices";
import { Box, CircularProgress, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BidModal from "./BidModal";
import RollModal from "./RollModal";
import { checkEventRegistration } from "../services/eventServices";
import {
  getAvailableEvents,
  linkEventToSession,
  getLinkedEvent,
} from "../services/eventServices";
import { SessionHeader } from "./sessions/detail/SessionHeader";
import { SessionInfo } from "./sessions/detail/SessionInfo";
import { LootMasterPanel } from "./sessions/detail/LootMasterPanel";
import { LootTable } from "./sessions/detail/LootTable";
import { handleDistributeNew } from "../handlers/handleDistributeNew";
import { handleAutoDistribute } from "../handlers/handleAutoDistribute";
import { handleRemove } from "../handlers/handleRemove";
import { verifyWishlistStatus } from "../components/Wishlist/WishListServices"; // Import the verifyWishlistStatus function

interface ItemType {
  name: string;
  imageFilename: string;
  url?: string;
}

const adaptedItemsData: ItemType[] = (
  Array.isArray(itemsDataNew)
    ? itemsDataNew
    : (itemsDataNew as { items?: any[] }).items ?? []
).map((item: any) => ({
  name: item.name,
  imageFilename: item.imageFilename.replace(/'/g, "_").replace(/\s+/g, "_"),
  url: item.url,
}));

const SessionDetail: React.FC = () => {
  const [availableEvents, setAvailableEvents] = useState<any[]>([]);
  const [linkedEvent, setLinkedEvent] = useState<any>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { sessionId } = useParams<{ sessionId: string }>();
  const { session, participants, lootDrops, loading } =
    useSessionFirestore(sessionId);
  const { user, loading: userLoading, selectedGuild } = useUser();
  const { guildId } = useSessionGuild(sessionId || "");
  const [enrichedLootDrops, setEnrichedLootDrops] = useState<LootDrop[]>([]);
  const [success, setSuccess] = useState<string | null>(null);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{
    itemName: string;
    itemImage: string;
    isCustom: boolean;
    url?: string;
  } | null>(null);
  const [selectedLootId, setSelectedLootId] = useState<string | null>(null);
  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [currentLootData, setCurrentLootData] = useState<any>(null); // For bid reason
  const [historyVisible, setHistoryVisible] = useState<Record<string, boolean>>(
    {}
  );
  const [bidHistoryVisible, setBidHistoryVisible] = useState<
    Record<string, boolean>
  >({});
  const [selectedGameMode, setSelectedGameMode] = useState<
    "Throne and Liberty" | "Custom"
  >("Throne and Liberty");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState<ItemType[]>([]);

  // New aggregate state
  const [aggregateData, setAggregateData] = useState<{
    highestRolls: Record<string, number>;
    currentBids: Record<string, number>;
    rollCounts: Record<string, number>;
    bidCounts: Record<string, number>;
  }>({
    highestRolls: {},
    currentBids: {},
    rollCounts: {},
    bidCounts: {},
  });

  const BATCH_SIZE = 10;

  // Roll modal state
  const [rollModalOpen, setRollModalOpen] = useState(false);
  const [requireRollReason, setRequireRollReason] = useState(false);
  const [currentLootId, setCurrentLootId] = useState<string | null>(null);

  // Helper functions to toggle history visibility
  const toggleHistory = (lootId: string) => {
    setHistoryVisible((prev) => ({ ...prev, [lootId]: !prev[lootId] }));
  };

  const toggleBidHistory = (lootId: string) => {
    setBidHistoryVisible((prev) => ({ ...prev, [lootId]: !prev[lootId] }));
  };

  // Determine if the current user is the lootmaster
  const isLootmaster = useMemo(() => {
    return user?.uid === session?.lootmaster;
  }, [user?.uid, session?.lootmaster]);

  // Fetch aggregate data
  useEffect(() => {
    if (!sessionId) return;

    const aggregateRef = doc(db, "sessions", sessionId, "aggregates", "lootData");

    const unsubscribe = onSnapshot(aggregateRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        setAggregateData({
          highestRolls: data.highestRolls || {},
          currentBids: data.currentBids || {},
          rollCounts: data.rollCounts || {},
          bidCounts: data.bidCounts || {},
        });
      }
    });

    return () => unsubscribe();
  }, [sessionId]);

  // Filter items based on search term
  useEffect(() => {
    const filtered = adaptedItemsData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [searchTerm]);

  // Enrich loot drops with aggregate data
  useEffect(() => {
    const enrichLootDrops = async () => {
      const currentBatch = lootDrops.slice(
        0,
        enrichedLootDrops.length + BATCH_SIZE
      );
      const newEnrichedDrops = currentBatch.map((loot) => ({
        ...loot,
        highestRoll: aggregateData.highestRolls[loot.id] || 0,
        currentBid: aggregateData.currentBids[loot.id] || 0,
        rollCount: aggregateData.rollCounts[loot.id] || 0,
        bidCount: aggregateData.bidCounts[loot.id] || 0,
      }));
      setEnrichedLootDrops(newEnrichedDrops);
    };

    if (lootDrops.length > 0) {
      enrichLootDrops();
    }
  }, [lootDrops, aggregateData]);

  // Fetch available events when selectedGuild changes
  useEffect(() => {
    const fetchAvailableEvents = async () => {
      if (!selectedGuild) return;
      try {
        const events = await getAvailableEvents(selectedGuild);
        setAvailableEvents(events);
      } catch (error) {
        console.error("Error fetching available events:", error);
        setAvailableEvents([]);
      }
    };
    fetchAvailableEvents();
  }, [selectedGuild]);

  // Fetch linked event if session has an eventId
  useEffect(() => {
    const fetchLinkedEvent = async () => {
      if (!session || !session.eventId || !selectedGuild) {
        setLinkedEvent(null);
        return;
      }
      try {
        const event = await getLinkedEvent(selectedGuild, session.eventId);
        setLinkedEvent(event);
      } catch (error) {
        console.error("Error fetching linked event:", error);
        setLinkedEvent(null);
      }
    };
    fetchLinkedEvent();
  }, [session, selectedGuild]);

  // Auto distribute handler
  const handleAutoDistributeWrapper = async () => {
    if (!sessionId || !user || !selectedGuild) return;

    await handleAutoDistribute({
      sessionId,
      user,
      selectedGuild,
      setSuccess,
    });
  };

  // Bid handler wrapper
  const handleBidWrapper = async (bidAmount: number, reason?: string) => {
    if (!selectedLootId || !user || !selectedGuild || !sessionId || !session)
      return;

    const canParticipate = await checkEventRegistration(
      sessionId,
      user.uid,
      selectedGuild
    );

    if (!canParticipate) {
      setConfirmationTitle("Error");
      setConfirmationMessage(
        "You must be registered for the linked event to participate"
      );
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
    }

    const lootRef = doc(
      db,
      "sessions",
      sessionId,
      "lootDrops",
      selectedLootId
    );
    const lootDoc = await getDoc(lootRef);
    const lootData = lootDoc.data();

    // Use verifyWishlistStatus for bid eligibility
    if (lootData?.wishlistOnly === true) {
      const { isEligible, message } = await verifyWishlistStatus(
        user.uid,
        lootData.itemName,
        session,
        lootData.wishlistVerification
      );

      if (!isEligible) {
        setConfirmationTitle("Not Eligible");
        setConfirmationMessage(message || "You are not eligible to bid for this item.");
        setIsError(true);
        setConfirmationModalVisible(true);
        return;
      }
    }

    await handleBid({
      lootId: selectedLootId,
      bidAmount,
      reason,
      user,
      sessionId,
      selectedGuild,
      session,
      setConfirmationTitle,
      setConfirmationMessage,
      setIsError,
      setConfirmationModalVisible,
      setSuccess,
      uid: user.uid,
    });

    setBidModalOpen(false);
    setCurrentLootData(null);
  };

  // Distribute handler wrapper
  const handleDistributeWrapper = async (lootId: string) => {
    if (!user) return;

    await handleDistributeNew({
      lootId,
      sessionId: sessionId!,
      selectedGuild: selectedGuild!,
      user,
      setConfirmationTitle,
      setConfirmationMessage,
      setIsError,
      setConfirmationModalVisible,
      setSuccess,
    });
  };

  // Remove handler wrapper
  const handleRemoveWrapper = async (lootId: string) => {
    if (!sessionId || !user || !selectedGuild) return;

    // Call the main handleRemove function with all required props
    await handleRemove({
      lootId,
      sessionId,
      selectedGuild,
      setConfirmationTitle,
      setConfirmationMessage,
      setIsError,
      setConfirmationModalVisible,
      setSuccess,
      guildId: selectedGuild,
    });

    // Update aggregates after removal
    const aggregateRef = doc(
      db,
      "sessions",
      sessionId,
      "aggregates",
      "lootData"
    );
    const batch = writeBatch(db);

    batch.update(aggregateRef, {
      [`highestRolls.${lootId}`]: null,
      [`currentBids.${lootId}`]: null,
      [`rollCounts.${lootId}`]: null,
      [`bidCounts.${lootId}`]: null,
    });

    await batch.commit();
  };

  // Filtering enriched loot drops
  const filteredEnrichedLootDrops = useMemo(() => {
    if (!enrichedLootDrops) return [];

    return enrichedLootDrops;
  }, [enrichedLootDrops]);

  // Infinite scroll handler
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100) {
      const currentSize = enrichedLootDrops.length;
      const nextBatch = lootDrops.slice(currentSize, currentSize + BATCH_SIZE);
      if (nextBatch.length > 0) {
        setEnrichedLootDrops((prev) => [...prev, ...nextBatch]);
      }
    }
  };

  // Roll confirmation handler
  const handleRollConfirm = async (reason?: string) => {
    if (
      !currentLootId ||
      !user ||
      !sessionId ||
      !selectedGuild ||
      !session
    )
      return;

    await handleRollLoot({
      lootId: currentLootId,
      user,
      sessionId,
      reason,
      setConfirmationTitle,
      setConfirmationMessage,
      setIsError,
      setConfirmationModalVisible,
      setSuccess,
      setUserRolls: () => {},
      selectedGuild,
      session,
    });

    setRollModalOpen(false);
    setCurrentLootId(null);
    setRequireRollReason(false);
  };

  // Redirect and loading states
  if (!sessionId || loading.session || userLoading || !user || !selectedGuild) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{ minHeight: "100vh", backgroundColor: theme.palette.background.default }}
      onScroll={handleScroll}
    >
      <SessionHeader
        sessionName={session?.name || "Session Detail"}
        onNavigateBack={() => navigate("/sessions")}
      />

      <Container maxWidth="lg" sx={{ pt: 4, pb: 6 }}>
        <SessionInfo
          session={session}
          selectedGuild={selectedGuild}
          linkedEvent={linkedEvent}
          isLootmaster={isLootmaster}
          availableEvents={availableEvents}
          onLinkEvent={async (eventId: string) => {
            try {
              await linkEventToSession(sessionId!, eventId);
              const event = await getLinkedEvent(selectedGuild, eventId);
              setLinkedEvent(event);
              setSuccess("Event linked successfully");
            } catch (error) {
              console.error("Error linking event:", error);
              setConfirmationTitle("Error");
              setConfirmationMessage("Failed to link the selected event.");
              setIsError(true);
              setConfirmationModalVisible(true);
            }
          }}
          onExpire={handleAutoDistributeWrapper}
        />

        {isLootmaster && (
          <LootMasterPanel
            selectedGameMode={selectedGameMode}
            searchTerm={searchTerm}
            filteredItems={filteredItems}
            onGameModeChange={setSelectedGameMode}
            onSearchChange={setSearchTerm}
            onAddItem={setSelectedItem}
          />
        )}

        <LootTable
          loading={loading.loot}
          lootDrops={filteredEnrichedLootDrops}
          isLootmaster={isLootmaster}
          onRoll={async (props: HandleRollLootProps) => {
            if (!user || !sessionId || !selectedGuild || !session) return;

            const canParticipate = await checkEventRegistration(
              sessionId,
              user.uid,
              selectedGuild
            );

            if (!canParticipate) {
              setConfirmationTitle("Error");
              setConfirmationMessage(
                "You must be registered for the linked event to participate"
              );
              setIsError(true);
              setConfirmationModalVisible(true);
              return;
            }

            const lootId = props.lootId;
            const lootRef = doc(
              db,
              "sessions",
              sessionId,
              "lootDrops",
              lootId
            );
            const lootDoc = await getDoc(lootRef);
            const lootData = lootDoc.data();

            // Use verifyWishlistStatus for roll eligibility
            if (lootData?.wishlistOnly === true) {
              const { isEligible, message } = await verifyWishlistStatus(
                user.uid,
                lootData.itemName,
                session,
                lootData.wishlistVerification
              );

              if (!isEligible) {
                setConfirmationTitle("Not Eligible");
                setConfirmationMessage(
                  message || "You are not eligible to roll for this item."
                );
                setIsError(true);
                setConfirmationModalVisible(true);
                return;
              }
            }

            if (lootData?.requireBidReason) {
              setRequireRollReason(true);
              setCurrentLootId(lootId);
              setRollModalOpen(true);
            } else {
              await handleRollLoot({
                ...props,
                user,
                sessionId,
                reason: undefined,
                setConfirmationTitle,
                setConfirmationMessage,
                setIsError,
                setConfirmationModalVisible,
                setSuccess,
                setUserRolls: () => {},
                selectedGuild,
                session,
              });
            }
          }}
          onDistribute={handleDistributeWrapper}
          onRemove={handleRemoveWrapper}
          onToggleHistory={toggleHistory}
          onToggleBidHistory={toggleBidHistory}
          onBid={async (lootId) => {
            if (!sessionId) return;
            const lootRef = doc(
              db,
              "sessions",
              sessionId,
              "lootDrops",
              lootId
            );
            const lootDoc = await getDoc(lootRef);
            const lootData = lootDoc.data();
            setCurrentLootData(lootData);

            // Use verifyWishlistStatus for bid eligibility
            if (lootData?.wishlistOnly === true) {
              const { isEligible, message } = await verifyWishlistStatus(
                user.uid,
                lootData.itemName,
                session,
                lootData.wishlistVerification
              );

              if (!isEligible) {
                setConfirmationTitle("Not Eligible");
                setConfirmationMessage(
                  message || "You are not eligible to bid for this item."
                );
                setIsError(true);
                setConfirmationModalVisible(true);
                return;
              }
            }

            setBidModalOpen(true);
            setSelectedLootId(lootId);
          }}
          historyVisible={historyVisible}
          bidHistoryVisible={bidHistoryVisible}
          sessionId={sessionId}
          user={user}
          hasRolls={{}}
          hasBids={{}}
          session={session}
          aggregateData={{
            highestRolls: aggregateData.highestRolls,
            currentBids: aggregateData.currentBids,
            bidCounts: aggregateData.bidCounts,
            rollCounts: aggregateData.rollCounts,
          }}
        />
      </Container>

      {/* Modals */}
      {confirmationModalVisible && (
        <ConfirmationModal
          title={confirmationTitle}
          message={confirmationMessage}
          onConfirm={() => setConfirmationModalVisible(false)}
          onCancel={() => setConfirmationModalVisible(false)}
          isError={isError}
        />
      )}

      <BidModal
        open={bidModalOpen}
        onClose={() => {
          setBidModalOpen(false);
          setCurrentLootData(null);
        }}
        onConfirm={handleBidWrapper}
        requireReason={currentLootData?.requireBidReason}
      />

      <RollModal
        open={rollModalOpen}
        onClose={() => {
          setRollModalOpen(false);
          setCurrentLootId(null);
          setRequireRollReason(false);
        }}
        onConfirm={handleRollConfirm}
        requireReason={requireRollReason}
      />

      {selectedItem && (
        <LootMethodModal
          itemName={selectedItem.itemName}
          itemImage={selectedItem.itemImage}
          isCustom={selectedItem.isCustom}
          onConfirm={async (
            method,
            isPrivateBidding,
            minIncrement,
            selectedTrait,
            itemNameInput,
            wishlistOnly,
            wishlistVerification,
            requireBidReason
          ) => {
            if (!sessionId || !user || !selectedGuild) return;

            const batch = writeBatch(db);
            const lootRef = doc(
              collection(db, "sessions", sessionId, "lootDrops")
            );

            const processedData = {
              itemName: itemNameInput || selectedItem.itemName,
              description: selectedItem.isCustom
                ? "Custom Item"
                : "Throne and Liberty Item",
              traits: [selectedTrait],
              addedBy: user.uid,
              createdAt: serverTimestamp(),
              currentBid: 0,
              currentBidder: null,
              status: "open",
              lootMethod: method,
              rolledNumber: null,
              minIncrement,
              isPrivateBidding,
              imageUrl: selectedItem.itemImage,
              wishlistOnly: wishlistOnly || false,
              ...(wishlistVerification &&
                wishlistVerification.method !== "none" && {
                  wishlistVerification: {
                    method: wishlistVerification.method,
                    ...(wishlistVerification.method === "custom" && {
                      hours: wishlistVerification.hours || 24,
                    }),
                  },
                }),
              requireBidReason: requireBidReason || false,
              highestRoll: -1,
              highestRollerName: "",
              highestRollerId: "",
            };

            batch.set(lootRef, processedData);

            const aggregateRef = doc(
              db,
              "sessions",
              sessionId,
              "aggregates",
              "lootData"
            );
            batch.update(aggregateRef, {
              [`items.${lootRef.id}`]: true,
            });

            await batch.commit();

            await logAction({
              action: LogActions.ITEM_ADDED,
              actor: user.uid,
              details: `Added item: ${processedData.itemName} (${method})`,
              sessionId,
              guild: selectedGuild,
            });

            setSelectedItem(null);
            setSuccess("Item added successfully");
          }}
          onCancel={() => setSelectedItem(null)}
        />
      )}
    </Box>
  );
};

export default SessionDetail;
