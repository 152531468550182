import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const GuildInvite: React.FC = () => {
  const { guildName } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyGuild = async () => {
      if (!guildName) return;

      const guildsRef = collection(db, 'guilds');
      const guildSnapshot = await getDocs(guildsRef);
      const guildExists = guildSnapshot.docs.some(doc => doc.id === guildName);

      if (!guildExists) {
        navigate('/not-found');
        return;
      }

      const clientId = "1297526766604124203";
      const redirectUri = process.env.REACT_APP_DISCORD_REDIRECT_URL;

      if (!redirectUri) {
        console.error("REACT_APP_DISCORD_REDIRECT_URL is not defined");
        return;
      }

      const scope = "identify email";
      
      const state = encodeURIComponent(
        JSON.stringify({ guild: guildName, isNewGuild: false })
      );

      const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&response_type=code&scope=${encodeURIComponent(scope)}&state=${state}`;

      window.location.href = discordAuthUrl;
    };

    verifyGuild();
  }, [guildName, navigate]);

  return <div>Verifying guild and redirecting...</div>;
};

export default GuildInvite;
