import React from 'react';
import {
    Box, Container, Grid, Card, CardContent,
    Typography, Button, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow,
    Paper, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip
} from '@mui/material';
import { collection, query, onSnapshot, addDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';
import CreateEvent from './CreateEvent';
import { useNavigate } from 'react-router-dom';

export interface EventData {
    id: string;
    title: string;
    startDate: string;
    endDate: string;
    description: string;
    createdBy: string;
    maxParticipants: number;
    registeredUsers: string[];
    dkpDistributed: boolean;
    dkpValue: number;
}

interface GuildMembership {
    guildId: string;
    role: string;
}

interface UserType {
    memberships?: GuildMembership[];
    uid?: string;
}

const Event: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [events, setEvents] = React.useState<EventData[]>([]);
    const { user, selectedGuild } = useUser();
    const [openCreateEvent, setOpenCreateEvent] = React.useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [eventToDelete, setEventToDelete] = React.useState<string | null>(null);

    const canCreateEvents = (user: UserType | null) => {
        if (!user?.memberships) return false;
        const userGuild = user.memberships.find(m => m.guildId === selectedGuild);
        return userGuild?.role === 'admin' || userGuild?.role === 'officer';
    };

    const handleDeleteClick = (eventId: string) => {
        setEventToDelete(eventId);
        setDeleteConfirmOpen(true);
    };

    const handleDeleteEvent = async (eventId: string) => {
        if (!selectedGuild) return;
        const eventDocRef = doc(db, 'events', selectedGuild, 'guildEvents', eventId);
        await deleteDoc(eventDocRef);
    };

    const handleConfirmDelete = async () => {
        if (eventToDelete) {
            await handleDeleteEvent(eventToDelete);
            setDeleteConfirmOpen(false);
            setEventToDelete(null);
        }
    };

    React.useEffect(() => {
        if (!selectedGuild) return;

        const eventsCollectionRef = collection(db, 'events', selectedGuild, 'guildEvents');
        const q = query(eventsCollectionRef);

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const eventsList: EventData[] = [];
            querySnapshot.forEach((doc) => {
                eventsList.push({ id: doc.id, ...doc.data() } as EventData);
            });
            setEvents(eventsList);
        });

        return () => unsubscribe();
    }, [selectedGuild]);

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
            {/* Hero Header */}
            <Box sx={{
                position: 'relative',
                height: '200px',
                overflow: 'hidden',
            }}>
                {/* Background Image */}
                <Box sx={{
                    backgroundImage: 'url(/images/newsite/bkg.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    filter: 'blur(1px)',
                    zIndex: 1,
                }} />

                {/* Dark Overlay */}
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    zIndex: 2,
                }} />

                {/* Content */}
                <Container maxWidth="lg" sx={{ height: '100%', position: 'relative', zIndex: 3 }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pt: 3
                    }}>
                        <Typography variant="h4" sx={{ color: 'white', fontWeight: 700 }}>
                            Events
                        </Typography>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={() => navigate('/dashboard')}
                            sx={{ color: 'white', borderColor: 'white' }}
                        >
                            Back to Dashboard
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Container maxWidth="lg" sx={{ mt: -2, pt: 4, mb: 4, position: 'relative' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                    <Typography variant="h5">
                                        Upcoming Events
                                    </Typography>
                                    {canCreateEvents(user) && (
                                        <Button
                                            variant="contained"
                                            onClick={() => setOpenCreateEvent(true)}
                                        >
                                            Create Event
                                        </Button>
                                    )}
                                </Box>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: '20%' }}>Event</TableCell>
                                                <TableCell sx={{ width: '15%' }}>Start Date</TableCell>
                                                <TableCell sx={{ width: '15%' }}>End Date</TableCell>
                                                <TableCell sx={{ width: '25%' }}>Description</TableCell>
                                                <TableCell sx={{ width: '5%' }}>Spots</TableCell>
                                                <TableCell sx={{ width: '5%' }}>DKP Value</TableCell>
                                                <TableCell sx={{ width: '5%' }}>Status</TableCell>
                                                <TableCell sx={{ width: '10%' }}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {events.map((event) => (
                                                <TableRow key={event.id} sx={{
                                                    bgcolor: event.dkpDistributed ? theme.palette.action.disabledBackground : 'inherit'
                                                }}>
                                                    <TableCell sx={{ maxWidth: 200 }}>
                                                        <Tooltip title={event.title}>
                                                            <Typography noWrap>
                                                                {event.title}
                                                            </Typography>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        {new Date(event.startDate).toLocaleString()}
                                                    </TableCell>
                                                    <TableCell>
                                                        {new Date(event.endDate).toLocaleString()}
                                                    </TableCell>
                                                    <TableCell sx={{ maxWidth: 300 }}>
                                                        <Tooltip title={event.description}>
                                                            <Typography noWrap>
                                                                {event.description}
                                                            </Typography>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        {event.registeredUsers.length}/{event.maxParticipants}
                                                    </TableCell>
                                                    <TableCell>{event.dkpValue || 0}</TableCell>
                                                    <TableCell>
                                                        {event.dkpDistributed ? (
                                                            <Typography color="text.secondary">Completed</Typography>
                                                        ) : (
                                                            <Typography color="primary">Open</Typography>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => navigate(`/event/${event.id}`)}
                                                            >
                                                                View Details
                                                            </Button>
                                                            {canCreateEvents(user) && (
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    onClick={() => handleDeleteClick(event.id)}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            )}
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <CreateEvent
                    open={openCreateEvent}
                    onClose={() => setOpenCreateEvent(false)}
                />
                <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this event? This action cannot be undone.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
                        <Button onClick={handleConfirmDelete} color="error" variant="contained">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Box>
    );
}
export default Event;
