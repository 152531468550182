import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  Container,
  Box,
  Grid,
  useTheme,
} from '@mui/material';
import { Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const DiscordBot: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  // Image slideshow (same as AboutThisApp for consistency)
  const images = [
    '/images/bot1.png',
    '/images/bot2.png',
    '/images/bot3.png',
    '/images/bot4.png',
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setModalOpen(true);
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // 3 seconds transition

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [images.length]);

  return (
    <>
      {/* Top Section */}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: { xs: 500, sm: 400, md: 400 },
          color: 'white',
          textAlign: 'center',
          flexDirection: 'column',
          overflow: 'visible',
          padding: { xs: 2, md: 0 },
          zIndex: 10,
        }}
      >
        {/* Background and Dark Overlay */}
        <Box
          sx={{
            backgroundImage: 'url(/images/newsite/bkg.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            filter: 'blur(1px)',
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 2,
          }}
        />

        {/* Slideshow Image Frame */}
        <Container sx={{ zIndex: 7, mt: { xs: 2, sm: 6, md: 8 } }}>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <Box
                onClick={() => handleImageClick(currentImageIndex)}
                sx={{
                  width: '100%',
                  height: { xs: 250, sm: 300, md: 400 },
                  backgroundImage: `url(${images[currentImageIndex]})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  border: '2px solid #fff',
                  borderRadius: 0,
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                  zIndex: 10,
                  position: 'relative',
                  transition: 'background-image 1s ease-in-out',
                  backgroundColor: 'black',
                  marginBottom: { xs: -10, sm: -30, md: -40 },
                  cursor: 'pointer',
                }}
              />
            </Grid>

            {/* Content for Discord Bot Integration */}
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <Box sx={{ textAlign: { xs: 'center' }, mt: { xs: 10, sm: 0 } }}>
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    fontWeight: 'bold',
                    fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                  }}
                >
                  Discord Bot Integration
                </Typography>
                <Typography variant="h6" sx={{ mb: 4 }}>
                  Enhance your raiding experience with our Discord bot integration.
                  Subscribe to unlock premium features directly through Discord!
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => window.location.href = 'https://ko-fi.com/lootmanager'}
                  sx={{
                    fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                    padding: { xs: '6px 12px', sm: '8px 16px', md: '10px 20px' },
                  }}
                >
                  Subscribe Now
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container
        sx={{
          maxWidth: 'md',
          mt: { xs: 10, sm: 15, md: 30 },
          p: { xs: 4, sm: 6 },
          backgroundColor: theme.palette.background.paper,
          borderRadius: 4,
          boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.4)',
          textAlign: 'center',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Main Title Section */}
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3, color: theme.palette.primary.main }}>
          Enhance Your Raiding Experience
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, fontSize: '1.1rem', color: theme.palette.text.secondary }}>
          Take your loot sessions to the next level with seamless Discord integration. Subscribe for uninterrupted access to all bidding and rolling features, right from Discord.
        </Typography>

        {/* Premium Access Section */}
        <Box sx={{ p: 3, mb: 5, backgroundColor: theme.palette.primary.light, borderRadius: 3 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.primary.contrastText }}>
            Premium Access - $9.99/month
          </Typography>
          <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText, fontSize: '1.2rem', mb: 2 }}>
            ✨ Guild-Wide Access ✨ <br /> Unlock premium features for your entire guild with one subscription!
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ fontWeight: 'bold', py: 1, px: 4 }}
            onClick={() => window.location.href = 'https://ko-fi.com/lootmanager'}
          >
            Subscribe Now
          </Button>
        </Box>

        {/* Feature List Section */}
        <Box sx={{ mb: 5 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, color: theme.palette.primary.main, textAlign: 'center' }}>
            Features Included
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {[
              {
                title: "Interactive UI with /lm Command",
                description: "Simply type /lm in Discord to instantly access all items posted by your guild. Start rolling and bidding intuitively without ever needing to leave Discord.",
                highlight: true,
              },
              { title: "Bid on items through Discord DMs" },
              { title: "Roll on items with simple commands" },
              { title: "Check DKP balance instantly" },
              { title: "View active sessions and items" },
              { title: "Unlimited guild members access" },
              { title: "Exclusive 'Bot Subscriber ❤️' Discord role for the subscriber" },
            ].map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    p: 3,
                    borderRadius: 2,
                    boxShadow: feature.highlight ? '0px 8px 20px rgba(0, 150, 136, 0.5)' : '0px 6px 18px rgba(0, 0, 0, 0.15)',
                    backgroundColor: feature.highlight ? theme.palette.primary.main : theme.palette.background.default,
                    color: feature.highlight ? theme.palette.primary.contrastText : theme.palette.text.primary,
                    minHeight: '150px', // Set consistent height
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: feature.highlight ? '0px 10px 24px rgba(0, 150, 136, 0.7)' : '0px 8px 20px rgba(0, 0, 0, 0.25)',
                    },
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: feature.highlight ? '1.1rem' : '1rem' }}>
                    {feature.title}
                  </Typography>
                  {feature.description && (
                    <Typography variant="body2" sx={{ mt: 1, fontSize: '0.9rem', px: 2 }}>
                      {feature.description}
                    </Typography>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Setup Guide Section */}
        <Box sx={{ mt: 8, mb: 5 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 4, color: theme.palette.primary.main, textAlign: 'center' }}>
            Complete Setup Guide
          </Typography>
          <Grid container spacing={4}>
            {[
              {
                title: "Join or Create a Guild",
                description: "Register on our website and either join an existing guild or create your own"
              },
              {
                title: "Subscribe to Premium Access",
                description: "Click the Subscribe button above to support the project through Ko-fi"
              },
              {
                title: "Activate Premium Features",
                description: "Join our Discord Community and contact Az to activate premium features for your guild (Automated system coming soon!)"
              },
              {
                title: "Add Bot to Server",
                description: "Invite the bot to your server",
                link: "https://discord.com/oauth2/authorize?client_id=1297526766604124203&permissions=380104689664&integration_type=0&scope=bot+applications.commands"
              },
              {
                title: "Start Using the Bot",
                description: "Use /lm command in any channel to access the interactive UI"
              }
            ].map((step, index) => (
              <Grid item xs={12} key={index}>
                <Box sx={{
                  p: 3,
                  borderRadius: 2,
                  backgroundColor: theme.palette.background.default,
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                }}>
                  <Typography variant="h6" sx={{
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <Box sx={{
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold'
                    }}>
                      {index + 1}
                    </Box>
                    {step.title}
                  </Typography>
                  <Typography variant="body1" sx={{
                    ml: 5,
                    textAlign: 'left'  // Only add textAlign here for instructions
                  }}>
                    {step.description}
                    {step.link && (
                      <Button
                        variant="text"
                        color="primary"
                        href={step.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ ml: 2 }}
                      >
                        Invite Bot
                      </Button>
                    )}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>


        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.9)',
          }}
        >
          <Box sx={{
            position: 'relative',
            maxWidth: '90vw',
            maxHeight: '90vh',
            outline: 'none',
          }}>
            <IconButton
              onClick={() => setModalOpen(false)}
              sx={{
                position: 'absolute',
                right: -40,
                top: -40,
                color: 'white',
              }}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              onClick={handlePrevious}
              sx={{
                position: 'absolute',
                left: -50,
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'white',
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              onClick={handleNext}
              sx={{
                position: 'absolute',
                right: -50,
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'white',
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
            <img
              src={images[selectedImageIndex]}
              alt={`Discord Bot Screenshot ${selectedImageIndex + 1}`}
              style={{
                maxWidth: '100%',
                maxHeight: '90vh',
                objectFit: 'contain',
              }}
            />
          </Box>
        </Modal>
      </Container>
    </>
  );
};

export default DiscordBot;
