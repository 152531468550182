// src/components/NotFound.tsx

import React from "react";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <div className="not-found">
      <h2>404 - Page Not Found</h2>
      <p>The page you're looking for doesn't exist.</p>
      <Link to="/" className="home-link">
        Go to Dashboard
      </Link>
    </div>
  );
};

export default NotFound;
