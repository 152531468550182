import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  writeBatch,
  serverTimestamp,
  increment,
} from "firebase/firestore";
import { UserGuild, LootDrop, Session } from "../interfaces";
import { HandleBidProps } from "../interfaces/handlers";
import { logAction, LogActions } from "../services/logginServices";
import { verifyWishlistStatus } from "../components/Wishlist/WishListServices";
import { checkEventRegistration } from "../services/eventServices";
import { isSessionExpired } from "../components/sessions/checkExpire"
interface WishlistVerification {
  method: "session" | "none" | "custom";
  hours?: number;
}

export const handleBid = async ({
  lootId,
  bidAmount,
  user,
  sessionId,
  selectedGuild,
  session,
  setConfirmationTitle,
  setConfirmationMessage,
  setIsError,
  setConfirmationModalVisible,
  setSuccess,
}: HandleBidProps) => {
  if (!user || !sessionId || !selectedGuild) return;
  const expired = await isSessionExpired(sessionId);
  if (expired) {
      setConfirmationTitle("Session Expired");
      setConfirmationMessage("This session has expired. No more rolls can be made.");
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
  }
  try {
    // Fetch the session to get guildId
    const sessionRef = doc(db, "sessions", sessionId);
    const sessionDoc = await getDoc(sessionRef);
    if (!sessionDoc.exists()) {
      throw new Error("Session not found.");
    }
    const sessionData = sessionDoc.data() as Session;
    const guildId = sessionData.guild;

    // Fetch loot data
    const lootRef = doc(db, "sessions", sessionId, "lootDrops", lootId);
    const lootDoc = await getDoc(lootRef);
    if (!lootDoc.exists()) {
      throw new Error("Loot item not found.");
    }
    const lootData = lootDoc.data() as LootDrop;

    // Verify wishlist status if required
    if (lootData.wishlistOnly === true) {
      const verification: WishlistVerification =
        typeof lootData.wishlistVerification === "string"
          ? { method: "none" }
          : (lootData.wishlistVerification as WishlistVerification);

      const isWishlisted = await verifyWishlistStatus(
        user.uid,
        lootData.itemName,
        session,
        verification
      );

      if (!isWishlisted) {
        const hours = verification.hours || 0;
        const message =
          hours > 0
            ? `This item requires you to have it in your wishlist for at least ${hours} hours before bidding.`
            : "This item requires you to have it in your wishlist to bid.";

        setConfirmationTitle("Wishlist Required");
        setConfirmationMessage(message);
        setIsError(true);
        setConfirmationModalVisible(true);
        return;
      }
    }

    // Check event registration if applicable
    const canParticipate = await checkEventRegistration(
      sessionId,
      user.uid,
      selectedGuild
    );

    if (!canParticipate) {
      setConfirmationTitle("Error");
      setConfirmationMessage(
        "You must be registered for the linked event to participate."
      );
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
    }

    // Fetch user guild data
    const userGuildRef = doc(db, "userGuilds", `${user.uid}_${guildId}`);
    const userGuildDoc = await getDoc(userGuildRef);

    if (!userGuildDoc.exists()) {
      throw new Error("User guild data not found.");
    }
    const userGuildData = userGuildDoc.data() as UserGuild;

    // Check DKP balance
    if ((userGuildData.dkp || 0) < bidAmount) {
      setConfirmationTitle("Insufficient DKP");
      setConfirmationMessage("You do not have enough DKP to place this bid.");
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
    }

    // Check if user is already the highest bidder
    const isLootmaster = user.uid === sessionData.lootmaster;
    const canSeeBids =
      !lootData.isPrivateBidding || isLootmaster || user?.role === "admin";

    if (lootData.currentBidder === user.uid) {
      setConfirmationTitle("Current Highest Bidder");
      setConfirmationMessage(
        canSeeBids
          ? "You are currently winning this item!"
          : `You are currently winning with a bid of ${lootData.currentBid} DKP!`
      );
      setIsError(false);
      setConfirmationModalVisible(true);
      return;
    }

    // Validate bid amount
    const isFirstBid = lootData.currentBid === 0;
    const minimumBid = isFirstBid
      ? 1
      : lootData.currentBid + (lootData.minIncrement || 1);

    if (bidAmount < minimumBid) {
      setConfirmationTitle("Bid Error");
      setConfirmationMessage(
        `Your bid must be at least ${minimumBid} DKP${
          isFirstBid ? "" : ` (current bid + minimum increment)`
        }.`
      );
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
    }

    // Begin batch operation
    const batch = writeBatch(db);

    // Create new bid
    const bidRef = doc(
      collection(db, "sessions", sessionId, "lootDrops", lootId, "bids")
    );
    batch.set(bidRef, {
      bidderId: user.uid,
      bidderName: userGuildData.inGameName || user.displayName || "Unknown",
      amount: bidAmount,
      timestamp: serverTimestamp(),
    });

    // Update aggregate data
    const aggregateRef = doc(
      db,
      "sessions",
      sessionId,
      "aggregates",
      "lootData"
    );
    batch.update(aggregateRef, {
      [`currentBids.${lootId}`]: bidAmount,
      [`bidCounts.${lootId}`]: increment(1),
    });

    // Refund previous bidder if applicable
    if (lootData.currentBidder && lootData.currentBid > 0) {
      const previousBidderId = lootData.currentBidder;
      const previousBidAmount = lootData.currentBid;
      const previousUserGuildRef = doc(
        db,
        "userGuilds",
        `${previousBidderId}_${guildId}`
      );
      const previousUserGuildDoc = await getDoc(previousUserGuildRef);

      if (previousUserGuildDoc.exists()) {
        const previousUserGuildData =
          previousUserGuildDoc.data() as UserGuild;

        // Refund DKP
        batch.update(previousUserGuildRef, {
          dkp: (previousUserGuildData.dkp || 0) + previousBidAmount,
        });

        // Log refund
        await logAction({
          action: LogActions.BID_REFUNDED,
          actor: "system",
          details: `Refunded ${previousBidAmount} DKP to ${
            previousUserGuildData.username
          } for item ${lootData.itemName} (Outbid)`,
          sessionId,
          guild: selectedGuild,
        });
      }
    }

    // Deduct DKP from current bidder
    batch.update(userGuildRef, {
      dkp: (userGuildData.dkp || 0) - bidAmount,
    });

    // Log DKP deduction
    const dkpLogRef = doc(collection(db, "dkpLogs"));
    batch.set(dkpLogRef, {
      userId: user.uid,
      username: userGuildData.username || user.displayName || "Unknown",
      changeAmount: -bidAmount,
      reason: `Bid placed for item ${lootData.itemName} (ID: ${lootId})`,
      timestamp: serverTimestamp(),
      adminId: user.uid,
      adminName: userGuildData.username || user.displayName || "Unknown",
      guild: selectedGuild,
    });

    // Update loot data
    batch.update(lootRef, {
      currentBid: bidAmount,
      currentBidder: user.uid,
      currentBidderName: userGuildData.inGameName || user.displayName || "Unknown",
    });

    // Log bid placed
    await logAction({
      action: LogActions.BID_PLACED,
      actor: user.uid,
      details: `Placed bid of ${bidAmount} DKP on item ${lootData.itemName}`,
      sessionId,
      guild: selectedGuild,
    });

    // Commit batch
    await batch.commit();

    // Success message
    setSuccess("Bid placed successfully!");
    setConfirmationTitle("Bid Successful");
    setConfirmationMessage(
      canSeeBids
        ? `Your bid of ${bidAmount} DKP has been placed.`
        : `Your bid of ${bidAmount} DKP has been placed. You are now the highest bidder!`
    );
    setIsError(false);
    setConfirmationModalVisible(true);
  } catch (error: any) {
    setConfirmationTitle("Error");
    setConfirmationMessage(error.message);
    setIsError(true);
    setConfirmationModalVisible(true);
  }
};
