import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    TextField,
    IconButton,
    Tooltip,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { nanoid } from 'nanoid';

interface EventParticipationLinkProps {
    open: boolean;
    onClose: () => void;
    eventId: string;
    guildId: string;
    registeredUsers: string[];
}

const EventParticipationLink: React.FC<EventParticipationLinkProps> = ({
    open,
    onClose,
    eventId,
    guildId,
    registeredUsers
}) => {
    const [participationCode, setParticipationCode] = React.useState('');
    const [linkExpiry, setLinkExpiry] = React.useState<Date | null>(null);
    const [duration, setDuration] = React.useState(5); // Default to 5 minutes

    const generateNewCode = async () => {
        const code = nanoid(8);
        const expiryTime = new Date();
        expiryTime.setMinutes(expiryTime.getMinutes() + duration);

        const eventRef = doc(db, 'events', guildId, 'guildEvents', eventId);
        await updateDoc(eventRef, {
            participationCode: code,
            participationCodeExpiry: Timestamp.fromDate(expiryTime),
            verifiedParticipants: [],
            participationAttempts: {},
            maxAttempts: 2,
            activeVerification: true
        });

        setParticipationCode(code);
        setLinkExpiry(expiryTime);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Start Attendance Verification</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 2 }}>
                    <Typography>
                        Generate a verification link for {registeredUsers.length} registered participants.
                        Link will expire after first use or selected duration.
                    </Typography>

                    <FormControl fullWidth>
                        <InputLabel>Verification Duration</InputLabel>
                        <Select
                            value={duration}
                            label="Verification Duration"
                            onChange={(e) => setDuration(Number(e.target.value))}
                        >
                            <MenuItem value={3}>3 minutes</MenuItem>
                            <MenuItem value={5}>5 minutes</MenuItem>
                            <MenuItem value={10}>10 minutes</MenuItem>
                        </Select>
                    </FormControl>

                    {participationCode ? (
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Verification Link"
                                value={`${window.location.origin}/event-verification/${eventId}?code=${participationCode}`}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <Tooltip title="Copy to clipboard">
                                            <IconButton onClick={() => navigator.clipboard.writeText(`${window.location.origin}/event-verification/${eventId}?code=${participationCode}`)}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ),
                                }}
                            />
                            <Typography variant="caption" color="error" sx={{ mt: 1, display: 'block' }}>
                                Expires at: {linkExpiry?.toLocaleTimeString()} • Single-use per participant
                            </Typography>
                        </Box>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={generateNewCode}
                            fullWidth
                        >
                            Start Verification
                        </Button>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EventParticipationLink;
