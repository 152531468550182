import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { doc, updateDoc, arrayUnion, arrayRemove, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';
import { Timestamp } from 'firebase/firestore';

interface WishlistItem {
    itemName: string;
    imageUrl: string;
    url: string;
    addedAt: Timestamp;
}

interface WishlistPanelProps {
    selectedGameMode: "Throne and Liberty" | "Custom";
    searchTerm: string;
    filteredItems: ItemType[];
    onGameModeChange: (mode: "Throne and Liberty" | "Custom") => void;
    onSearchChange: (term: string) => void;
}

interface ItemType {
    name: string;
    imageFilename: string;
    url?: string;
}


export const WishlistPanel: React.FC<WishlistPanelProps> = ({
    selectedGameMode,
    searchTerm,
    filteredItems,
    onGameModeChange,
    onSearchChange,
}) => {
    const theme = useTheme();
    const { user, selectedGuild } = useUser();
    const [wishlistItems, setWishlistItems] = useState<WishlistItem[]>([]);
    const [isLocked, setIsLocked] = useState(false);

    useEffect(() => {
        if (!user || !selectedGuild) return;

        const userGuildRef = doc(db, 'userGuilds', `${user.uid}_${selectedGuild}`);
        const unsubscribe = onSnapshot(userGuildRef, (doc) => {
            if (doc.exists()) {
                setWishlistItems(doc.data()?.wishlist || []);
                setIsLocked(doc.data()?.wishlistLocked || false);
            }
        });

        return () => unsubscribe();
    }, [user, selectedGuild]);

    const handleAddToWishlist = async (item: ItemType) => {
        if (!user || !selectedGuild) return;
        const userGuildRef = doc(db, 'userGuilds', `${user.uid}_${selectedGuild}`);

        const isDuplicate = wishlistItems.some(
            wishlistItem => wishlistItem.itemName === item.name
        );

        if (isDuplicate) {
            return;
        }

        const wishlistItem = {
            itemName: item.name,
            imageUrl: `/images/new/${item.imageFilename}`,
            url: item.url || '',
            addedAt: Timestamp.now()
        };

        try {
            await updateDoc(userGuildRef, {
                wishlist: arrayUnion(wishlistItem)
            });
        } catch (error) {
            console.error('Error adding item to wishlist:', error);
        }
    };

    const handleRemoveFromWishlist = async (item: WishlistItem) => {
        if (!user || !selectedGuild) return;
        const userGuildRef = doc(db, 'userGuilds', `${user.uid}_${selectedGuild}`);

        try {
            await updateDoc(userGuildRef, {
                wishlist: arrayRemove(item)
            });
        } catch (error) {
            console.error('Error removing item from wishlist:', error);
        }
    };
    const filteredWishlistItems = wishlistItems.filter(item =>
        item.itemName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <Box sx={{ bgcolor: theme.palette.background.paper, borderRadius: 2, p: 4, boxShadow: 3, mb: 3 }}>
            {isLocked && (
                <Box
                    sx={{
                        bgcolor: 'error.main',
                        color: 'white',
                        p: 2,
                        mb: 3,
                        borderRadius: 1,
                        textAlign: 'center'
                    }}
                >
                    <Typography variant="body1">
                        Your wishlist has been locked by an admin. Please contact your guild administrators to unlock it.
                    </Typography>
                </Box>
            )}

            {/* Search Panel */}
            <Box sx={{
                maxHeight: '300px',
                overflowY: 'scroll',
                border: '1px solid',
                borderColor: theme.palette.divider,
                borderRadius: 1,
                p: 2,
                mb: 3,
                bgcolor: theme.palette.background.default,
            }}>
                <input
                    type="text"
                    placeholder="Search for items..."
                    value={searchTerm}
                    onChange={(e) => onSearchChange(e.target.value)}
                    style={{
                        width: '100%',
                        padding: '8px',
                        marginBottom: '8px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                    }}
                />

                {filteredItems.map((item, index) => (
                    <Box
                        key={`${item.name}-${index}`}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                            mb: 2,
                            bgcolor: theme.palette.background.paper,
                            borderRadius: 1,
                            boxShadow: 1,
                        }}
                    >
                        <Box
                            component="img"
                            src={`/images/new/${item.imageFilename}`}
                            alt={item.name}
                            sx={{ width: 50, height: 50, borderRadius: 1, mr: 2 }}
                        />
                        <Box sx={{ flex: 1, mr: 2 }}>
                            <Typography
                                component="a"
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="body1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'primary.main',
                                    textDecoration: 'none',
                                    '&:hover': { textDecoration: 'underline' }
                                }}
                            >
                                {item.name}
                            </Typography>
                        </Box>
                        <Button
                            variant="outlined"
                            onClick={() => handleAddToWishlist(item)}
                            disabled={isLocked}
                            sx={{
                                whiteSpace: 'nowrap',
                                padding: '4px 8px',
                                fontSize: '0.875rem',
                                color: theme.palette.primary.main,
                            }}
                        >
                            Add to Wishlist
                        </Button>
                    </Box>
                ))}
            </Box>

            {/* Wishlist Display Panel */}
            <Divider sx={{ my: 3 }} />
            <Typography variant="h6" sx={{ mb: 2 }}>
                Your Wishlist
            </Typography>
            <Box sx={{
                maxHeight: '300px',
                overflowY: 'scroll',
                border: '1px solid',
                borderColor: theme.palette.divider,
                borderRadius: 1,
                p: 2,
                bgcolor: theme.palette.background.default,
            }}>
                {filteredWishlistItems.map((item, index) => (
                    <Box
                        key={`wishlist-${index}`}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                            mb: 2,
                            bgcolor: theme.palette.background.paper,
                            borderRadius: 1,
                            boxShadow: 1,
                        }}
                    >
                        <Box
                            component="img"
                            src={item.imageUrl}
                            alt={item.itemName}
                            sx={{ width: 50, height: 50, borderRadius: 1, mr: 2 }}
                        />
                        <Box sx={{ flex: 1, mr: 2 }}>
                            <Typography
                                component="a"
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="body1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'primary.main',
                                    textDecoration: 'none',
                                    '&:hover': { textDecoration: 'underline' }
                                }}
                            >
                                {item.itemName}
                            </Typography>
                            <Typography variant="caption" display="block" color="text.secondary">
                                Added: {item.addedAt instanceof Timestamp ? item.addedAt.toDate().toLocaleString() : 'Just now'}
                            </Typography>
                        </Box>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleRemoveFromWishlist(item)}
                            disabled={isLocked}
                            sx={{
                                whiteSpace: 'nowrap',
                                padding: '4px 8px',
                                fontSize: '0.875rem',
                            }}
                        >
                            Remove
                        </Button>
                    </Box>
                ))}
                {wishlistItems.length === 0 && (
                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', py: 2 }}>
                        No items in your wishlist yet
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
