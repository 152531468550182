import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  collection,
  doc,
  writeBatch,
} from 'firebase/firestore';
import { UserGuild } from '../interfaces';
import {
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

interface DKPDecayProps {
  adminGuild: string;
  users: UserGuild[];
  setUsers: React.Dispatch<React.SetStateAction<UserGuild[]>>;
}

interface UserGuildWithNewDkp extends UserGuild {
  newDkp?: number;
}

const DKPDecay: React.FC<DKPDecayProps> = ({ adminGuild, users, setUsers }) => {
  const [decayRate, setDecayRate] = useState<number>(0);
  const [taxRate, setTaxRate] = useState<number>(0);
  const [decayPreview, setDecayPreview] = useState<UserGuildWithNewDkp[]>([]);
  const [taxPreview, setTaxPreview] = useState<UserGuildWithNewDkp[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showDecayPreview, setShowDecayPreview] = useState<boolean>(false);
  const [showTaxPreview, setShowTaxPreview] = useState<boolean>(false);

  // Update decay preview when decayRate or users change
  useEffect(() => {
    if (decayRate > 0) {
      previewDecay(decayRate, users);
    }
  }, [decayRate, users]);

  // Update tax preview when taxRate or users change
  useEffect(() => {
    if (taxRate > 0) {
      previewTax(taxRate, users);
    }
  }, [taxRate, users]);

  // Preview decay
  const previewDecay = (
    rate: number,
    currentUsers: UserGuildWithNewDkp[]
  ) => {
    if (rate <= 0) {
      setError('Please enter a valid decay rate.');
      return;
    }
    const preview = currentUsers.map((user) => ({
      ...user,
      newDkp: parseFloat((user.dkp * (1 - rate / 100)).toFixed(2)),
    }));
    setDecayPreview(preview);
    setShowDecayPreview(true);
  };

  // Preview tax
  const previewTax = (rate: number, currentUsers: UserGuildWithNewDkp[]) => {
    if (rate <= 0) {
      setError('Please enter a valid tax rate.');
      return;
    }
    const preview = currentUsers.map((user) => ({
      ...user,
      newDkp: parseFloat((user.dkp - user.dkp * (rate / 100)).toFixed(2)),
    }));
    setTaxPreview(preview);
    setShowTaxPreview(true);
  };

  const applyDecay = async () => {
    if (decayRate <= 0) {
      setError('Please enter a valid decay rate.');
      return;
    }
    if (
      !window.confirm(
        `Are you sure you want to apply a ${decayRate}% DKP decay to all users?`
      )
    ) {
      return;
    }
    setLoading(true);
    setError('');
    try {
      const batch = writeBatch(db);

      users.forEach((user) => {
        const userGuildRef = doc(db, 'userGuilds', user.id);
        const newDkp = parseFloat(
          (user.dkp * (1 - decayRate / 100)).toFixed(2)
        );
        batch.update(userGuildRef, { dkp: newDkp });

        const logRef = doc(collection(db, 'dkpLogs'));
        batch.set(logRef, {
          userId: user.uid || user.id,
          username: user.username,
          changeAmount: parseFloat((-user.dkp * (decayRate / 100)).toFixed(2)),
          reason: `DKP Decay of ${decayRate}%`,
          timestamp: new Date(),
          adminId: 'system',
          adminName: 'System',
          guild: adminGuild,
        });
      });

      await batch.commit();
      setError('');
      setDecayPreview([]);
      setShowDecayPreview(false);

      // Update users in the parent component
      setUsers((prevUsers) =>
        prevUsers.map((user) => ({
          ...user,
          dkp: parseFloat((user.dkp * (1 - decayRate / 100)).toFixed(2)),
        }))
      );

    } catch (err) {
      console.error('Failed to apply decay:', err);
      setError('Failed to apply decay');
    } finally {
      setLoading(false);
    }
  };

  const applyTax = async () => {
    if (taxRate <= 0) {
      setError('Please enter a valid tax rate.');
      return;
    }
    if (
      !window.confirm(
        `Are you sure you want to apply a ${taxRate}% DKP tax to all users?`
      )
    ) {
      return;
    }
    setLoading(true);
    setError('');
    try {
      const batch = writeBatch(db);

      users.forEach((user) => {
        const userGuildRef = doc(db, 'userGuilds', user.id);
        const taxAmount = parseFloat(
          (user.dkp * (taxRate / 100)).toFixed(2)
        );
        const newDkp = parseFloat((user.dkp - taxAmount).toFixed(2));
        batch.update(userGuildRef, { dkp: newDkp });

        const logRef = doc(collection(db, 'dkpLogs'));
        batch.set(logRef, {
          userId: user.uid || user.id,
          username: user.username,
          changeAmount: -taxAmount,
          reason: `DKP Tax of ${taxRate}%`,
          timestamp: new Date(),
          adminId: 'system',
          adminName: 'System',
          guild: adminGuild,
        });
      });

      await batch.commit();
      setError('');
      setTaxPreview([]);
      setShowTaxPreview(false);

      // Update users in the parent component
      setUsers((prevUsers) =>
        prevUsers.map((user) => {
          const taxAmount = parseFloat(
            (user.dkp * (taxRate / 100)).toFixed(2)
          );
          return {
            ...user,
            dkp: parseFloat((user.dkp - taxAmount).toFixed(2)),
          };
        })
      );

    } catch (err) {
      console.error('Failed to apply tax:', err);
      setError('Failed to apply tax');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
        DKP Decay and Tax Management
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
  
      {/* Decay Management Section */}
      <Box sx={{ background: 'rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(10px)', boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)', padding: 2, borderRadius: 2, mb: 4 }}>
        <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>
          Apply DKP Decay
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Decay Rate (%)"
              type="number"
              fullWidth
              value={decayRate}
              onChange={(e) => setDecayRate(Number(e.target.value))}
              placeholder="Enter decay rate (e.g., 5)"
              sx={{ color: 'white' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => previewDecay(decayRate, users)}
              sx={{ flex: 1 }}
            >
              Preview Decay
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={applyDecay}
              disabled={loading}
              sx={{ flex: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Apply Decay'}
            </Button>
          </Grid>
        </Grid>
  
        <Collapse in={showDecayPreview}>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Decay Preview</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ backgroundColor: 'transparent' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'white' }}>Username</TableCell>
                    <TableCell sx={{ color: 'white' }}>Current DKP</TableCell>
                    <TableCell sx={{ color: 'white' }}>New DKP</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {decayPreview.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell sx={{ color: 'white' }}>{user.username}</TableCell>
                      <TableCell sx={{ color: 'white' }}>{user.dkp.toFixed(2)}</TableCell>
                      <TableCell sx={{ color: 'white' }}>{user.newDkp?.toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </Box>

    </Box>
  );
  
};

export default DKPDecay;
