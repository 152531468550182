// src/components/ProtectedRoute.tsx

import React from "react";
import { Navigate } from "react-router-dom";
import useUser from "../hooks/useUser";

interface ProtectedRouteProps {
  children: React.ReactNode;
  adminOnly?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, adminOnly = false }) => {
  const { user, loading, selectedGuild } = useUser();

  if (loading) return <div>Loading...</div>;
  if (!user) {
    //console.log("ProtectedRoute - No user found, redirecting to /login");
    return <Navigate to="/login" />;
  }

  if (!selectedGuild) {
    //console.log("ProtectedRoute - No guild selected, redirecting to /select-guild");
    return <Navigate to="/select-guild" />;
  }

  // Find the membership for the selected guild
  const membership = user.memberships.find((m) => m.guildId === selectedGuild);

  if (!membership) {
    //console.log("ProtectedRoute - No membership found for the selected guild, redirecting to /login");
    return <Navigate to="/login" />;
  }

  if (membership.status === "pending") {
    //console.log("ProtectedRoute - User status pending, redirecting to /pending-approval");
    return <Navigate to="/pending-approval" />;
  }
  if (membership.status === "rejected") {
    //console.log("ProtectedRoute - User status rejected, redirecting to /rejected");
    return <Navigate to="/rejected" />;
  }

  if (adminOnly && membership.role !== "admin") {
    return <Navigate to={`/dashboard`} />;
  }

  //console.log("ProtectedRoute - Access granted to", children);
  return <>{children}</>;
};

export default ProtectedRoute;
