import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Navigation from "./components/Navigation";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import SessionList from "./components/sessions/SessionList";
import SessionDetail from "./components/SessionDetail";
import NotFound from "./components/NotFound";
import AboutThisApp from "./components/AboutThisApp";
import PendingApproval from "./components/PendingApproval";
import Rejected from "./components/Rejected";
import DKPManagement from "./components/DKPManagement";
import UserManagement from "./components/UserManagement";
import UserDetails from "./components/UserDetails";
import SelectGuild from "./components/SelectGuild";
import useUser, { UserProvider } from "./hooks/useUser";
import Auth from "./components/Auth";
import ProtectedRoute from "./components/ProtectedRoute";
import News from "./components/News";
import DiscordBot from './components/DiscordBot';
import AdminLogs from './components/AdminLogs';
import UpdateNotification from './components/UpdateNotification';
import GuildInvite from './components/GuildInvite';
import Event from './components/events/Event';
import CreateEvent from './components/events/CreateEvent';
import EventDetails from "./components/events/EventDetails";
import Wishlist from "./components/Wishlist/Wishlist";
import EventRegistration from './components/events/EventRegistration';
import { useLocation } from "react-router-dom"; // Import useLocation

// Mantis Imports
import ThemeCustomization from './themes';
import Locales from './components/Locales';
import ScrollTop from './components/ScrollTop';
import Snackbar from './components/@extended/Snackbar';
import Notistack from './components/third-party/Notistack';

const AppRoutes: React.FC = () => {
  const { user, loading, selectedGuild } = useUser();
  const location = useLocation(); // Get the current route location
  const restrictedRoutes = ["/login", "/about", "/news", "/discord-bot"];

  if (loading) return <div>Loading...</div>;

  return (
    <>
      {/* Render UpdateNotification only if not on the login page */}
      {!restrictedRoutes.includes(location.pathname) && <UpdateNotification />}
      <Navigation />
      <Routes>
        <Route path="/guild/:guildName" element={<GuildInvite />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/login" element={user ? <Navigate to="/select-guild" /> : <Login />} />
        <Route path="/about" element={<AboutThisApp />} />
        <Route path="/news" element={<News />} />
        <Route path="/discord-bot" element={<DiscordBot />} />
        <Route path="/pending-approval" element={<PendingApproval />} />
        <Route path="/rejected" element={<Rejected />} />
        <Route path="/select-guild" element={<SelectGuild />} />
        <Route path="/event-registration/:eventId" element={<EventRegistration />} />
        <Route
          path="/event-verification/:eventId"
          element={<EventRegistration />}
        />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sessions"
          element={
            <ProtectedRoute>
              <SessionList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/session/:sessionId"
          element={
            <ProtectedRoute>
              <SessionDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dkp-management"
          element={
            <ProtectedRoute adminOnly>
              <DKPManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-management"
          element={
            <ProtectedRoute adminOnly>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-logs"
          element={
            <ProtectedRoute adminOnly>
              <AdminLogs />
            </ProtectedRoute>
          }
        />
        <Route path="/user-details" element={<UserDetails />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="*" element={<NotFound />} />
        <Route
          path="/events"
          element={
            <ProtectedRoute>
              <Event />
            </ProtectedRoute>
          }
        />
        <Route
          path="/event/:eventId"
          element={
            <ProtectedRoute>
              <EventDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path="/wishlist"
          element={
            <ProtectedRoute>
              <Wishlist />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

const App: React.FC = () => (
  <ThemeCustomization>
    <Locales>
      <ScrollTop>
        <UserProvider>
          <Notistack>
            <Router>
              <AppRoutes />
            </Router>
            <Snackbar />
          </Notistack>
        </UserProvider>
      </ScrollTop>
    </Locales>
  </ThemeCustomization>
);

export default App;