// LootMethodModal.tsx
import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Switch,
  FormControlLabel,
  Stack,
  Divider,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@mui/material';

interface LootMethodModalProps {
  itemName: string;
  itemImage: string;
  isCustom: boolean;
  onConfirm: (
    method: "dkp" | "roll",
    isPrivateBidding: boolean,
    minIncrement: number,
    selectedTrait: string,
    itemName?: string,
    wishlistOnly?: boolean,
    wishlistVerification?: {
      method: "none" | "session" | "custom",
      hours?: number
    },
    requireBidReason?: boolean
  ) => void;
  onCancel: () => void;
}

const LootMethodModal: React.FC<LootMethodModalProps> = ({
  itemName,
  itemImage,
  isCustom,
  onConfirm,
  onCancel,
}) => {
  const [isPrivateBidding, setIsPrivateBidding] = useState(false);
  const [minIncrement, setMinIncrement] = useState(1);
  const [selectedTrait, setSelectedTrait] = useState("");
  const [customItemName, setCustomItemName] = useState(itemName);
  const [wishlistOnly, setWishlistOnly] = useState(false);
  const [wishlistTimeCheck, setWishlistTimeCheck] = useState<"none" | "session" | "custom">("none");
  const [customWishlistTime, setCustomWishlistTime] = useState(24);
  const [requireBidReason, setRequireBidReason] = useState(false);

  const canConfirm = selectedTrait.trim() && (!isCustom || customItemName.trim());

  return (
    <Modal open={true} onClose={onCancel}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        maxWidth: 500,
        width: '90%',
        maxHeight: '90vh',
        overflow: 'auto',
      }}>
        <Stack spacing={3}>
          {/* Header */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              component="img"
              src={itemImage}
              alt={itemName}
              sx={{ width: 64, height: 64, borderRadius: 1 }}
            />
            <Typography variant="h6">
              {isCustom ? "Add Custom Item to Loot Pool" : `Add "${itemName}" to Loot Pool`}
            </Typography>
          </Box>

          <Divider />

          {/* Custom Item Name Input */}
          {isCustom && (
            <TextField
              fullWidth
              label="Item Name"
              value={customItemName}
              onChange={(e) => setCustomItemName(e.target.value)}
              placeholder="Enter item name..."
            />
          )}

          {/* Trait Input */}
          <TextField
            required
            fullWidth
            label="Trait"
            value={selectedTrait}
            onChange={(e) => setSelectedTrait(e.target.value)}
            placeholder="Enter item trait..."
            helperText="Trait is required (e.g., 'Crit Damage')"
          />

          {/* Global Loot Settings */}
          <Box sx={{ bgcolor: 'background.default', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Global Loot Settings
            </Typography>
            <Stack spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={wishlistOnly}
                    onChange={(e) => {
                      setWishlistOnly(e.target.checked);
                      if (!e.target.checked) {
                        setWishlistTimeCheck("none");
                      }
                    }}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body1">Wishlist Only</Typography>
                    <Typography variant="caption" color="text.secondary">
                      Only players who wishlisted this item can participate
                    </Typography>
                  </Box>
                }
              />

              {/* Wishlist Verification Method */}
              {wishlistOnly && (
                <Box sx={{ mt: 2 }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Wishlist Verification Method</FormLabel>
                    <RadioGroup
                      aria-label="wishlist-verification"
                      name="wishlistVerification"
                      value={wishlistTimeCheck}
                      onChange={(e) => setWishlistTimeCheck(e.target.value as "none" | "session" | "custom")}
                    >
                      <FormControlLabel
                        value="session"
                        control={<Radio />}
                        label={
                          <Box>
                            <Typography variant="body1">Session Start Check</Typography>
                            <Typography variant="caption" color="text.secondary">
                              Only consider wishlists created before this session started
                            </Typography>
                          </Box>
                        }
                      />
                      <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        label={
                          <Box>
                            <Typography variant="body1">Time-based Check</Typography>
                            <Typography variant="caption" color="text.secondary">
                              Only consider wishlists created before a specific time period
                            </Typography>
                          </Box>
                        }
                      />
                    </RadioGroup>
                  </FormControl>

                  {wishlistTimeCheck === "custom" && (
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        type="number"
                        label="Hours Before Now"
                        value={customWishlistTime}
                        onChange={(e) => setCustomWishlistTime(parseInt(e.target.value))}
                        InputProps={{ inputProps: { min: 1 } }}
                        helperText="Specify the time window in hours"
                        fullWidth
                      />
                    </Box>
                  )}
                </Box>
              )}

              <FormControlLabel
                control={
                  <Switch
                    checked={requireBidReason}
                    onChange={(e) => setRequireBidReason(e.target.checked)}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body1">Require Reason</Typography>
                    <Typography variant="caption" color="text.secondary">
                      Players must provide a reason when bidding or rolling
                    </Typography>
                  </Box>
                }
              />
            </Stack>
          </Box>

          {/* DKP Section */}
          <Box sx={{ bgcolor: 'background.default', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              DKP Bidding Settings
            </Typography>
            <Stack spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPrivateBidding}
                    onChange={(e) => setIsPrivateBidding(e.target.checked)}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body1">Private Bidding</Typography>
                    <Typography variant="caption" color="text.secondary">
                      Bids will be hidden until bidding ends
                    </Typography>
                  </Box>
                }
              />

              <TextField
                type="number"
                label="Minimum Increment"
                value={minIncrement}
                onChange={(e) => setMinIncrement(parseInt(e.target.value))}
                InputProps={{ inputProps: { min: 1 } }}
              />

              <Button
                variant="contained"
                fullWidth
                disabled={!canConfirm}
                onClick={() =>
                  onConfirm(
                    "dkp",
                    isPrivateBidding,
                    minIncrement,
                    selectedTrait,
                    customItemName,
                    wishlistOnly,
                    wishlistOnly
                      ? {
                          method: wishlistTimeCheck,
                          hours: wishlistTimeCheck === "custom" ? customWishlistTime : undefined,
                        }
                      : undefined,
                    requireBidReason
                  )
                }
              >
                Start DKP Bidding
              </Button>
            </Stack>
          </Box>

          {/* Roll Section */}
          <Box sx={{ bgcolor: 'background.default', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Random Roll
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disabled={!canConfirm}
              onClick={() =>
                onConfirm(
                  "roll",
                  false,
                  0,
                  selectedTrait,
                  customItemName,
                  wishlistOnly,
                  wishlistOnly
                    ? {
                        method: wishlistTimeCheck,
                        hours: wishlistTimeCheck === "custom" ? customWishlistTime : undefined,
                      }
                    : undefined,
                  requireBidReason
                )
              }
            >
              Start Random Roll
            </Button>
          </Box>

          {/* Cancel Button */}
          <Button variant="outlined" fullWidth onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default LootMethodModal;
