import { db } from "../firebase";
import { collection, query, where, getDocs, addDoc, serverTimestamp, doc, getDoc, updateDoc, increment } from "firebase/firestore";
import { HandleRollLootProps } from "../interfaces/handlers";
import { logAction, LogActions } from "../services/logginServices"
import { verifyWishlistStatus } from "../components/Wishlist/WishListServices";
import { isSessionExpired } from "../components/sessions/checkExpire"

export const handleRollLoot = async ({
  lootId,
  user,
  sessionId,
  reason, // Added reason parameter
  setConfirmationTitle,
  setConfirmationMessage,
  setIsError,
  setConfirmationModalVisible,
  setSuccess,
  setUserRolls,
  selectedGuild,
  session,
}: HandleRollLootProps & { reason?: string }) => {

  if (!user || !sessionId) return;

  const expired = await isSessionExpired(sessionId);
  if (expired) {
    setConfirmationTitle("Session Expired");
    setConfirmationMessage("This session has expired. No more rolls can be made.");
    setIsError(true);
    setConfirmationModalVisible(true);
    return;
  }

  try {
    const lootRef = doc(db, "sessions", sessionId, "lootDrops", lootId);
    const lootDoc = await getDoc(lootRef);
    const lootData = lootDoc.data();
    const itemName = lootData?.itemName || "Unknown Item";

    if (lootData?.wishlistOnly === true) {
      const isWishlisted = await verifyWishlistStatus(
        user.uid,
        itemName,
        session,
        lootData.wishlistVerification
      );

      if (!isWishlisted) {
        setConfirmationTitle("Wishlist Required");
        const hours = lootData.wishlistVerification?.hours || 0;
        const message = hours > 0
          ? `This item requires you to have it in your wishlist for at least ${hours} hours before rolling.`
          : "This item requires you to have it in your wishlist to roll.";

        setConfirmationMessage(message);
        setIsError(true);
        setConfirmationModalVisible(true);
        return;
      }
    }

    const rollsRef = collection(db, "sessions", sessionId, "lootDrops", lootId, "rolls");

    const q = query(rollsRef, where("rollerId", "==", user.uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      setConfirmationTitle("Roll Error");
      setConfirmationMessage("You have already rolled for this item.");
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
    }

    const rolledNumber = Math.floor(Math.random() * 100) + 1;

    await addDoc(rollsRef, {
      rollerId: user.uid,
      rollerName: user.displayName || "Unknown",
      roll: rolledNumber,
      createdAt: serverTimestamp(),
      sessionId: sessionId,
      reason: reason || null, // Store the reason
    });

    const aggregateRef = doc(db, "sessions", sessionId, "aggregates", "lootData");
    await updateDoc(aggregateRef, {
      [`rollCounts.${lootId}`]: increment(1)
    });

    // Update loot document with highest roll if applicable
    const existingHighestRoll = lootData?.highestRoll || -1;
    if (rolledNumber > existingHighestRoll) {
      await Promise.all([
        updateDoc(lootRef, {
          highestRoll: rolledNumber,
          highestRollerName: user.displayName || "Unknown",
          highestRollerId: user.uid,
        }),
        updateDoc(aggregateRef, {
          [`highestRolls.${lootId}`]: rolledNumber
        })
      ]);
    }

    setSuccess(`You rolled a ${rolledNumber}!`);
    setConfirmationTitle("Roll Successful");
    setConfirmationMessage(`You rolled a ${rolledNumber}!`);

    await logAction({
      action: LogActions.ITEM_ROLLED,
      actor: user.uid,
      details: `Rolled ${rolledNumber} for item ${itemName}`,
      sessionId: sessionId,
      guild: selectedGuild
    });

    setIsError(false);
    setConfirmationModalVisible(true);

    setUserRolls((prev) => ({
      ...prev,
      [lootId]: true,
    }));
  } catch (error: any) {
    setConfirmationTitle("Error");
    setConfirmationMessage(error.message);
    setIsError(true);
    setConfirmationModalVisible(true);
  }
};
