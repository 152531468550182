import React from 'react';
import {
    Box, Button, TextField, Dialog,
    DialogTitle, DialogContent, DialogActions,
    Container, Card, CardContent
} from '@mui/material';
import { addDoc, collection, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormControlLabel, Switch } from '@mui/material';



interface CreateEventProps {
    open: boolean;
    onClose: () => void;
}

const CreateEvent: React.FC<CreateEventProps> = ({ open, onClose }) => {
    const { user, selectedGuild } = useUser();
    const [eventData, setEventData] = React.useState({
        title: '',
        startDate: DateTime.now(),
        endDate: DateTime.now(),
        description: '',
        maxParticipants: 20,
        dkpValue: 0,
        allowLateRegistration: false
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!selectedGuild) return;

        const eventsCollectionRef = collection(db, 'events');
        const guildDocRef = doc(eventsCollectionRef, selectedGuild);
        const guildEventsRef = collection(guildDocRef, 'guildEvents');

        await addDoc(guildEventsRef, {
            ...eventData,
            createdBy: user?.uid,
            guildId: selectedGuild,
            registeredUsers: [],
            createdAt: serverTimestamp(),
            startDate: eventData.startDate.toISO(),
            endDate: eventData.endDate.toISO(),
            isCompleted: false,
            dkpDistributed: false
        });

        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Create New Event</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            label="Event Title"
                            required
                            value={eventData.title}
                            onChange={(e) => setEventData({ ...eventData, title: e.target.value })}
                        />
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                label="Start Date"
                                value={eventData.startDate}
                                onChange={(newValue) => {
                                    setEventData({ ...eventData, startDate: newValue || DateTime.now() });
                                }}
                            />
                            <DateTimePicker
                                label="End Date"
                                value={eventData.endDate}
                                onChange={(newValue) => {
                                    setEventData({ ...eventData, endDate: newValue || DateTime.now() });
                                }}
                            />
                        </LocalizationProvider>

                        <TextField
                            label="Description"
                            multiline
                            rows={4}
                            value={eventData.description}
                            onChange={(e) => setEventData({ ...eventData, description: e.target.value })}
                        />
                        <TextField
                            label="Max Participants"
                            type="number"
                            required
                            value={eventData.maxParticipants}
                            onChange={(e) => setEventData({ ...eventData, maxParticipants: parseInt(e.target.value) })}
                        />
                        <TextField
                            label="DKP Value"
                            type="number"
                            required
                            value={eventData.dkpValue}
                            onChange={(e) => setEventData({ ...eventData, dkpValue: parseInt(e.target.value) })}
                            helperText="DKP to be awarded upon event completion"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={eventData.allowLateRegistration}
                                    onChange={(e) => setEventData({
                                        ...eventData,
                                        allowLateRegistration: e.target.checked
                                    })}
                                />
                            }
                            label="Allow Late Registration with Verification"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="submit" variant="contained">Create Event</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateEvent;
