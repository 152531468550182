import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { Box, Button, Paper, Typography, Link, useTheme, Backdrop } from '@mui/material';
import { alpha } from '@mui/material/styles';

const DISCORD_LINK = 'https://discord.gg/J3bCDN4vys';

const UpdateNotification: React.FC = () => {
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);
  const [newVersion, setNewVersion] = useState<string>('');
  const theme = useTheme();

  useEffect(() => {
    const versionRef = doc(db, 'system', 'version');

    const unsubscribe = onSnapshot(versionRef, (doc) => {
      const version = doc.data()?.version;
      const currentVersion = localStorage.getItem('appVersion');

      if (version) {
        if (!currentVersion) {
          localStorage.setItem('appVersion', version);
        } else if (currentVersion !== version) {
          setNewVersion(version);
          setShowUpdatePrompt(true);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleUpdate = () => {
    console.log('Updating to new version...');
    localStorage.setItem('appVersion', newVersion);
    window.location.reload();
  };

  if (!showUpdatePrompt) return null;

  return (
    <Backdrop
      open={showUpdatePrompt}
      sx={{
        zIndex: theme.zIndex.modal,
        backgroundColor: alpha(theme.palette.background.default, 0.8),
      }}
    >
      <Paper
        elevation={24}
        sx={{
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          p: 4,
          borderRadius: 2,
          maxWidth: 400,
          mx: 2,
          textAlign: 'center',
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
            🎉 New Version Available! 🎉
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Join our{' '}
            <Link
              href={DISCORD_LINK}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: 'inherit',
                textDecoration: 'underline',
                fontWeight: 'bold',
                '&:hover': {
                  color: alpha(theme.palette.primary.contrastText, 0.8)
                }
              }}
            >
              Discord
            </Link>
            {' '}to chat with our friendly dev about your ideas and feature suggestions! We update this tool based on your feedback and we're always looking for ways to improve it.
          </Typography>


        </Box>
        <Button
          variant="contained"
          size="large"
          onClick={handleUpdate}
          sx={{
            bgcolor: theme.palette.common.white,
            color: theme.palette.primary.main,
            px: 4,
            py: 1.5,
            fontSize: '1.1rem',
            fontWeight: 'bold',
            '&:hover': {
              bgcolor: alpha(theme.palette.common.white, 0.9)
            }
          }}
        >
          Update Now
        </Button>
      </Paper>
    </Backdrop>
  );
};

export default UpdateNotification;