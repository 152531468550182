import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useUser from '../hooks/useUser';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  AccountCircle,
  ExpandMore,
  Home,
  DarkMode,
  LightMode,
  Menu as MenuIcon,
  Dashboard,
  Group,
  Article,
  Info,
  Settings,
  Star,
  Chat as DiscordIcon,
} from '@mui/icons-material';
import useConfig from '../hooks/useConfig';

const Navigation: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user, loading, selectedGuild } = useUser();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { mode, toggleThemeMode } = useConfig();

  const isAdmin = user?.role === 'admin';
  const isApproved = user?.status === 'approved';

  const navigationGroups = {
    info: [
      { text: 'News', icon: <Article />, path: '/news' },
      { text: 'About', icon: <Info />, path: '/about' },
      
    ],
    Premium: [
      { text: 'Discord Bot', icon: <DiscordIcon />, path: '/discord-bot' },
    ],
    guild: isApproved && selectedGuild ? [
      { text: 'Dashboard', icon: <Dashboard />, path: '/dashboard' },
      { text: 'Sessions', icon: <Group />, path: '/sessions' },
      { text: 'Events', icon: <Group />, path: '/events' },
      { text: 'Wishlist', icon: <Star />, path: '/wishlist' },
    ] : [],
    admin: isAdmin ? [
      { text: 'DKP Management', icon: <Dashboard />, path: '/dkp-management' },
      { text: 'Admin Logs', icon: <Dashboard />, path: '/admin-logs' },
      { text: 'User Management', icon: <Dashboard />, path: '/user-management' },
    ] : [],

  };

  const isActive = (path: string) => location.pathname === path;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/login');
    handleDrawerToggle();
  };

  const MobileDrawer = (
    <Box sx={{ width: 250 }}>
      {Object.entries(navigationGroups).map(([groupKey, items]) => (
        items.length > 0 && (
          <Accordion key={groupKey} disableGutters elevation={0}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="overline">{groupKey.toUpperCase()}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List disablePadding>
                {items.map((item) => (
                  <ListItem
                    key={item.text}
                    component={Link}
                    to={item.path}
                    onClick={handleDrawerToggle}
                    sx={{
                      pl: 2,
                      backgroundColor: isActive(item.path) ? 'action.selected' : 'transparent',
                      '&:hover': {
                        backgroundColor: 'action.hover'
                      },
                      cursor: 'pointer'
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        )
      ))}
    </Box>
  );

  if (loading) return null;

  return (
    <AppBar position="sticky" color="default" elevation={1}>
      <Toolbar>
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Loot Manager
            </Typography>
            <Drawer
              variant="temporary"
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
            >
              {MobileDrawer}
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <IconButton color="inherit" edge="start" onClick={() => navigate('/dashboard')}>
              <Home />
            </IconButton>
            <Typography variant="h6" sx={{ ml: 1, flexGrow: 0 }}>
              Loot Manager
            </Typography>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', gap: 4 }}>
              {Object.entries(navigationGroups).map(([groupKey, items]) => (
                items.length > 0 && (
                  <Box key={groupKey} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography variant="overline" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                      {groupKey.toUpperCase()}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {items.map((item) => (
                        <Button
                          key={item.text}
                          color="inherit"
                          component={Link}
                          to={item.path}
                          startIcon={item.icon}
                          sx={{
                            backgroundColor: isActive(item.path) ? 'action.selected' : 'transparent',
                            '&:hover': { backgroundColor: 'action.hover' }
                          }}
                        >
                          {item.text}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                )
              ))}
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {user && !isMobile && (
            <Typography variant="body2" color="textSecondary">
              💰 {user.dkp.toFixed(2)} | 🏰 {selectedGuild}
            </Typography>
          )}

          <Button
            color="inherit"
            href="https://discord.gg/J3bCDN4vys"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<DiscordIcon />}
          >
            Support
          </Button>

          <IconButton color="inherit" onClick={toggleThemeMode}>
            {mode === 'dark' ? <LightMode /> : <DarkMode />}
          </IconButton>

          {user ? (
            <IconButton color="inherit" onClick={(e) => setAnchorEl(e.currentTarget)}>
              <AccountCircle />
            </IconButton>
          ) : (
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
          )}
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => { navigate('/select-guild'); setAnchorEl(null); }}>
            Change Guild
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
