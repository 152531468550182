import { collection, doc, getDoc, getDocs, query, where, writeBatch, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { HandleRemoveProps } from "../interfaces/handlers";
import { Bid, UserGuild, LootDrop } from "../interfaces";
import { LogActions, logAction } from "../services/logginServices";

export const handleRemove = async ({
  lootId,
  sessionId,
  selectedGuild,
  setConfirmationTitle,
  setConfirmationMessage,
  setIsError,
  setConfirmationModalVisible,
  setSuccess,
}: HandleRemoveProps): Promise<void> => {
  if (!sessionId || !selectedGuild) return;

  try {
    const batch = writeBatch(db);
    const lootRef = doc(db, "sessions", sessionId, "lootDrops", lootId);

    // Fetch lootData to get itemName and winner info
    const lootDoc = await getDoc(lootRef);
    if (!lootDoc.exists()) {
      setConfirmationTitle("Error");
      setConfirmationMessage("Loot item not found.");
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
    }

    const lootData = lootDoc.data() as LootDrop;

    // Refund DKP to the current winning bidder if using DKP method
    if (lootData.lootMethod === 'dkp' && lootData.currentBidder && lootData.currentBid > 0) {
      const userGuildsRef = collection(db, "userGuilds");
      const userGuildQuery = query(
        userGuildsRef,
        where("uid", "==", lootData.currentBidder),
        where("guildId", "==", selectedGuild)
      );
      const userGuildSnapshot = await getDocs(userGuildQuery);

      if (!userGuildSnapshot.empty) {
        const userGuildDoc = userGuildSnapshot.docs[0];
        const userGuildData = userGuildDoc.data() as UserGuild;
        const userGuildRef = doc(db, "userGuilds", userGuildDoc.id);

        // Refund DKP to current winner
        batch.update(userGuildRef, {
          dkp: userGuildData.dkp + lootData.currentBid,
        });

        // Log refund action for the current winner
        const refundLogRef = doc(collection(db, "dkpLogs"));
        batch.set(refundLogRef, {
          userId: lootData.currentBidder,
          username: lootData.currentBidderName || userGuildData.username,
          changeAmount: lootData.currentBid,
          reason: `Refund for winning bid on removed item ${lootData.itemName} (ID: ${lootId})`,
          timestamp: serverTimestamp(),
          adminId: "system",
          adminName: "System",
          guild: selectedGuild,
        });

        await logAction({
          action: LogActions.BID_REFUNDED,
          actor: "system",
          details: `Refunded ${lootData.currentBid} DKP to ${lootData.currentBidderName || userGuildData.username} (winning bid) due to removal of item ${lootData.itemName}`,
          sessionId: sessionId,
          guild: selectedGuild
        });
      }
    }

    // Handle refund for other unrefunded bids
    const bidsRef = collection(db, "sessions", sessionId, "lootDrops", lootId, "bids");
    const bidsQuery = query(bidsRef, where("refunded", "==", false));
    const bidsSnapshot = await getDocs(bidsQuery);

    for (const bidDoc of bidsSnapshot.docs) {
      const bid = bidDoc.data() as Bid;

      // Avoid duplicating the refund for the current winner
      if (bid.bidderId === lootData.currentBidder) continue;

      const userGuildsRef = collection(db, "userGuilds");
      const userGuildQuery = query(
        userGuildsRef,
        where("uid", "==", bid.bidderId),
        where("guildId", "==", selectedGuild)
      );
      const userGuildSnapshot = await getDocs(userGuildQuery);

      if (!userGuildSnapshot.empty) {
        const userGuildDoc = userGuildSnapshot.docs[0];
        const userGuildData = userGuildDoc.data() as UserGuild;
        const userGuildRef = doc(db, "userGuilds", userGuildDoc.id);

        // Mark bid as refunded
        batch.update(bidDoc.ref, { refunded: true });

        // Refund DKP to the bidder
        batch.update(userGuildRef, {
          dkp: userGuildData.dkp + bid.bidAmount,
        });

        // Log refund action for each bidder
        const refundLogRef = doc(collection(db, "dkpLogs"));
        batch.set(refundLogRef, {
          userId: bid.bidderId,
          username: userGuildData.username,
          changeAmount: bid.bidAmount,
          reason: `Refund for bid on deleted item ${lootData.itemName} (ID: ${lootId})`,
          timestamp: serverTimestamp(),
          adminId: "system",
          adminName: "System",
          guild: selectedGuild,
        });

        await logAction({
          action: LogActions.BID_REFUNDED,
          actor: "system",
          details: `Refunded ${bid.bidAmount} DKP to ${userGuildData.username} for removed item ${lootData.itemName}`,
          sessionId: sessionId,
          guild: selectedGuild
        });
      }
    }

    // Delete the loot item
    batch.delete(lootRef);
    await batch.commit();

    setSuccess("Loot item removed and all DKP refunded successfully!");
    setConfirmationTitle("Success");
    setConfirmationMessage("Loot item removed and all DKP refunded successfully!");
    setIsError(false);
    setConfirmationModalVisible(true);
  } catch (error: any) {
    setConfirmationTitle("Error");
    setConfirmationMessage(error.message);
    setIsError(true);
    setConfirmationModalVisible(true);
  }
};
