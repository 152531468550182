import React, { useEffect, useState } from 'react';
import { db, auth } from '../../firebase';
import {
  collection,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  getDocs,
  Timestamp,
  serverTimestamp,
  addDoc,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import { Session, LootDrop, UserGuild, Bid } from '../../interfaces';
import itemsData from '../../items.json';
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  useTheme,
  Card,
  CardContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { CircularProgress } from '@mui/material';
import { EventData } from '../events/Event';
import EventSessionLink from './EventSessionLink';
import SessionTimer from './SessionTimer';
import { logAction, LogActions } from '../../services/logginServices';

interface DurationConfig {
  value: number;
  unit: 'minutes' | 'hours' | 'days' | 'seconds' | 'n/a';
}

const SessionList: React.FC = () => {
  const theme = useTheme();
  const { user, loading, selectedGuild } = useUser();
  const [sessions, setSessions] = useState<Session[]>([]);
  const [sessionName, setSessionName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [isLoadingSessions, setIsLoadingSessions] = useState(true);
  const [sessionDuration, setSessionDuration] = useState<DurationConfig>({
    value: 24,
    unit: 'hours',
  });

  const itemIconMap = itemsData.reduce((map, item) => {
    map[item.name] = `/images/new/${item.imageFilename}`;
    return map;
  }, {} as Record<string, string>);

  const [events, setEvents] = useState<EventData[]>([]);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const [showEventLink, setShowEventLink] = useState(false);

  useEffect(() => {
    if (!user || !selectedGuild) {
      setSessions([]);
      setIsLoadingSessions(false);
      return;
    }

    setIsLoadingSessions(true);

    const sessionsCollection = collection(db, 'sessions');
    const q = query(
      sessionsCollection,
      where('guild', '==', selectedGuild),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, async (sessionSnapshot) => {
      try {
        const sessionsList = await Promise.all(
          sessionSnapshot.docs.map(async (sessionDoc) => {
            const sessionData = sessionDoc.data();

            // Fetch the user profile of the session creator
            const creatorRef = doc(db, 'users', sessionData.createdBy);
            const creatorSnap = await getDoc(creatorRef);
            const creatorData = creatorSnap.exists()
              ? creatorSnap.data()
              : { inGameName: 'Unknown User' };

            const participantsCollection = collection(
              db,
              'sessions',
              sessionDoc.id,
              'participants'
            );
            const participantsSnapshot = await getDocs(participantsCollection);
            const totalParticipants = participantsSnapshot.size;

            const lootDropsCollection = collection(
              db,
              'sessions',
              sessionDoc.id,
              'lootDrops'
            );
            const lootDropsSnapshot = await getDocs(lootDropsCollection);
            const lootDrops = lootDropsSnapshot.docs.map((itemDoc) =>
              itemDoc.data() as LootDrop
            );

            const remainingItems = lootDrops.filter(
              (item) => item.status !== 'distributed'
            ).length;

            // Fetch linked event details if eventId exists
            let linkedEventTitle = null;
            if (sessionData.eventId) {
              const eventDocRef = doc(
                db,
                'events',
                selectedGuild,
                'guildEvents',
                sessionData.eventId
              );
              const eventDoc = await getDoc(eventDocRef);
              if (eventDoc.exists()) {
                const eventData = eventDoc.data() as EventData;
                linkedEventTitle = eventData.title;
              }
            }

            return {
              id: sessionDoc.id,
              name: sessionData.name || 'Unnamed Session',
              createdBy: creatorData.inGameName || 'Unknown User',
              guild: sessionData.guild,
              createdAt: sessionData.createdAt,
              lootmaster: sessionData.lootmaster,
              expiresAt: sessionData.expiresAt || null,
              totalParticipants,
              totalItems: lootDrops.length,
              remainingItems,
              itemNames: lootDrops
                .map((item) => item.itemName || 'Unnamed Item')
                .join(', '),
              items: lootDrops,
              eventId: sessionData.eventId || null,
              linkedEventTitle,
            } as Session;
          })
        );

        setSessions(
          sessionsList.filter((session) => session !== null) as Session[]
        );
        setIsLoadingSessions(false);
      } catch (error) {
        console.error('Error processing sessions:', error);
        setSessions([]);
        setIsLoadingSessions(false);
      }
    });

    return () => unsubscribe();
  }, [user, selectedGuild]);

  useEffect(() => {
    const fetchEvents = async () => {
      if (!selectedGuild) return;
      const eventsCollectionRef = collection(
        db,
        'events',
        selectedGuild,
        'guildEvents'
      );
      const q = query(eventsCollectionRef);
      const querySnapshot = await getDocs(q);
      const eventsList = querySnapshot.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as EventData)
      );
      setEvents(eventsList);
    };
    fetchEvents();
  }, [selectedGuild]);

  const handleCreateSession = async () => {
    setError(null);

    if (!canCreateSession) {
      setError('Only officers and admins can create sessions');
      return;
    }

    if (sessionName.trim() === '') {
      setError('Session name cannot be empty');
      return;
    }

    try {
      const newSessionRef = doc(collection(db, 'sessions'));
      const baseSessionData = {
        name: sessionName,
        createdBy: user?.uid || 'Unknown User',
        createdAt: serverTimestamp(),
        isActive: true,
        lootmaster: auth.currentUser?.uid || null,
        guild: selectedGuild || 'Unknown',
        eventId: selectedEventId || null,
      };

      if (sessionDuration.unit === 'n/a') {
        // Create session without expiration
        await setDoc(newSessionRef, baseSessionData);
      } else {
        // Create session with expiration
        const durationInSeconds = {
          seconds: sessionDuration.value,
          minutes: sessionDuration.value * 60,
          hours: sessionDuration.value * 60 * 60,
          days: sessionDuration.value * 24 * 60 * 60,
        }[sessionDuration.unit];

        await setDoc(newSessionRef, {
          ...baseSessionData,
          durationInSeconds,
        });

        await Promise.all([
          setDoc(doc(db, 'sessions', newSessionRef.id, 'aggregates', 'rolls'), {
            lootIds: {},
          }),
          setDoc(doc(db, 'sessions', newSessionRef.id, 'aggregates', 'bids'), {
            lootIds: {},
          }),
          setDoc(
            doc(db, 'sessions', newSessionRef.id, 'aggregates', 'lootData'),
            {
              currentBids: {},
              bidCounts: {},
              highestRolls: {},
              rollCounts: {},
            }
          ),
        ]);

        const sessionDoc = await getDoc(newSessionRef);
        const creationTime = sessionDoc.data()?.createdAt;

        if (creationTime) {
          const expiresAt = Timestamp.fromMillis(
            creationTime.toMillis() + durationInSeconds * 1000
          );
          await updateDoc(newSessionRef, { expiresAt });
        }
      }

      setSessionName('');
      setSelectedEventId(null);
      setError(null);
      navigate(`/session/${newSessionRef.id}`);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleDeleteSession = async (sessionId: string) => {
    if (!auth.currentUser) {
      alert('You must be logged in to delete a session.');
      return;
    }

    if (!selectedGuild) {
      alert('Selected guild is not available.');
      return;
    }

    try {
      const sessionRef = doc(db, 'sessions', sessionId);
      const sessionDoc = await getDoc(sessionRef);

      if (!sessionDoc.exists()) {
        alert('Session does not exist.');
        return;
      }

      const sessionData = sessionDoc.data() as Session;
      const isAdmin = user?.role === 'admin';

      if (!isAdmin && sessionData.createdBy !== user?.uid) {
        alert('You are not authorized to delete this session.');
        return;
      }

      // Get all loot drops for the session
      const lootDropsRef = collection(db, 'sessions', sessionId, 'lootDrops');
      const lootDropsSnapshot = await getDocs(lootDropsRef);

      // Begin a batch operation
      const batch = writeBatch(db);

      // Process refunds for each item
      for (const lootDoc of lootDropsSnapshot.docs) {
        const lootData = lootDoc.data() as LootDrop;
        const lootId = lootDoc.id;

        // **Only process refunds for items that are not yet distributed**
        if (lootData.status !== 'distributed') {
          // Refund DKP to the current winning bidder if using DKP method
          if (
            lootData.lootMethod === 'dkp' &&
            lootData.currentBidder &&
            lootData.currentBid > 0
          ) {
            const userGuildRef = doc(
              db,
              'userGuilds',
              `${lootData.currentBidder}_${selectedGuild}`
            );
            const userGuildDoc = await getDoc(userGuildRef);

            if (userGuildDoc.exists()) {
              const userGuildData = userGuildDoc.data() as UserGuild;

              // Refund DKP to current winner
              batch.update(userGuildRef, {
                dkp: (userGuildData.dkp || 0) + lootData.currentBid,
              });

              // Log refund action for the current winner
              const refundLogRef = doc(collection(db, 'dkpLogs'));
              batch.set(refundLogRef, {
                userId: lootData.currentBidder,
                username:
                  lootData.currentBidderName || userGuildData.username || 'Unknown',
                changeAmount: lootData.currentBid,
                reason: `Refund for winning bid on removed item ${lootData.itemName} (ID: ${lootId})`,
                timestamp: serverTimestamp(),
                adminId: 'system',
                adminName: 'System',
                guild: selectedGuild as string,
              });

              await logAction({
                action: LogActions.BID_REFUNDED,
                actor: 'system',
                details: `Refunded ${lootData.currentBid} DKP to ${
                  lootData.currentBidderName || userGuildData.username
                } (winning bid) due to removal of item ${lootData.itemName}`,
                sessionId: sessionId,
                guild: selectedGuild as string,
              });
            }
          }

          // Handle refund for other unrefunded bids
          const bidsRef = collection(
            db,
            'sessions',
            sessionId,
            'lootDrops',
            lootId,
            'bids'
          );
          const bidsQuery = query(bidsRef, where('refunded', '==', false));
          const bidsSnapshot = await getDocs(bidsQuery);

          for (const bidDoc of bidsSnapshot.docs) {
            const bid = bidDoc.data() as Bid;

            // Avoid duplicating the refund for the current winner
            if (bid.bidderId === lootData.currentBidder) continue;

            const userGuildRef = doc(
              db,
              'userGuilds',
              `${bid.bidderId}_${selectedGuild}`
            );
            const userGuildDoc = await getDoc(userGuildRef);

            if (userGuildDoc.exists()) {
              const userGuildData = userGuildDoc.data() as UserGuild;

              // Mark bid as refunded
              batch.update(bidDoc.ref, { refunded: true });

              // Refund DKP to the bidder
              batch.update(userGuildRef, {
                dkp: (userGuildData.dkp || 0) + bid.bidAmount,
              });

              // Log refund action for each bidder
              const refundLogRef = doc(collection(db, 'dkpLogs'));
              batch.set(refundLogRef, {
                userId: bid.bidderId,
                username: userGuildData.username || 'Unknown',
                changeAmount: bid.bidAmount,
                reason: `Refund for bid on deleted item ${lootData.itemName} (ID: ${lootId})`,
                timestamp: serverTimestamp(),
                adminId: 'system',
                adminName: 'System',
                guild: selectedGuild as string,
              });

              await logAction({
                action: LogActions.BID_REFUNDED,
                actor: 'system',
                details: `Refunded ${bid.bidAmount} DKP to ${userGuildData.username} for removed item ${lootData.itemName}`,
                sessionId: sessionId,
                guild: selectedGuild as string,
              });
            }
          }
        }

        // Delete the loot item
        batch.delete(lootDoc.ref);
      }

      // Delete the session after processing refunds
      batch.delete(sessionRef);

      await batch.commit();

      setSessions(sessions.filter((session) => session.id !== sessionId));
      alert('Session deleted successfully and all DKP refunded to bidders.');
    } catch (error: any) {
      alert(`Error deleting session: ${error.message}`);
    }
  };

  if (loading || !user || !selectedGuild) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const isAdmin = user?.role === 'admin';
  const canCreateSession = user?.role === 'admin' || user?.role === 'officer';

  return (
    <Box
      sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.default }}
    >
      {/* Hero Header */}
      <Box sx={{ position: 'relative', height: '200px', overflow: 'hidden' }}>
        {/* Background Image */}
        <Box
          sx={{
            backgroundImage: 'url(/images/newsite/bkg.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            filter: 'blur(1px)',
            zIndex: 1,
          }}
        />
        {/* Dark Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 2,
          }}
        />
        {/* Content */}
        <Container
          maxWidth="lg"
          sx={{ height: '100%', position: 'relative', zIndex: 3 }}
        >
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 3 }}
          >
            <Typography variant="h4" sx={{ color: 'white', fontWeight: 700 }}>
              Activity Sessions
            </Typography>
            <Button
              variant="outlined"
              onClick={() => navigate('/dashboard')}
              sx={{ color: 'white', borderColor: 'white' }}
            >
              Back to Dashboard
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Main Content */}
      <Container
        maxWidth="lg"
        sx={{ mt: -2, pt: 4, mb: 4, position: 'relative' }}
      >
        <Grid container spacing={3}>
          {/* Create Session Form */}
          {canCreateSession && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 3 }}>
                    Create New Session
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Session Name"
                        value={sessionName}
                        onChange={(e) => setSessionName(e.target.value)}
                        error={!!error}
                        helperText={error}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel>Duration Type</InputLabel>
                        <Select
                          value={sessionDuration.unit}
                          label="Duration Type"
                          onChange={(e) =>
                            setSessionDuration({
                              ...sessionDuration,
                              unit: e.target.value as DurationConfig['unit'],
                            })
                          }
                        >
                          <MenuItem value="n/a">No Expiration</MenuItem>
                          <MenuItem value="seconds">Seconds</MenuItem>
                          <MenuItem value="minutes">Minutes</MenuItem>
                          <MenuItem value="hours">Hours</MenuItem>
                          <MenuItem value="days">Days</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Duration Value"
                        disabled={sessionDuration.unit === 'n/a'}
                        value={
                          sessionDuration.unit === 'n/a'
                            ? ''
                            : sessionDuration.value
                        }
                        onChange={(e) =>
                          setSessionDuration({
                            ...sessionDuration,
                            value: Math.max(
                              1,
                              parseInt(e.target.value) || 1
                            ),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        onClick={() => setShowEventLink(true)}
                        fullWidth
                        startIcon={<PersonAddIcon />}
                      >
                        {selectedEventId ? 'Change Event' : 'Link to Event'}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={handleCreateSession}
                        fullWidth
                        sx={{ mt: 2 }}
                      >
                        Create Session
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          {/* Sessions List */}
          <Grid item xs={12}>
            {isLoadingSessions ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '200px',
                }}
              >
                <CircularProgress />
              </Box>
            ) : sessions.length === 0 ? (
              <Paper sx={{ p: 3, textAlign: 'center' }}>
                <Typography color="textSecondary">
                  No sessions available for your guild.
                </Typography>
              </Paper>
            ) : (
              <Grid container spacing={2}>
                {sessions.map((session) => {
                  const expiresAt =
                    session.expiresAt instanceof Timestamp
                      ? session.expiresAt.toDate()
                      : session.expiresAt;
                  const isExpired = expiresAt ? new Date() > expiresAt : false;

                  return (
                    <Grid item xs={12} key={session.id}>
                      <Card sx={{ position: 'relative' }}>
                        <CardContent>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                          >
                            <Grid item xs={12} md={8}>
                              <Typography variant="h6">
                                {session.name}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Time Remaining:
                                </Typography>
                                <SessionTimer
                                  expiresAt={
                                    session.expiresAt instanceof Timestamp
                                      ? session.expiresAt
                                      : null
                                  }
                                />
                              </Box>
                              {session.linkedEventTitle && (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{ mb: 1 }}
                                >
                                  Linked Event: {session.linkedEventTitle}
                                </Typography>
                              )}
                              <Box
                                sx={{
                                  mt: 2,
                                  display: 'flex',
                                  gap: 1,
                                  flexWrap: 'wrap',
                                }}
                              >
                                {session.items.map((item, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      opacity:
                                        item.status === 'distributed'
                                          ? 0.5
                                          : 1,
                                      transition: 'opacity 0.2s',
                                    }}
                                  >
                                    <img
                                      src={
                                        itemIconMap[item.itemName] ||
                                        '/images/legendarysword.png'
                                      }
                                      alt={item.itemName}
                                      style={{ width: 40, height: 40 }}
                                    />
                                  </Box>
                                ))}
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 1,
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Button
                                  variant="contained"
                                  startIcon={<VisibilityIcon />}
                                  onClick={() =>
                                    navigate(`/session/${session.id}`)
                                  }
                                >
                                  View
                                </Button>
                                {(isAdmin ||
                                  session.createdBy === user?.uid) && (
                                  <IconButton
                                    color="error"
                                    onClick={() =>
                                      handleDeleteSession(session.id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      <EventSessionLink
        open={showEventLink}
        onClose={() => setShowEventLink(false)}
        onSelectEvent={(eventId: string) => {
          setSelectedEventId(eventId);
          setShowEventLink(false);
        }}
        events={events}
      />
    </Box>
  );
};

export default SessionList;
