import React, { useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import itemsDataNew from "../../items.json";
import { WishlistPanel } from './WishlistPanel';
import { Button, Grid, Paper } from '@mui/material';
import { fetchTopWishlistedItems } from './WishListServices';
import useUser from '../../hooks/useUser';


interface ItemType {
  name: string;
  imageFilename: string;
  url?: string;
}

const adaptedItemsData: ItemType[] = (
  Array.isArray(itemsDataNew) ? itemsDataNew : (itemsDataNew as { items?: any[] }).items ?? []
).map((item: any) => ({
  name: item.name,
  imageFilename: item.imageFilename.replace(/'/g, "_").replace(/\s+/g, "_"),
  url: item.url,
}));

const Wishlist = () => {
  const [selectedGameMode, setSelectedGameMode] = useState<"Throne and Liberty" | "Custom">("Throne and Liberty");
  const [searchTerm, setSearchTerm] = useState("");
  const [topItems, setTopItems] = useState<Array<{itemName: string, count: number}>>([]);
  const [showTopItems, setShowTopItems] = useState(false);
  const { selectedGuild } = useUser();

  const handleFetchTopItems = async () => {
    if (!selectedGuild) return;
    const items = await fetchTopWishlistedItems(selectedGuild);
    setTopItems(items);
    setShowTopItems(true);
  };

  const filteredAvailableItems = adaptedItemsData.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Container maxWidth="lg" sx={{ pt: 4, pb: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h4">Wishlist</Typography>
          <Button 
            variant="contained" 
            onClick={handleFetchTopItems}
            sx={{ ml: 2 }}
          >
            Show Top 10 Popular Items
          </Button>
        </Box>

        {showTopItems && (
          <Box sx={{ mb: 4, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Top 10 Most Wanted Items</Typography>
            <Grid container spacing={2}>
              {topItems.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={item.itemName}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1">{`${index + 1}. ${item.itemName}`}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Wishlisted by {item.count} members
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        <WishlistPanel
          selectedGameMode={selectedGameMode}
          searchTerm={searchTerm}
          filteredItems={filteredAvailableItems}
          onGameModeChange={setSelectedGameMode}
          onSearchChange={setSearchTerm}
        />
      </Container>
    </Box>
  );
};


export default Wishlist;
