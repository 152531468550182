import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';

interface ParticipantData {
    uid: string;
    inGameName: string;
    dkp: number;
    primaryWeapon: string;
    secondaryWeapon: string;
    gearScore: number;
}

interface DKPModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    dkpValue: number;
    participants: ParticipantData[];
}

export const DistributeModal: React.FC<DKPModalProps> = ({ open, onClose, onConfirm, dkpValue, participants }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirm DKP Distribution</DialogTitle>
        <DialogContent>
            <Typography>
                You are about to distribute {dkpValue} DKP to each participant.
            </Typography>
            <TableContainer sx={{ mt: 2 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Player</TableCell>
                            <TableCell align="right">DKP to Receive</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {participants.map((participant) => (
                            <TableRow key={participant.uid}>
                                <TableCell>{participant.inGameName}</TableCell>
                                <TableCell align="right">{dkpValue}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onConfirm} color="primary">
                Confirm Distribution
            </Button>
        </DialogActions>
    </Dialog>
);

export const SplitModal: React.FC<DKPModalProps> = ({ open, onClose, onConfirm, dkpValue, participants }) => {
    const splitAmount = Math.floor(dkpValue / participants.length);
    
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirm DKP Split</DialogTitle>
            <DialogContent>
                <Typography>
                    You are about to split {dkpValue} DKP between {participants.length} participants.
                    Each participant will receive {splitAmount} DKP.
                </Typography>
                <TableContainer sx={{ mt: 2 }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Player</TableCell>
                                <TableCell align="right">DKP to Receive</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {participants.map((participant) => (
                                <TableRow key={participant.uid}>
                                    <TableCell>{participant.inGameName}</TableCell>
                                    <TableCell align="right">{splitAmount}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onConfirm} color="primary">
                    Confirm Split
                </Button>
            </DialogActions>
        </Dialog>
    );
};
