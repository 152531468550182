// src/components/DKPManagement.tsx

import React, { useEffect, useState, useRef } from 'react';
import { db } from '../firebase';
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  getDocs,
  query,
  where,
  writeBatch,
  Timestamp,
} from 'firebase/firestore';
import { CSVLink } from 'react-csv';
import useUser from '../hooks/useUser';
import { UserGuild, DKPPreset, DKPLog } from '../interfaces';
import DKPDecay from './DKPDecay';
import DKPCell from './DKPCell';
import { logAction, LogActions } from '../services/logginServices';
import useSessionGuild from "../hooks/useSessionGuild"
import { Box, Button, CircularProgress, Container, Grid, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, FormControl, SelectChangeEvent } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Utility function for debouncing
const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const DKPManagement: React.FC = () => {
  const { user, loading, selectedGuild } = useUser();
  const [users, setUsers] = useState<UserGuild[]>([]);
  const [presets, setPresets] = useState<DKPPreset[]>([]);
  const [dkpLogs, setDkpLogs] = useState<DKPLog[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserGuild[]>([]);
  const [selectedPreset, setSelectedPreset] = useState<string>('');
  const [dkpChange, setDkpChange] = useState<number>(0);
  const [reason, setReason] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [newPresetName, setNewPresetName] = useState<string>('');
  const [newPresetAmount, setNewPresetAmount] = useState<number>(0);
  const [newPresetReason, setNewPresetReason] = useState<string>('');
  const [editPresetId, setEditPresetId] = useState<string | null>(null);
  const [error, setError] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState<string>(''); // New state for sorting
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const [isPresetLoading, setIsPresetLoading] = useState<boolean>(true);
  const [splitAmount, setSplitAmount] = useState<number>(0);
  const [isSplitHovered, setIsSplitHovered] = useState<boolean>(false);
  const [selectedUsersHovered, setSelectedUsersHovered] = useState<boolean>(false);
  const [isSearchedUsersHovered, setIsSearchedUsersHovered] = useState<boolean>(false);
  const [isSelectedUsersHovered, setIsSelectedUsersHovered] = useState<boolean>(false);
  const [isDeducting, setIsDeducting] = useState<boolean>(false);

  const theme = useTheme();
  // Ref to store the debounced filter function
  const debouncedFilter = useRef(
    debounce((query: string, usersList: UserGuild[], sort: string) => {
      let filtered = usersList;
      if (query) {
        const names = query.split(',').map(name => name.trim().toLowerCase()).filter(name => name);
        filtered = usersList.filter(userItem =>
          names.some(name =>
            userItem.username.toLowerCase().includes(name) ||
            (userItem.inGameName?.toLowerCase().includes(name))
          )
        );
      }
      if (sort === 'dkp_desc') {
        filtered = filtered.sort((a, b) => b.dkp - a.dkp);
      } else if (sort === 'dkp_asc') {
        filtered = filtered.sort((a, b) => a.dkp - b.dkp);
      }
      setFilteredUsers(filtered);
    }, 300)
  ).current;

  // Fetch Users (Once)
  useEffect(() => {
    if (!user || !selectedGuild) return;

    const fetchUsers = async () => {
      try {
        const userGuildsRef = collection(db, 'userGuilds');
        const q = query(
          userGuildsRef,
          where('guildId', '==', selectedGuild),
          where('status', '==', 'approved')
        );
        const querySnapshot = await getDocs(q);
        const usersList: UserGuild[] = querySnapshot.docs.map((doc) => ({
          ...(doc.data() as UserGuild),
          id: doc.id,
        }));
        setUsers(usersList);
        setFilteredUsers(usersList);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('Failed to fetch users.');
      }
    };

    fetchUsers();
  }, [user, selectedGuild]);

  // Fetch Presets (Once)
  useEffect(() => {
    if (!user || !selectedGuild) return;

    const fetchPresets = async () => {
      try {
        const presetsRef = collection(db, 'dkpPresets');
        const q = query(presetsRef, where('guild', '==', selectedGuild));
        const querySnapshot = await getDocs(q);
        const presetsList = querySnapshot.docs.map((doc) => ({
          ...(doc.data() as DKPPreset),
          id: doc.id,
        }));

        // If no presets exist, initialize defaults
        if (presetsList.length === 0) {
          await initializeDefaultPresets();
        } else {
          setPresets(presetsList);
          setIsPresetLoading(false);
        }
      } catch (err) {
        console.error('Error fetching presets:', err);
        setError('Failed to fetch presets.');
        setIsPresetLoading(false);
      }
    };

    fetchPresets();
  }, [user, selectedGuild]);

  const initializeDefaultPresets = async () => {
    if (!selectedGuild) return;

    const defaultPresets = [
      {
        name: "PvP Victory",
        amount: 10,
        reason: "Participation in successful PvP battle",
        guild: selectedGuild
      },
      {
        name: "PvE Dungeon",
        amount: 15,
        reason: "Completion of PvE dungeon run",
        guild: selectedGuild
      },
      {
        name: "Raid Attendance",
        amount: 25,
        reason: "Full raid participation",
        guild: selectedGuild
      },
      {
        name: "Boss Kill",
        amount: 50,
        reason: "Successful raid boss kill",
        guild: selectedGuild
      }
    ];

    try {
      const batch = writeBatch(db);
      const presetsRef = collection(db, 'dkpPresets');

      for (const preset of defaultPresets) {
        const newPresetRef = doc(presetsRef);
        batch.set(newPresetRef, preset);
      }

      await batch.commit();

      // Update local state
      const newPresets = defaultPresets.map((preset, index) => ({
        ...preset,
        id: `default-${index}` // Temporary IDs will be replaced on next fetch
      }));
      setPresets(newPresets);
      setIsPresetLoading(false);
    } catch (err) {
      console.error('Error initializing default presets:', err);
      setError('Failed to initialize default presets.');
    }
  };


  // Fetch DKP Logs (Once)
  useEffect(() => {
    if (!user || !selectedGuild) return;

    const fetchDkpLogs = async () => {
      try {
        const logsRef = collection(db, 'dkpLogs');
        const q = query(logsRef, where('guild', '==', selectedGuild));
        const querySnapshot = await getDocs(q);

        // First, get all unique admin IDs from the logs
        const adminIds = new Set(querySnapshot.docs.map(doc => doc.data().adminId));

        // Fetch admin usernames from userGuilds collection
        const adminUsernames = new Map();
        for (const adminId of adminIds) {
          const userGuildsRef = collection(db, 'userGuilds');
          const userQuery = query(
            userGuildsRef,
            where('uid', '==', adminId),  // Changed from 'userId' to 'uid'
            where('guildId', '==', selectedGuild)
          );
          const userSnapshot = await getDocs(userQuery);
          if (!userSnapshot.empty) {
            const userData = userSnapshot.docs[0].data();
            adminUsernames.set(adminId, userData.username);
          }
        }

        const logsList = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            timestamp: data.timestamp instanceof Timestamp ?
              data.timestamp.toDate() :
              new Date(data.timestamp),
            adminName: adminUsernames.get(data.adminId) || 'System'
          };
        }) as DKPLog[];

        const lastFiftyLogs = logsList
          .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
          .slice(0, 50);

        setDkpLogs(lastFiftyLogs);
      } catch (err) {
        console.error('Error fetching DKP logs:', err);
        setError('Failed to fetch DKP logs.');
      }
    };
    fetchDkpLogs();
  }, [user, selectedGuild]);

  // Handle Search Input Change
  const handleSearchQueryChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedFilter(query, users, sortOrder);
  };

  // Handle Sort Order Change
  const handleSortOrderChange = (e: SelectChangeEvent<string>) => {
    const sort = e.target.value;
    setSortOrder(sort);

    let sorted = [...filteredUsers];
    if (sort === 'dkp_desc') {
      sorted = sorted.sort((a, b) => b.dkp - a.dkp);
    } else if (sort === 'dkp_asc') {
      sorted = sorted.sort((a, b) => a.dkp - b.dkp);
    }
    setFilteredUsers(sorted);
  };


  if (loading) return <div>Loading...</div>;

  // Determine user's role within the primary guild
  const primaryMembership = user?.memberships.find(
    (m) => m.guildId === selectedGuild
  );

  if (!user || primaryMembership?.role !== 'admin') {
    return <div>You do not have access to this page.</div>;
  }

  const handlePresetSelect = (presetId: string) => {
    setSelectedPreset(presetId);
    const preset = presets.find((p) => p.id === presetId);
    if (preset) {
      setDkpChange(preset.amount);
      setReason(preset.reason);
    }
  };

    // Add this function to handle mode toggle
    const handleModeToggle = () => {
      setIsDeducting(!isDeducting);
      // Update current DKP amount when switching modes
      setDkpChange(current => -current);
    };

  const handleUserSelect = (userGuildId: string) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userGuildId)
        ? prevSelected.filter((id) => id !== userGuildId)
        : [...prevSelected, userGuildId]
    );
  };

  const applyDkpChange = async () => {
    if (selectedUsers.length === 0) {
      alert('Please select at least one user.');
      return;
    }
    if (dkpChange === 0) {
      alert('Please enter DKP amount.');
      return;
    }
    if (reason.trim() === '') {
      //console.log('Reason input is empty. Cannot proceed without a valid reason.');
      alert('Please enter a reason for the DKP change.');
      return;
    }

    setIsApplying(true);
    try {
      const batch = writeBatch(db);

      for (const userGuildId of selectedUsers) {
        const userGuildRef = doc(db, 'userGuilds', userGuildId);
        const userGuildDoc = await getDoc(userGuildRef);
        if (!userGuildDoc.exists()) {
          alert(`UserGuild with ID ${userGuildId} does not exist.`);
          continue;
        }
        const userGuildData = userGuildDoc.data() as UserGuild;

        const newDkp = (userGuildData.dkp || 0) + dkpChange;
        batch.update(userGuildRef, {
          dkp: newDkp,
        });

        const logRef = doc(collection(db, 'dkpLogs'));
        batch.set(logRef, {
          userId: userGuildDoc.id,
          username: userGuildData.username,
          inGameName: userGuildData.inGameName || 'N/A',
          changeAmount: dkpChange,
          reason,
          timestamp: Timestamp.fromDate(new Date()),
          adminId: user.uid,
          adminName: user.username,
          guild: selectedGuild,
        });


        setUsers((prevUsers) =>
          prevUsers.map((u) =>
            u.id === userGuildId ? { ...u, dkp: newDkp } : u
          )
        );
        setFilteredUsers((prevUsers) =>
          prevUsers.map((u) =>
            u.id === userGuildId ? { ...u, dkp: newDkp } : u
          )
        );
      }

      await batch.commit();

      if (!selectedGuild) return;
      await logAction({
        action: LogActions.DKP_AWARDED,
        actor: user.uid,
        details: `Added ${dkpChange} DKP to ${selectedUsers.length} users. Reason: ${reason}`,
        sessionId: 'dkp-management',
        guild: selectedGuild
      });

      alert('DKP change applied successfully!');
      setSelectedUsers([]);
      setDkpChange(0);
      setReason('');
      setSelectedPreset('');
    } catch (err) {
      console.error(err);
      alert('Failed to apply DKP changes.');
    } finally {
      setIsApplying(false);
    }
  };

  const applyDkpToSearchedUsers = async () => {
    if (filteredUsers.length === 0) {
      alert('No users found in search results.');
      return;
    }
    if (dkpChange === 0) {
      alert('Please enter DKP amount.');
      return;
    }
    if (reason.trim() === '') {
      alert('Please enter a reason for the DKP change.');
      return;
    }

    setIsApplying(true);
    try {
      const batch = writeBatch(db);

      for (const userGuild of filteredUsers) {
        const userGuildRef = doc(db, 'userGuilds', userGuild.id);
        const newDkp = (userGuild.dkp || 0) + dkpChange;

        batch.update(userGuildRef, {
          dkp: newDkp,
        });

        const logRef = doc(collection(db, 'dkpLogs'));
        batch.set(logRef, {
          userId: userGuild.id,
          username: userGuild.username,
          inGameName: userGuild.inGameName || 'N/A',
          changeAmount: dkpChange,
          reason,
          timestamp: Timestamp.fromDate(new Date()),
          adminId: user.uid,
          adminName: user.username,
          guild: selectedGuild,
        });
      }

      await batch.commit();
      if (!selectedGuild) return;

      await logAction({
        action: LogActions.DKP_AWARDED,
        actor: user.uid,
        details: `Added ${dkpChange} DKP to ${filteredUsers.length} searched users. Reason: ${reason}`,
        sessionId: 'dkp-management',
        guild: selectedGuild
      });

      setUsers(users.map(u => {
        if (filteredUsers.some(fu => fu.id === u.id)) {
          return { ...u, dkp: u.dkp + dkpChange };
        }
        return u;
      }));

      setFilteredUsers(filteredUsers.map(u => ({
        ...u,
        dkp: u.dkp + dkpChange
      })));

      alert('DKP change applied to all searched users successfully!');
      setDkpChange(0);
      setReason('');
      setSelectedPreset('');
    } catch (err) {
      console.error(err);
      alert('Failed to apply DKP changes.');
    } finally {
      setIsApplying(false);
    }
  };

  const splitDkpAmount = async (totalAmount: number) => {
    if (filteredUsers.length === 0) {
      alert('No users found to split DKP between.');
      return;
    }

    if (totalAmount === 0) {
      alert('Please enter a DKP amount to split.');
      return;
    }

    if (!reason.trim()) {
      alert('Please enter a reason for the DKP split.');
      return;
    }

    const splitAmount = totalAmount / filteredUsers.length;
    setIsApplying(true);

    try {
      const batch = writeBatch(db);

      for (const userGuild of filteredUsers) {
        const userGuildRef = doc(db, 'userGuilds', userGuild.id);
        const newDkp = (userGuild.dkp || 0) + splitAmount;

        batch.update(userGuildRef, {
          dkp: newDkp,
        });

        const logRef = doc(collection(db, 'dkpLogs'));
        batch.set(logRef, {
          userId: userGuild.id,
          username: userGuild.username,
          inGameName: userGuild.inGameName || 'N/A',
          changeAmount: splitAmount,
          reason: `${reason} (Split ${totalAmount} DKP between ${filteredUsers.length} users)`,
          timestamp: Timestamp.fromDate(new Date()),
          adminId: user.uid,
          adminName: user.username,
          guild: selectedGuild,
        });
      }

      await batch.commit();
      if (!selectedGuild) return;

      // Add individual log entries for each user here
      for (const userGuild of filteredUsers) {
        await logAction({
          action: LogActions.DKP_AWARDED,
          actor: user.uid,
          details: `User ${userGuild.username} received ${splitAmount.toFixed(2)} DKP from split`,
          sessionId: 'dkp-management',
          guild: selectedGuild
        });
      }

      // Main split log entry
      await logAction({
        action: LogActions.DKP_AWARDED,
        actor: user.uid,
        details: `Split ${totalAmount} DKP between ${filteredUsers.length} users (${splitAmount.toFixed(2)} each). Reason: ${reason}`,
        sessionId: 'dkp-management',
        guild: selectedGuild
      });

      // Update local state
      setUsers(users.map(u => {
        if (filteredUsers.some(fu => fu.id === u.id)) {
          return { ...u, dkp: u.dkp + splitAmount };
        }
        return u;
      }));

      setFilteredUsers(filteredUsers.map(u => ({
        ...u,
        dkp: u.dkp + splitAmount
      })));

      alert(`DKP split successfully! Each user received ${splitAmount.toFixed(2)} DKP`);
      setDkpChange(0);
      setReason('');
      setSelectedPreset('');
    } catch (err) {
      console.error(err);
      alert('Failed to split DKP.');
    } finally {
      setIsApplying(false);
    }
  };


  const createPreset = async () => {
    if (!newPresetName || newPresetAmount === 0 || !newPresetReason) {
      setError('Please fill all preset fields.');
      return;
    }
    try {
      const presetsRef = collection(db, 'dkpPresets');
      const docRef = await addDoc(presetsRef, {
        name: newPresetName,
        amount: newPresetAmount,
        reason: newPresetReason,
        guild: selectedGuild,
      });
      // Update local state with new preset
      setPresets((prevPresets) => [
        ...prevPresets,
        {
          id: docRef.id,
          name: newPresetName,
          amount: newPresetAmount,
          reason: newPresetReason,
          guild: selectedGuild || '',
        },
      ]);
      setNewPresetName('');
      setNewPresetAmount(0);
      setNewPresetReason('');
      setError('');
    } catch (err) {
      console.error(err);
      setError('Failed to create preset.');
    }
  };

  const deletePreset = async (presetId: string) => {
    try {
      const presetRef = doc(db, 'dkpPresets', presetId);
      await deleteDoc(presetRef);
      // Update local state to remove the preset
      setPresets((prevPresets) =>
        prevPresets.filter((preset) => preset.id !== presetId)
      );
    } catch (err) {
      console.error(err);
      setError('Failed to delete preset.');
    }
  };

  const startEditPreset = (preset: DKPPreset) => {
    setEditPresetId(preset.id);
    setNewPresetName(preset.name);
    setNewPresetAmount(preset.amount);
    setNewPresetReason(preset.reason);
  };

  const savePresetEdit = async () => {
    if (
      !editPresetId ||
      !newPresetName ||
      newPresetAmount === 0 ||
      !newPresetReason
    ) {
      setError('Please fill all preset fields.');
      return;
    }
    try {
      const presetRef = doc(db, 'dkpPresets', editPresetId);
      await updateDoc(presetRef, {
        name: newPresetName,
        amount: newPresetAmount,
        reason: newPresetReason,
        guild: selectedGuild,
      });
      // Update local state with edited preset
      setPresets((prevPresets) =>
        prevPresets.map((preset) =>
          preset.id === editPresetId
            ? {
              ...preset,
              name: newPresetName,
              amount: newPresetAmount,
              reason: newPresetReason,
            }
            : preset
        )
      );
      setEditPresetId(null);
      setNewPresetName('');
      setNewPresetAmount(0);
      setNewPresetReason('');
      setError('');
    } catch (err) {
      console.error(err);
      setError('Failed to update preset.');
    }
  };

  const cancelPresetEdit = () => {
    setEditPresetId(null);
    setNewPresetName('');
    setNewPresetAmount(0);
    setNewPresetReason('');
  };

  const csvData = users.map((userItem) => ({
    Username: userItem.username,
    'In-Game Name': userItem.inGameName || 'N/A',
    DKP: userItem.dkp.toFixed(2),
    'Gear Score': userItem.gearScore || 'N/A',
    'Primary Weapon': userItem.primaryWeapon || 'N/A',
    'Secondary Weapon': userItem.secondaryWeapon || 'N/A',
    'In-Game Roles': userItem.inGameRole?.join(', ') || 'N/A',
    Role: userItem.role || 'Member',
    Status: userItem.status || 'N/A',
    Guild: selectedGuild,
    'User ID': userItem.uid
  }));


  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
      {/* DKP Management Header */}
      <Box sx={{ pt: 3, pb: 2, textAlign: 'center' }}>
        <Typography variant="h4" sx={{ color: 'white', fontWeight: 700 }}>
          DKP Management
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
      </Box>

      {/* DKP Presets Section */}
      <Container maxWidth="lg" sx={{ mb: 4 }}>
        <Box sx={{ background: 'rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(10px)', boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)', padding: 2, borderRadius: 2 }}>
          <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>DKP Presets</Typography>
          {isPresetLoading ? (
            <CircularProgress sx={{ color: 'white' }} />
          ) : (
            <>
              <TableContainer>
                <Table sx={{ backgroundColor: theme.palette.background.paper }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: 'white' }}>Name</TableCell>
                      <TableCell sx={{ color: 'white' }}>Amount</TableCell>
                      <TableCell sx={{ color: 'white' }}>Reason</TableCell>
                      <TableCell sx={{ color: 'white' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {presets.map((preset) => (
                      <TableRow key={preset.id}>
                        <TableCell sx={{ color: 'white' }}>{preset.name}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{preset.amount}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{preset.reason}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{ marginRight: 1 }}
                            onClick={() => startEditPreset(preset)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => deletePreset(preset.id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Preset Form (Create or Edit) */}
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>
                  {editPresetId ? 'Edit Preset' : 'Create New Preset'}
                </Typography>
                <TextField
                  label="Preset Name"
                  value={newPresetName}
                  onChange={(e) => setNewPresetName(e.target.value)}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  type="number"
                  label="Amount"
                  value={newPresetAmount}
                  onChange={(e) => setNewPresetAmount(Number(e.target.value))}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Reason"
                  value={newPresetReason}
                  onChange={(e) => setNewPresetReason(e.target.value)}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button variant="contained" onClick={editPresetId ? savePresetEdit : createPreset} sx={{ flex: 1 }}>
                    {editPresetId ? 'Save Changes' : 'Create Preset'}
                  </Button>
                  <Button variant="outlined" onClick={cancelPresetEdit} sx={{ flex: 1 }}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Container>

      {/* Manage Users' DKP Section */}
      <Container maxWidth="lg" sx={{ mb: 4 }}>
        <Box sx={{ background: 'rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(10px)', boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)', padding: 2, borderRadius: 2 }}>
          <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Manage Users' DKP</Typography>

          {/* Add Mode Toggle Button */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button
              variant="contained"
              color={isDeducting ? "secondary" : "primary"}
              onClick={handleModeToggle}
              sx={{ mb: 2 }}
            >
              {isDeducting ? "Switch to Add DKP Mode" : "Switch to Remove DKP Mode"}
            </Button>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <TextField
              label="Search by username or in-game name"
              value={searchQuery}
              onChange={handleSearchQueryChange}
              fullWidth
            />
            <Select
              value={sortOrder}
              onChange={handleSortOrderChange}
              fullWidth
              sx={{ flex: 1 }}
            >
              <MenuItem value="">No Sorting</MenuItem>
              <MenuItem value="dkp_desc">Sort by DKP (High to Low)</MenuItem>
              <MenuItem value="dkp_asc">Sort by DKP (Low to High)</MenuItem>
            </Select>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <Select
              value={selectedPreset}
              onChange={(e) => handlePresetSelect(e.target.value)}
              fullWidth
            >
              <MenuItem value="">Select Preset</MenuItem>
              {presets.map((preset) => (
                <MenuItem key={preset.id} value={preset.id}>
                  {preset.name} ({isDeducting ? '-' : '+'}${preset.amount})
                </MenuItem>
              ))}
            </Select>
            <TextField
              type="number"
              label={isDeducting ? "Enter DKP Amount to Remove" : "Enter DKP Amount to Add"}
              value={Math.abs(dkpChange)}
              onChange={(e) => setDkpChange(isDeducting ? -Math.abs(Number(e.target.value)) : Math.abs(Number(e.target.value)))}
              fullWidth
              InputProps={{
                startAdornment: <span style={{ color: isDeducting ? 'red' : 'green' }}>{isDeducting ? '-' : '+'}</span>
              }}
            />
            <TextField
              label="Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              fullWidth
            />
          </Box>

          {/* Update button labels to reflect mode */}
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              onClick={applyDkpToSearchedUsers}
              disabled={isApplying || filteredUsers.length === 0 || selectedUsers.length > 0}
              sx={{ flex: 1 }}
            >
              {isDeducting ? 'Remove DKP from Searched Users' : 'Add DKP to Searched Users'} ({filteredUsers.length})
            </Button>
            <Button
              variant="contained"
              onClick={applyDkpChange}
              disabled={isApplying || selectedUsers.length === 0}
              sx={{ flex: 1 }}
            >
              {isDeducting ? 'Remove DKP from Selected Users' : 'Add DKP to Selected Users'} ({selectedUsers.length})
            </Button>
            <Button
              variant="contained"
              onClick={() => splitDkpAmount(dkpChange)}
              disabled={isApplying || filteredUsers.length === 0 || selectedUsers.length > 0}
              sx={{ flex: 1 }}
            >
              Split DKP Amount
            </Button>
          </Box>

          {/* User Table */}
          <TableContainer sx={{ mt: 3 }}>
            <Table sx={{ backgroundColor: theme.palette.background.paper }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: 'white' }}>Select</TableCell>
                  <TableCell sx={{ color: 'white' }}>Username</TableCell>
                  <TableCell sx={{ color: 'white' }}>In-Game Name</TableCell>
                  <TableCell sx={{ color: 'white' }}>DKP</TableCell>
                  <TableCell sx={{ color: 'white' }}>Guild</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.length > 0 ? (
                  filteredUsers.map((userItem) => (
                    <TableRow
                      key={userItem.id}
                      sx={{
                        backgroundColor: selectedUsers.includes(userItem.id) ? theme.palette.primary.main : 'transparent',
                      }}
                    >
                      <TableCell>
                        <Button
                          onClick={() => handleUserSelect(userItem.id)}
                          variant="outlined"
                          sx={{
                            backgroundColor: selectedUsers.includes(userItem.id) ? theme.palette.primary.dark : 'transparent',
                            color: 'white',
                          }}
                        >
                          {selectedUsers.includes(userItem.id) ? 'Selected' : 'Select'}
                        </Button>
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>{userItem.username}</TableCell>
                      <TableCell sx={{ color: 'white' }}>{userItem.inGameName || 'N/A'}</TableCell>
                      <TableCell sx={{ color: 'white' }}>{userItem.dkp}</TableCell>
                      <TableCell sx={{ color: 'white' }}>{selectedGuild}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ color: 'white', textAlign: 'center' }}>
                      No users found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>


      {/* DKP Decay and Tax Management */}
      {selectedGuild && (
        <Container maxWidth="lg" sx={{ mb: 4 }}>
          <DKPDecay
            adminGuild={selectedGuild}
            users={users}
            setUsers={setUsers}
          />
        </Container>
      )}

      {/* DKP Change History */}
      <Container maxWidth="lg" sx={{ mb: 4 }}>
        <Box sx={{ background: 'rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(10px)', boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)', padding: 2, borderRadius: 2 }}>
          <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>DKP Change History (Last 50)</Typography>
          <TableContainer>
            <Table sx={{ backgroundColor: theme.palette.background.paper }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: 'white' }}>User</TableCell>
                  <TableCell sx={{ color: 'white' }}>Change Amount</TableCell>
                  <TableCell sx={{ color: 'white' }}>Reason</TableCell>
                  <TableCell sx={{ color: 'white' }}>Admin</TableCell>
                  <TableCell sx={{ color: 'white' }}>Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dkpLogs.length > 0 ? (
                  dkpLogs.map((log) => (
                    <TableRow key={log.id}>
                      <TableCell sx={{ color: 'white' }}>{log.username}</TableCell>
                      <TableCell sx={{ color: 'white' }}>{log.changeAmount.toFixed(2)}</TableCell>
                      <TableCell sx={{ color: 'white' }}>{log.reason}</TableCell>
                      <TableCell sx={{ color: 'white' }}>{log.adminName}</TableCell>
                      <TableCell sx={{ color: 'white' }}>{log.timestamp.toLocaleString()}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ color: 'white', textAlign: 'center' }}>
                      No DKP logs available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>

      {/* CSV Export */}
      <Box sx={{ textAlign: 'center', my: 3 }}>
        <CSVLink data={csvData} filename="dkp_data.csv">
          <Button variant="contained" color="primary">Export DKP Data as CSV</Button>
        </CSVLink>
      </Box>
    </Box>
  );


};

export default DKPManagement;
