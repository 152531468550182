import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    Switch,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    IconButton,
    TextField,
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import {
    doc,
    updateDoc,
    arrayUnion,
    arrayRemove,
    onSnapshot,
    serverTimestamp,
    DocumentSnapshot,
    DocumentData,
    getDoc
} from 'firebase/firestore';
import { db } from '../../firebase';
import itemsDataNew from "../../items.json";


interface ManageUserWishlistProps {
    open: boolean;
    onClose: () => void;
    user?: {
        uid: string;
        username: string;
        userGuildId: string;
    };
    guildId: string;
}

interface WishlistItem {
    itemName: string;
    imageUrl: string;
    addedAt: any;
}

export const ManageUserWishlist: React.FC<ManageUserWishlistProps> = ({
    open,
    onClose,
    user,
    guildId,
}) => {
    const [wishlistItems, setWishlistItems] = useState<WishlistItem[]>([]);
    const [isLocked, setIsLocked] = useState(false);
    const [newItemName, setNewItemName] = useState('');
    const [filteredItems, setFilteredItems] = useState<any[]>([]);

    useEffect(() => {
        if (!user) return;

        const userGuildRef = doc(db, 'userGuilds', user.userGuildId);
        const unsubscribe = onSnapshot(userGuildRef, (doc) => {
            if (doc.exists()) {
                setWishlistItems(doc.data()?.wishlist || []);
                setIsLocked(doc.data()?.wishlistLocked || false);
                setWonItems(doc.data()?.wonItems || []); // Fetch won items
            }
        });

        return () => unsubscribe();
    }, [user?.userGuildId]);

    // State to store won items
    const [wonItems, setWonItems] = useState<string[]>([]);

    const handleLockToggle = async () => {
        if (!user) return; // Add this check

        const userGuildRef = doc(db, 'userGuilds', user.userGuildId);
        await updateDoc(userGuildRef, {
            wishlistLocked: !isLocked
        });
        setIsLocked(!isLocked);
    };

    const handleAddItem = async () => {
        if (!user || !newItemName.trim()) return;

        const item = itemsDataNew.find(i =>
            i.name.toLowerCase() === newItemName.toLowerCase()
        );

        if (!item) return;

        const userGuildRef = doc(db, 'userGuilds', user.userGuildId);

        // First, update the document with a server timestamp
        await updateDoc(userGuildRef, {
            lastUpdated: serverTimestamp()
        });

        // Retrieve the current timestamp from the server
        const userGuildDoc = await getDoc(userGuildRef);
        const currentTimestamp = userGuildDoc.data()?.lastUpdated;

        // Then, add the item to the wishlist with the retrieved timestamp
        const wishlistItem = {
            itemName: item.name,
            imageUrl: `/images/new/${item.imageFilename}`,
            addedAt: currentTimestamp // Use the retrieved timestamp
        };

        await updateDoc(userGuildRef, {
            wishlist: arrayUnion(wishlistItem)
        });

        setNewItemName('');
    };



    const handleRemoveItem = async (item: WishlistItem) => {
        if (!user) return; // Add this check

        const userGuildRef = doc(db, 'userGuilds', user.userGuildId);
        await updateDoc(userGuildRef, {
            wishlist: arrayRemove(item)
        });
    };


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        setNewItemName(searchTerm);

        if (searchTerm.trim()) {
            const filtered = itemsDataNew.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredItems(filtered);
        } else {
            setFilteredItems([]);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Manage Wishlist - {user ? user.username : 'Unknown User'}
                <FormControlLabel
                    control={
                        <Switch
                            checked={isLocked}
                            onChange={handleLockToggle}
                            color="primary"
                        />
                    }
                    label={isLocked ? "Locked" : "Unlocked"}
                    sx={{ ml: 2 }}
                />
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Add Item"
                        value={newItemName}
                        onChange={handleSearchChange}
                        sx={{ mb: 1 }}
                    />
                    {filteredItems.length > 0 && (
                        <Box sx={{ maxHeight: 200, overflow: 'auto', mb: 2 }}>
                            {filteredItems.map((item) => (
                                <Button
                                    key={item.name}
                                    fullWidth
                                    onClick={() => {
                                        setNewItemName(item.name);
                                        setFilteredItems([]);
                                    }}
                                    sx={{ justifyContent: 'flex-start', textAlign: 'left' }}
                                >
                                    {item.name}
                                </Button>
                            ))}
                        </Box>
                    )}
                    <Button
                        variant="contained"
                        onClick={handleAddItem}
                        startIcon={<AddIcon />}
                        disabled={!newItemName.trim()}
                    >
                        Add Item
                    </Button>
                </Box>

                <List>
                    {wishlistItems.map((item, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    onClick={() => handleRemoveItem(item)}
                                    disabled={isLocked}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <Box
                                component="img"
                                src={item.imageUrl}
                                alt={item.itemName}
                                sx={{ width: 40, height: 40, mr: 2 }}
                            />
                            <ListItemText
                                primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {item.itemName}
                                        {wonItems.includes(item.itemName) ? (
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    backgroundColor: 'success.main',
                                                    color: 'white',
                                                    padding: '2px 8px',
                                                    borderRadius: '4px',
                                                    ml: 1
                                                }}
                                            >
                                                Won
                                            </Typography>
                                        ) : (
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    backgroundColor: 'warning.light',
                                                    color: 'white',
                                                    padding: '2px 8px',
                                                    borderRadius: '4px',
                                                    ml: 1
                                                }}
                                            >
                                                Not Yet Won
                                            </Typography>
                                        )}
                                    </Box>
                                }
                                secondary={item.addedAt?.toDate().toLocaleString()}
                            />
                        </ListItem>
                    ))}
                </List>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ManageUserWishlist;
