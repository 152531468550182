import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Stack,
  Divider
} from '@mui/material';

interface RollModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (reason?: string) => void;
  requireReason?: boolean;
}

const RollModal: React.FC<RollModalProps> = ({ open, onClose, onConfirm, requireReason = false }) => {
  const [rollReason, setRollReason] = useState<string>('');

  const handleConfirm = () => {
    if (!requireReason || rollReason.trim()) {
      onConfirm(rollReason);
      setRollReason('');
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        maxWidth: 500,
        width: '90%'
      }}>
        <Stack spacing={3}>
          <Typography variant="h6">
            Confirm Roll
          </Typography>

          <Divider />

          {requireReason && (
            <TextField
              required
              fullWidth
              label="Reason for Roll"
              value={rollReason}
              onChange={(e) => setRollReason(e.target.value)}
              placeholder="Enter your reason for rolling..."
              multiline
              rows={2}
            />
          )}

          <Button
            variant="contained"
            fullWidth
            disabled={requireReason && !rollReason.trim()}
            onClick={handleConfirm}
          >
            Confirm Roll
          </Button>

          <Button
            variant="outlined"
            fullWidth
            onClick={onClose}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default RollModal;
