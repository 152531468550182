import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  getDoc,
  doc,
} from "firebase/firestore";
import { Roll } from "../interfaces";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Alert,
} from "@mui/material";

interface RollingHistoryProps {
  sessionId: string;
  lootId: string;
}

interface ExtendedRoll extends Roll {
  rollerName: string;
  roll: number;
  timestamp: Date;
}

const RollingHistory: React.FC<RollingHistoryProps> = ({
  sessionId,
  lootId,
}) => {
  const [rolls, setRolls] = useState<ExtendedRoll[]>([]);
  const [userInfo, setUserInfo] = useState<Record<string, any>>({});
  const [error, setError] = useState<string | null>(null);
  const [guildId, setGuildId] = useState<string>("");

  // Fetch guildId directly from the session document
  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const sessionDocRef = doc(db, "sessions", sessionId);
        const sessionDocSnap = await getDoc(sessionDocRef);
        if (sessionDocSnap.exists()) {
          const sessionData = sessionDocSnap.data();
          setGuildId(sessionData.guild);
        } else {
          setError("Session not found.");
        }
      } catch (err) {
        setError("Failed to fetch session data.");
      }
    };

    if (sessionId) {
      fetchSessionData();
    }
  }, [sessionId]);

  useEffect(() => {
    if (!sessionId || !lootId) return;

    const rollsRef = collection(
      db,
      "sessions",
      sessionId,
      "lootDrops",
      lootId,
      "rolls"
    );
    const q = query(rollsRef, orderBy("createdAt", "desc"));

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const rollList = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          rollerId: data.rollerId,
          rollerName: data.rollerName,
          roll: data.roll,
          timestamp: data.createdAt?.toDate() || new Date(),
        } as ExtendedRoll;
      });

      setRolls(rollList);

      const rollerIds = Array.from(
        new Set(rollList.map((roll) => roll.rollerId).filter((id) => id))
      );

      // Fetch user data
      const userInfoPromises = rollerIds.map(async (uid) => {
        let userData = null;

        // Try fetching from userGuilds
        if (guildId) {
          const userGuildDocRef = doc(db, "userGuilds", `${uid}_${guildId}`);
          const userGuildDocSnap = await getDoc(userGuildDocRef);
          if (userGuildDocSnap.exists()) {
            const data = userGuildDocSnap.data();
            userData = {
              username: data.username,
              inGameName: data.inGameName || "N/A",
              primaryWeapon: data.primaryWeapon || "N/A",
              secondaryWeapon: data.secondaryWeapon || "N/A",
              gearScore: data.gearScore || 0,
              inGameRole: data.inGameRole || [],
            };
          }
        }

        // Fallback to users collection if not found in userGuilds
        if (!userData) {
          const userDocRef = doc(db, "users", uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const data = userDocSnap.data();
            userData = {
              username: data.username || "Unknown",
              inGameName: data.inGameName || "N/A",
              primaryWeapon: "N/A",
              secondaryWeapon: "N/A",
              gearScore: 0,
              inGameRole: [],
            };
          }
        }

        return {
          uid,
          data: userData,
        };
      });

      const userInfoArray = await Promise.all(userInfoPromises);

      const newUserInfo: Record<string, any> = {};
      userInfoArray.forEach(({ uid, data }) => {
        if (data) {
          newUserInfo[uid] = data;
        }
      });

      setUserInfo(newUserInfo);
    });

    return () => unsubscribe();
  }, [sessionId, lootId, guildId]);

  return (
    <Box className="container" p={3}>
      <Typography variant="h4" gutterBottom>
        Rolling History
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      {rolls.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          No rolls yet.
        </Typography>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Roller</strong>
                </TableCell>
                <TableCell>
                  <strong>In-Game Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Weapons</strong>
                </TableCell>
                <TableCell>
                  <strong>Roles</strong>
                </TableCell>
                <TableCell>
                  <strong>Gear Score</strong>
                </TableCell>
                <TableCell>
                  <strong>Roll</strong>
                </TableCell>
                <TableCell>
                  <strong>Time</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rolls.map((roll) => {
                const user = userInfo[roll.rollerId];
                return (
                  <TableRow key={roll.id}>
                    <TableCell>
                      {user?.username || roll.rollerName || "Unknown"}
                    </TableCell>
                    <TableCell>{user?.inGameName || "N/A"}</TableCell>
                    <TableCell>
                      {user
                        ? `${user.primaryWeapon} / ${user.secondaryWeapon}`
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {user?.inGameRole?.join(", ") || "N/A"}
                    </TableCell>
                    <TableCell>{user?.gearScore || 0}</TableCell>
                    <TableCell>{roll.roll}</TableCell>
                    <TableCell>{roll.timestamp.toLocaleString()}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default RollingHistory;
