//src\components\Auth.tsx

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../firebase';

const Auth: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authenticate = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
      const guild = params.get('guild'); // Get the guild from the URL


      if (token) {
        try {
          if (guild) {
            // Store the selected guild in localStorage BEFORE authentication
            localStorage.setItem('selectedGuild', guild);
          }

          await signInWithCustomToken(auth, token);

          navigate('/dashboard'); // Redirect to dashboard

        } catch (error: any) {
          console.error('Auth - Authentication error:', error);
          navigate('/login'); // Navigate to login on error
        }
      } else {
        console.error('Auth - No token found in URL');
        navigate('/login'); // Navigate to login if no token found
      }
    };

    authenticate();
  }, [navigate]);

  return <div>Authenticating...</div>;
};

export default Auth;
