import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  ButtonGroup,
  Box
} from '@mui/material';
import { isSessionExpired } from '../components/sessions/checkExpire';

interface ConfirmationModalProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
  hideCancelButton?: boolean;
  isError?: boolean;
  options?: { label: string; onClick: () => void }[];
  sessionId?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  message,
  onConfirm,
  onCancel,
  hideCancelButton,
  isError,
  options,
  sessionId,
}) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  const handleOptionClick = (index: number, onClick: () => void) => {
    setSelectedOption(index);
    onClick();
  };

  const handleConfirm = async () => {
    if (sessionId) {
      const expired = await isSessionExpired(sessionId);
      if (expired) {
        setSelectedOption(null);
        return;
      }
    }
    onConfirm();
  };

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{
        color: isError ? 'error.main' : 'primary.main',
        fontWeight: 'bold'
      }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
        {options && options.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <ButtonGroup variant="outlined" fullWidth>
              {options.map((option, index) => (
                <Button
                  key={index}
                  onClick={() => handleOptionClick(index, option.onClick)}
                  variant={selectedOption === index ? "contained" : "outlined"}
                >
                  {option.label}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color={isError ? "error" : "primary"}
        >
          OK
        </Button>
        {!hideCancelButton && onCancel && (
          <Button onClick={onCancel} variant="outlined">
            Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
