import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Box, Container, Grid, Card, CardContent, Typography, Button, Paper,
    useTheme, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Tabs, Tab, Divider, CircularProgress, TextField, Alert
} from '@mui/material';
import {
    collection, doc, getDoc, query, getDocs, where, updateDoc,
    arrayUnion, arrayRemove, onSnapshot, writeBatch, increment, Timestamp
} from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';
import { DateTime } from 'luxon';
import { DistributeModal, SplitModal } from './DKPModals';
import EventParticipationLink from './EventParticipationLink';
import { FormControlLabel, Switch } from '@mui/material';


interface EventDetailsData {
    id: string;
    title: string;
    startDate: string;
    endDate: string;
    description: string;
    createdBy: string;
    maxParticipants: number;
    registeredUsers: string[];
    dkpValue: number;
    isCompleted: boolean;
    dkpDistributed: boolean;
    activeVerification?: boolean;
    participationCode?: string;
    participationCodeExpiry?: any;
    verifiedParticipants?: string[];
    participationAttempts?: { [key: string]: number };
    requireParticipationLink?: boolean;
    allowLateRegistration?: boolean;
}

interface ParticipantData {
    uid: string;
    inGameName: string;
    dkp: number;
    primaryWeapon: string;
    secondaryWeapon: string;
    gearScore: number;
    userGuildId: string;
    docRef: any;
}

interface WeaponStats {
    [key: string]: number;
}

const EventDetails: React.FC = () => {
    const theme = useTheme();
    const { eventId } = useParams();
    const { selectedGuild, user } = useUser();
    const [eventDetails, setEventDetails] = React.useState<EventDetailsData | null>(null);
    const [tempDkpValue, setTempDkpValue] = React.useState(0);
    const [participants, setParticipants] = React.useState<ParticipantData[]>([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [weaponStats, setWeaponStats] = React.useState<WeaponStats>({});
    const [averageGearScore, setAverageGearScore] = React.useState(0);
    const [updatedDkpValue, setUpdatedDkpValue] = React.useState(0);
    const [openDistributeModal, setOpenDistributeModal] = React.useState(false);
    const [openSplitModal, setOpenSplitModal] = React.useState(false);
    const [openParticipationLink, setOpenParticipationLink] = React.useState(false);


    React.useEffect(() => {
        if (eventDetails) {
            setTempDkpValue(eventDetails.dkpValue);
        }
    }, [eventDetails]);

    const handleUpdateDKPValue = async (value: number) => {
        if (!selectedGuild || !eventId) return;
        const eventDocRef = doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId);
        await updateDoc(eventDocRef, { dkpValue: value });
        setUpdatedDkpValue(value);
    };

    const handleDistributeDKP = async () => {
        if (!selectedGuild || !eventId || !eventDetails) return;
        try {
            const batch = writeBatch(db);
            const verifiedUsers = eventDetails.verifiedParticipants || [];

            participants
                .filter(p => verifiedUsers.includes(p.uid))
                .forEach(participant => {
                    batch.update(participant.docRef, {
                        dkp: increment(eventDetails.dkpValue)
                    });

                    const dkpLogsRef = collection(db, 'dkpLogs');
                    const newLogRef = doc(dkpLogsRef);
                    batch.set(newLogRef, {
                        userId: participant.uid,
                        username: participant.inGameName,
                        changeAmount: eventDetails.dkpValue,
                        reason: `Event completion: ${eventDetails.title}`,
                        timestamp: Timestamp.now(),
                        adminId: user?.uid,
                        guild: selectedGuild,
                    });
                });

            const eventDocRef = doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId);
            batch.update(eventDocRef, {
                isCompleted: true,
                dkpDistributed: true
            });

            await batch.commit();
        } catch (error) {
            console.error('Error distributing DKP:', error);
        }
    };

    const handleSplitDKP = async () => {
        if (!selectedGuild || !eventId || !eventDetails) return;
        try {
            const batch = writeBatch(db);
            const verifiedUsers = eventDetails.verifiedParticipants || [];
            const verifiedParticipants = participants.filter(p => verifiedUsers.includes(p.uid));
            const splitAmount = Math.floor(eventDetails.dkpValue / verifiedParticipants.length);

            verifiedParticipants.forEach(participant => {
                batch.update(participant.docRef, {
                    dkp: increment(splitAmount)
                });

                const dkpLogsRef = collection(db, 'dkpLogs');
                const newLogRef = doc(dkpLogsRef);
                batch.set(newLogRef, {
                    userId: participant.uid,
                    username: participant.inGameName,
                    changeAmount: splitAmount,
                    reason: `Split DKP from event: ${eventDetails.title}`,
                    timestamp: Timestamp.now(),
                    adminId: user?.uid,
                    guild: selectedGuild,
                });
            });

            const eventDocRef = doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId);
            batch.update(eventDocRef, {
                isCompleted: true,
                dkpDistributed: true
            });

            await batch.commit();
        } catch (error) {
            console.error('Error splitting DKP:', error);
        }
    };

    const handleRegistration = async () => {
        if (!user || !selectedGuild || !eventId) return;
        const eventDocRef = doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId);
        await updateDoc(eventDocRef, {
            registeredUsers: arrayUnion(user.uid)
        });
        setEventDetails(prev => {
            if (!prev) return prev;
            return {
                ...prev,
                registeredUsers: [...prev.registeredUsers, user.uid]
            };
        });
    };

    const handleUnregister = async () => {
        if (!user || !selectedGuild || !eventId) return;
        const eventDocRef = doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId);
        await updateDoc(eventDocRef, {
            registeredUsers: arrayRemove(user.uid)
        });
        setEventDetails(prev => {
            if (!prev) return prev;
            return {
                ...prev,
                registeredUsers: prev.registeredUsers.filter(id => id !== user.uid)
            };
        });
    };


    React.useEffect(() => {
        if (!selectedGuild || !eventId) return;

        // Listen to event details
        const eventsCollectionRef = collection(db, 'events');
        const guildDocRef = doc(eventsCollectionRef, selectedGuild);
        const guildEventsRef = collection(guildDocRef, 'guildEvents');
        const eventDocRef = doc(guildEventsRef, eventId);

        const unsubscribeEvent = onSnapshot(eventDocRef, (eventDoc) => {
            if (eventDoc.exists()) {
                const eventData = { id: eventDoc.id, ...eventDoc.data() } as EventDetailsData;
                setEventDetails(eventData);
            }
        });

        return () => unsubscribeEvent();
    }, [selectedGuild, eventId]);

    React.useEffect(() => {
        const fetchParticipants = async () => {
            if (!selectedGuild || !eventDetails?.registeredUsers) return;

            const userGuildsRef = collection(db, 'userGuilds');

            const registeredUserIds = eventDetails.registeredUsers;
            const participantsList: ParticipantData[] = [];
            const weaponCounts: WeaponStats = {};
            let totalGearScore = 0;

            const batchSize = 10;
            const batches = [];
            for (let i = 0; i < registeredUserIds.length; i += batchSize) {
                const batchIds = registeredUserIds.slice(i, i + batchSize);
                const q = query(
                    userGuildsRef,
                    where('guildId', '==', selectedGuild),
                    where('uid', 'in', batchIds)
                );
                batches.push(getDocs(q));
            }

            const results = await Promise.all(batches);
            results.forEach((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    participantsList.push({
                        uid: data.uid,
                        inGameName: data.inGameName || 'N/A',
                        dkp: data.dkp || 0,
                        primaryWeapon: data.primaryWeapon || 'N/A',
                        secondaryWeapon: data.secondaryWeapon || 'N/A',
                        gearScore: data.gearScore || 0,
                        userGuildId: doc.id,
                        docRef: doc.ref
                    });

                    if (data.primaryWeapon) {
                        weaponCounts[data.primaryWeapon] = (weaponCounts[data.primaryWeapon] || 0) + 1;
                    }
                    if (data.secondaryWeapon) {
                        weaponCounts[data.secondaryWeapon] = (weaponCounts[data.secondaryWeapon] || 0) + 1;
                    }

                    totalGearScore += data.gearScore || 0;
                });
            });

            setParticipants(participantsList.sort((a, b) => b.gearScore - a.gearScore));
            setWeaponStats(weaponCounts);
            setAverageGearScore(
                participantsList.length > 0
                    ? Math.round(totalGearScore / participantsList.length)
                    : 0
            );
        };

        fetchParticipants();
    }, [selectedGuild, eventDetails?.registeredUsers]);

    const renderParticipantList = () => (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Rank</TableCell>
                        <TableCell>Player</TableCell>
                        <TableCell>Weapons</TableCell>
                        <TableCell align="right">Gear Score</TableCell>
                        <TableCell align="right">DKP</TableCell>
                        <TableCell align="center">Attendance</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {participants.map((participant, index) => (
                        <TableRow key={participant.uid}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{participant.inGameName}</TableCell>
                            <TableCell>
                                {participant.primaryWeapon} {participant.secondaryWeapon && `+ ${participant.secondaryWeapon}`}
                            </TableCell>
                            <TableCell align="right">{participant.gearScore}</TableCell>
                            <TableCell align="right">{participant.dkp}</TableCell>
                            <TableCell align="center">
                                {eventDetails?.verifiedParticipants?.includes(participant.uid) ? (
                                    <Typography color="success.main">✓</Typography>
                                ) : (
                                    <Typography color="error.main">✗</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderEventStats = () => (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Event Statistics</Typography>
            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>Weapon Distribution</Typography>
                {Object.entries(weaponStats)
                    .sort(([, a], [, b]) => b - a)
                    .map(([weapon, count]) => (
                        <Box key={weapon} sx={{ mb: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2">{weapon}</Typography>
                                <Typography variant="body2">{count} players</Typography>
                            </Box>
                            <Box sx={{
                                width: '100%',
                                height: '4px',
                                bgcolor: theme.palette.grey[200],
                                borderRadius: '2px',
                            }}>
                                <Box sx={{
                                    width: `${(count / participants.length) * 100}%`,
                                    height: '100%',
                                    bgcolor: theme.palette.primary.main,
                                    borderRadius: '2px',
                                }} />
                            </Box>
                        </Box>
                    ))}
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>Party Composition</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography>Average Gear Score:</Typography>
                    <Typography fontWeight="bold">{averageGearScore}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography>Total Participants:</Typography>
                    <Typography fontWeight="bold">{participants.length}/{eventDetails?.maxParticipants}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography>Verified Attendees:</Typography>
                    <Typography fontWeight="bold">
                        {eventDetails?.verifiedParticipants?.length || 0}/{participants.length}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );

    if (!eventDetails) return <CircularProgress />;

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
            {/* Hero Header */}
            <Box sx={{
                position: 'relative',
                height: '200px',
                overflow: 'hidden',
            }}>
                {/* Background Image */}
                <Box sx={{
                    backgroundImage: 'url(/images/newsite/bkg.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    filter: 'blur(1px)',
                    zIndex: 1,
                }} />

                {/* Dark Overlay */}
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    zIndex: 2,
                }} />

                {/* Content */}
                <Container maxWidth="lg" sx={{ height: '100%', position: 'relative', zIndex: 3 }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                        color: 'white'
                    }}>
                        <Typography variant="h4" sx={{ fontWeight: 700 }}>
                            {eventDetails.title}
                        </Typography>
                        <Typography variant="subtitle1">
                            {DateTime.fromISO(eventDetails.startDate).toLocaleString(DateTime.DATETIME_FULL)}
                        </Typography>
                    </Box>
                </Container>
            </Box>

            {/* Main Content */}
            <Container maxWidth="lg" sx={{ mt: -5, position: 'relative', zIndex: 4 }}>
                <Grid container spacing={3}>
                    {/* Event Info Card */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{
                            height: '100%',
                            background: 'rgba(255, 255, 255, 0.1)',
                            backdropFilter: 'blur(10px)',
                            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
                        }}>
                            <CardContent>
                                <Box sx={{ textAlign: 'center', mb: 3 }}>
                                    <Typography variant="h6" sx={{ mb: 2 }}>Event Details</Typography>
                                    <Typography color="primary" sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
                                        {eventDetails.dkpValue} DKP
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    p: 2,
                                    backgroundColor: theme.palette.background.paper,
                                    borderRadius: 1
                                }}>
                                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Participants</span>
                                        <strong>{participants.length}/{eventDetails.maxParticipants}</strong>
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Average GS</span>
                                        <strong>{averageGearScore}</strong>
                                    </Typography>
                                </Box>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color={eventDetails.registeredUsers.includes(user?.uid || '') ? "error" : "primary"}
                                    onClick={eventDetails.registeredUsers.includes(user?.uid || '') ? handleUnregister : handleRegistration}
                                    disabled={
                                        (participants.length >= eventDetails.maxParticipants &&
                                            !eventDetails.registeredUsers.includes(user?.uid || '')) ||
                                        (eventDetails.requireParticipationLink &&
                                            !eventDetails.verifiedParticipants?.includes(user?.uid || ''))
                                    }
                                    sx={{ mt: 2 }}
                                >
                                    {eventDetails.registeredUsers.includes(user?.uid || '')
                                        ? 'Unregister'
                                        : eventDetails.requireParticipationLink && !eventDetails.verifiedParticipants?.includes(user?.uid || '')
                                            ? 'Registration Link Required'
                                            : 'Register for Event'}
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Participants and Stats */}
                    <Grid item xs={12} md={8}>
                        <Card>
                            <CardContent>
                                {eventDetails.activeVerification && (
                                    <Alert severity="info" sx={{ mb: 2 }}>
                                        Attendance verification is active until{' '}
                                        {eventDetails.participationCodeExpiry?.toDate().toLocaleTimeString()}
                                    </Alert>
                                )}
                                <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)} sx={{ mb: 2 }}>
                                    <Tab label="Participants" />
                                    <Tab label="Event Statistics" />
                                </Tabs>
                                {tabValue === 0 ? renderParticipantList() : renderEventStats()}
                            </CardContent>
                        </Card>

                        {/* Admin Controls */}
                        {user?.memberships.find(m => m.guildId === selectedGuild)?.role === 'admin' && !eventDetails.dkpDistributed && (
                            <Card sx={{ mt: 3 }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>Admin Controls</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => setOpenParticipationLink(true)}
                                                color={eventDetails.activeVerification ? "secondary" : "primary"}
                                            >
                                                {eventDetails.activeVerification ? 'Generate New Verification Code' : 'Start Attendance Check'}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={eventDetails.allowLateRegistration || false}
                                                        onChange={async (e) => {
                                                            if (!selectedGuild || !eventId) return;
                                                            const eventsCollectionRef = collection(db, 'events');
                                                            const guildDocRef = doc(eventsCollectionRef, selectedGuild);
                                                            const guildEventsRef = collection(guildDocRef, 'guildEvents');
                                                            const eventDocRef = doc(guildEventsRef, eventId);

                                                            await updateDoc(eventDocRef, {
                                                                allowLateRegistration: e.target.checked
                                                            });
                                                        }}
                                                    />
                                                }
                                                label="Allow Late Registration with Verification"
                                            />

                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                label="DKP Value"
                                                value={tempDkpValue}
                                                onChange={(e) => setTempDkpValue(parseInt(e.target.value))}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => handleUpdateDKPValue(tempDkpValue)}
                                                sx={{ height: '56px' }}
                                            >
                                                Set DKP
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => setOpenDistributeModal(true)}
                                                color="primary"
                                            >
                                                Distribute DKP
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => setOpenSplitModal(true)}
                                                color="secondary"
                                            >
                                                Split DKP
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                </Grid>
            </Container>

            {/* Modals */}
            <DistributeModal
                open={openDistributeModal}
                onClose={() => setOpenDistributeModal(false)}
                onConfirm={() => {
                    handleDistributeDKP();
                    setOpenDistributeModal(false);
                }}
                dkpValue={eventDetails.dkpValue}
                participants={participants.filter(p => eventDetails.verifiedParticipants?.includes(p.uid))}
            />

            <SplitModal
                open={openSplitModal}
                onClose={() => setOpenSplitModal(false)}
                onConfirm={() => {
                    handleSplitDKP();
                    setOpenSplitModal(false);
                }}
                dkpValue={eventDetails.dkpValue}
                participants={participants.filter(p => eventDetails.verifiedParticipants?.includes(p.uid))}
            />

            {eventId && selectedGuild && (
                <EventParticipationLink
                    open={openParticipationLink}
                    onClose={() => setOpenParticipationLink(false)}
                    eventId={eventId}
                    guildId={selectedGuild}
                    registeredUsers={eventDetails.registeredUsers}
                />
            )}
        </Box>
    );
};

export default EventDetails;
