// LootTable.tsx
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress, Stack, Card, Avatar, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getDoc, doc, Timestamp, collection, query, orderBy, getDocs, limit } from 'firebase/firestore';
import { db } from '../../../firebase';
import RollingHistory from '../../RollingHistory';
import BiddingHistory from '../../BiddingHistory';
import { LootDrop } from '../../../interfaces';
import { HandleRollLootProps } from '../../../interfaces/handlers';
import itemsDataNew from '../../../items.json';

interface ReasonEntry {
    inGameName: string;
    value: number | string;
    reason?: string;
    isWinner: boolean;
}

interface LootTableProps {
    loading: boolean;
    lootDrops: LootDrop[];
    isLootmaster: boolean;
    historyVisible: Record<string, boolean>;
    bidHistoryVisible: Record<string, boolean>;
    sessionId: string;
    user: any;
    onRoll: (props: HandleRollLootProps) => Promise<void>;
    onDistribute: (lootId: string) => Promise<void>;
    onRemove: (lootId: string) => void;
    onToggleHistory: (lootId: string) => void;
    onToggleBidHistory: (lootId: string) => void;
    onBid: (lootId: string) => void;
    hasRolls: Record<string, boolean>;
    hasBids: Record<string, boolean>;
    session: any;
    aggregateData: {
        highestRolls: Record<string, number>;
        currentBids: Record<string, number>;
        bidCounts: Record<string, number>;
        rollCounts: Record<string, number>;
    };
}

export const LootTable: React.FC<LootTableProps> = ({
    loading,
    lootDrops,
    isLootmaster,
    historyVisible,
    bidHistoryVisible,
    sessionId,
    user,
    onRoll,
    onDistribute,
    onRemove,
    onToggleHistory,
    onToggleBidHistory,
    onBid,
    hasRolls,
    hasBids,
    session,
    aggregateData
}) => {
    const theme = useTheme();
    const [currentWinners, setCurrentWinners] = useState<Record<string, ReasonEntry>>({});
    const [guildId, setGuildId] = useState<string>('');
    const [reasonModalOpen, setReasonModalOpen] = useState(false);
    const [selectedReasons, setSelectedReasons] = useState<ReasonEntry[]>([]);
    const [selectedLootMethod, setSelectedLootMethod] = useState<string>('');

    useEffect(() => {
        const fetchSessionData = async () => {
            const sessionDocRef = doc(db, 'sessions', sessionId);
            const sessionDocSnap = await getDoc(sessionDocRef);
            if (sessionDocSnap.exists()) {
                const sessionData = sessionDocSnap.data();
                setGuildId(sessionData.guild);
            }
        };

        fetchSessionData();
    }, [sessionId]);

    useEffect(() => {
        const fetchCurrentWinners = async () => {
            const newCurrentWinners: Record<string, ReasonEntry> = {};

            for (const loot of lootDrops) {
                if (loot.lootMethod === 'roll') {
                    const rollsRef = collection(db, "sessions", sessionId, "lootDrops", loot.id, "rolls");
                    const q = query(rollsRef, orderBy("roll", "desc"), limit(1));
                    const rollsSnapshot = await getDocs(q);
                    if (!rollsSnapshot.empty) {
                        const rollDoc = rollsSnapshot.docs[0];
                        const rollData = rollDoc.data();
                        const highestRoll = rollData.roll;
                        const userId = rollData.rollerId;

                        const userGuildDocRef = doc(db, 'userGuilds', `${userId}_${guildId}`);
                        const userGuildDocSnap = await getDoc(userGuildDocRef);
                        const userData = userGuildDocSnap.exists() ? userGuildDocSnap.data() : null;

                        newCurrentWinners[loot.id] = {
                            inGameName: userData?.inGameName || rollData.rollerName || 'Unknown',
                            value: highestRoll,
                            reason: rollData.reason || 'No reason provided', // Adding default reason if not present
                            isWinner: true // Assuming the single highest roll is the winner
                        };
                    }
                } else if (loot.lootMethod === 'dkp') {
                    const currentBid = aggregateData.currentBids[loot.id];
                    if (currentBid !== undefined) {
                        const bidderId = loot.currentBidder || '';
                        const userGuildDocRef = doc(db, 'userGuilds', `${bidderId}_${guildId}`);
                        const userGuildDocSnap = await getDoc(userGuildDocRef);
                        const userData = userGuildDocSnap.exists() ? userGuildDocSnap.data() : null;

                        newCurrentWinners[loot.id] = {
                            inGameName: userData?.inGameName || loot.currentBidderName || 'Unknown',
                            value: currentBid,
                            reason: 'Top bidder', // Default reason for DKP
                            isWinner: true // Assuming the top bid is the winner
                        };
                    }
                }
            }

            setCurrentWinners(newCurrentWinners);

        };

        if (lootDrops.length > 0 && guildId) {
            fetchCurrentWinners();
        }
    }, [aggregateData, lootDrops, guildId, sessionId]);

    const openReasonModal = async (lootId: string, lootMethod: string) => {
        const reasons: ReasonEntry[] = [];

        if (lootMethod === 'roll') {
            const rollsRef = collection(db, "sessions", sessionId, "lootDrops", lootId, "rolls");
            const q = query(rollsRef, orderBy("roll", "desc"));
            const rollsSnapshot = await getDocs(q);
            for (const docSnap of rollsSnapshot.docs) {
                const rollData = docSnap.data();
                const userId = rollData.rollerId;
                const userGuildDocRef = doc(db, 'userGuilds', `${userId}_${guildId}`);
                const userGuildDocSnap = await getDoc(userGuildDocRef);
                const userData = userGuildDocSnap.exists() ? userGuildDocSnap.data() : null;

                reasons.push({
                    inGameName: userData?.inGameName || rollData.rollerName || 'Unknown',
                    value: rollData.roll,
                    reason: rollData.reason || 'No reason provided',
                    isWinner: reasons.length === 0,
                });
            }
        } else if (lootMethod === 'dkp') {
            const bidsRef = collection(db, "sessions", sessionId, "lootDrops", lootId, "bids");
            const q = query(bidsRef, orderBy("bidAmount", "desc"));
            const bidsSnapshot = await getDocs(q);
            for (const docSnap of bidsSnapshot.docs) {
                const bidData = docSnap.data();
                const userId = bidData.bidderId;
                const userGuildDocRef = doc(db, 'userGuilds', `${userId}_${guildId}`);
                const userGuildDocSnap = await getDoc(userGuildDocRef);
                const userData = userGuildDocSnap.exists() ? userGuildDocSnap.data() : null;

                reasons.push({
                    inGameName: userData?.inGameName || bidData.bidderName || 'Unknown',
                    value: bidData.bidAmount,
                    reason: bidData.reason || 'No reason provided',
                    isWinner: reasons.length === 0,
                });
            }
        }

        setSelectedReasons(reasons);
        setSelectedLootMethod(lootMethod);
        setReasonModalOpen(true);
    };

    const closeReasonModal = () => {
        setSelectedReasons([]);
        setSelectedLootMethod('');
        setReasonModalOpen(false);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (lootDrops.length === 0) {
        return (
            <Typography sx={{ textAlign: 'center', mt: 2 }}>
                No loot items added yet.
            </Typography>
        );
    }

    const sortedLootDrops = [...lootDrops].sort((a, b) => {
        const dateA = a.createdAt ? (a.createdAt instanceof Timestamp ? a.createdAt.toDate() : a.createdAt) : new Date(0);
        const dateB = b.createdAt ? (b.createdAt instanceof Timestamp ? b.createdAt.toDate() : b.createdAt) : new Date(0);
        return dateB.getTime() - dateA.getTime();
    });

    return (
        <Box sx={{ bgcolor: theme.palette.background.paper, borderRadius: 2, p: 4, boxShadow: 3 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>
                Loot Drops
            </Typography>

            {/* Desktop View */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Box component="table" sx={{ width: '100%', borderCollapse: 'collapse' }}>
                    <Box component="thead">
                        <Box component="tr" sx={{ bgcolor: theme.palette.grey[200] }}>
                            <Box component="th" sx={{ p: 2, fontWeight: 'bold', borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                Actions
                            </Box>
                            <Box component="th" sx={{ p: 2, fontWeight: 'bold', borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                Item
                            </Box>
                            <Box component="th" sx={{ p: 2, fontWeight: 'bold', borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                Status
                            </Box>
                            <Box component="th" sx={{ p: 2, fontWeight: 'bold', borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                Current Winner
                            </Box>
                            <Box component="th" sx={{ p: 2, fontWeight: 'bold', borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                Traits
                            </Box>
                            <Box component="th" sx={{ p: 2, fontWeight: 'bold', borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                Reasons
                            </Box>
                        </Box>
                    </Box>

                    <Box component="tbody">
                        {sortedLootDrops.map((loot, index) => (
                            <React.Fragment key={index}>
                                <Box component="tr" sx={{ '&:nth-of-type(even)': { bgcolor: theme.palette.grey[100] } }}>
                                    {/* Actions Column */}
                                    <Box component="td" sx={{ p: 2, borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                        {loot.lootMethod === "roll" ? (
                                            <>
                                                <Button onClick={() => onToggleHistory(loot.id)}>
                                                    {historyVisible[loot.id] ? 'Hide Roll History' : 'Show Roll History'}
                                                </Button>
                                                <Button onClick={() => onRoll({
                                                    lootId: loot.id,
                                                    user,
                                                    sessionId,
                                                    session,
                                                    setConfirmationTitle: () => { },
                                                    setConfirmationMessage: () => { },
                                                    setIsError: () => { },
                                                    setConfirmationModalVisible: () => { },
                                                    setSuccess: () => { },
                                                    setUserRolls: () => { },
                                                    selectedGuild: guildId
                                                })}>
                                                    Roll
                                                </Button>
                                                {isLootmaster && (
                                                    <>
                                                        <Button onClick={() => onDistribute(loot.id)}>Distribute</Button>
                                                        <Button onClick={() => onRemove(loot.id)}>Remove</Button>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Button onClick={() => onToggleBidHistory(loot.id)}>
                                                    {bidHistoryVisible[loot.id] ? 'Hide Bid History' : 'Show Bid History'}
                                                </Button>
                                                <Button onClick={() => onBid(loot.id)}>
                                                    Bid
                                                </Button>
                                                {isLootmaster && (
                                                    <>
                                                        <Button onClick={() => onDistribute(loot.id)}>Distribute</Button>
                                                        <Button onClick={() => onRemove(loot.id)}>Remove</Button>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Box>

                                    {/* Item Column */}
                                    <Box component="td" sx={{ p: 2, borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <Box
                                                component="img"
                                                src={loot.imageUrl || '/images/placeholder.png'}
                                                alt={loot.itemName}
                                                sx={{ width: 40, height: 40, borderRadius: 1 }}
                                            />
                                            <a href={itemsDataNew.find(item => item.name === loot.itemName)?.url || '#'}>
                                                {loot.itemName}
                                            </a>
                                        </Box>
                                    </Box>

                                    {/* Status Column */}
                                    <Box component="td" sx={{ p: 2, borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                        {loot.status}
                                    </Box>

                                    {/* Current Winner Column */}
                                    <Box component="td" sx={{ p: 2, borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                        {loot.lootMethod === 'roll' || isLootmaster ? (
                                            currentWinners[loot.id] ? (
                                                `${currentWinners[loot.id].inGameName} (${currentWinners[loot.id].value}${loot.lootMethod === 'dkp' ? ' DKP' : ''})`
                                            ) : (
                                                loot.lootMethod === 'roll' ? 'No rolls yet' : 'No bids yet'
                                            )
                                        ) : (
                                            loot.isPrivateBidding ? 'Hidden (Private Bidding)' : (
                                                currentWinners[loot.id] ? (
                                                    `${currentWinners[loot.id].inGameName} (${currentWinners[loot.id].value} DKP)`
                                                ) : 'No bids yet'
                                            )
                                        )}
                                    </Box>

                                    {/* Traits Column */}
                                    <Box component="td" sx={{ p: 2, borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                        {loot.traits ? loot.traits.join(', ') : 'N/A'}
                                    </Box>

                                    {/* Reasons Column */}
                                    <Box component="td" sx={{ p: 2, borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                        {(isLootmaster || !loot.isPrivateBidding) && (
                                            <Button onClick={() => openReasonModal(loot.id, loot.lootMethod)}>
                                                View Reasons
                                            </Button>
                                        )}
                                    </Box>
                                </Box>

                                {/* History row */}
                                {((loot.lootMethod === "roll" && historyVisible[loot.id]) ||
                                    (loot.lootMethod === "dkp" && bidHistoryVisible[loot.id])) && (
                                        <Box component="tr">
                                            <Box
                                                component="td"
                                                colSpan={6}
                                                sx={{
                                                    p: 2,
                                                    borderBottom: '1px solid',
                                                    borderColor: theme.palette.divider,
                                                    backgroundColor: theme.palette.background.default
                                                }}
                                            >
                                                {loot.lootMethod === "roll" ? (
                                                    <RollingHistory
                                                        sessionId={sessionId}
                                                        lootId={loot.id}
                                                    />
                                                ) : (
                                                    <BiddingHistory
                                                        sessionId={sessionId}
                                                        lootId={loot.id}
                                                        isPrivate={loot.isPrivateBidding ?? false}
                                                        isLootmaster={isLootmaster}
                                                        user={user}
                                                        aggregateData={aggregateData}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>
            </Box>

            {/* Mobile View */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Stack spacing={2}>
                    {sortedLootDrops.map((loot, index) => (
                        <Card key={index} sx={{ p: 2 }}>
                            <Stack spacing={2}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Avatar
                                        src={loot.imageUrl || '/images/placeholder.png'}
                                        alt={loot.itemName}
                                        sx={{ width: 40, height: 40 }}
                                    />
                                    <a href={itemsDataNew.find(item => item.name === loot.itemName)?.url || '#'}>
                                        {loot.itemName}
                                    </a>
                                </Box>


                                <Stack spacing={1}>
                                    <Typography
                                        sx={{
                                            color: loot.status === 'distributed' ? theme.palette.success.main :
                                                loot.status === 'open' ? theme.palette.info.main :
                                                    theme.palette.text.primary,
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <strong>Status:</strong> {loot.status}
                                    </Typography>

                                    <Typography><strong>Traits:</strong> {loot.traits?.join(', ') || 'N/A'}</Typography>
                                    {(loot.lootMethod === 'roll' || isLootmaster || !loot.isPrivateBidding) && (
                                        <Typography>
                                            <strong>Current Winner:</strong> {
                                                currentWinners[loot.id] ? (
                                                    `${currentWinners[loot.id].inGameName} (${currentWinners[loot.id].value}${loot.lootMethod === 'dkp' ? ' DKP' : ''})`
                                                ) : (
                                                    loot.lootMethod === 'roll' ? 'No rolls yet' : 'No bids yet'
                                                )
                                            }
                                        </Typography>
                                    )}
                                </Stack>

                                <Stack direction="row" flexWrap="wrap" gap={1}>
                                    {loot.lootMethod === "roll" ? (
                                        <>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => onToggleHistory(loot.id)}
                                            >
                                                {historyVisible[loot.id] ? 'Hide History' : 'Show History'}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => onRoll({
                                                    lootId: loot.id,
                                                    user,
                                                    sessionId,
                                                    setConfirmationTitle: () => { },
                                                    setConfirmationMessage: () => { },
                                                    setIsError: () => { },
                                                    setConfirmationModalVisible: () => { },
                                                    setSuccess: () => { },
                                                    setUserRolls: () => { },
                                                    selectedGuild: guildId,
                                                    session
                                                })}
                                            >
                                                Roll
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => onToggleBidHistory(loot.id)}
                                            >
                                                {bidHistoryVisible[loot.id] ? 'Hide History' : 'Show History'}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => onBid(loot.id)}
                                            >
                                                Bid
                                            </Button>
                                        </>
                                    )}
                                    {isLootmaster && (
                                        <>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => onDistribute(loot.id)}
                                            >
                                                Distribute
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                                onClick={() => onRemove(loot.id)}
                                            >
                                                Remove
                                            </Button>
                                        </>
                                    )}
                                    {(isLootmaster || !loot.isPrivateBidding) && (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => openReasonModal(loot.id, loot.lootMethod)}
                                        >
                                            View Reasons
                                        </Button>
                                    )}
                                </Stack>

                                {/* History Sections */}
                                {loot.lootMethod === "roll" && historyVisible[loot.id] && (
                                    <Box sx={{
                                        mt: 2,
                                        bgcolor: 'background.default',
                                        p: 2,
                                        borderRadius: 1,
                                        maxHeight: '300px',
                                        overflowY: 'auto'
                                    }}>
                                        <RollingHistory
                                            sessionId={sessionId}
                                            lootId={loot.id}
                                        />
                                    </Box>
                                )}

                                {loot.lootMethod === "dkp" && bidHistoryVisible[loot.id] && (
                                    <Box sx={{
                                        mt: 2,
                                        bgcolor: 'background.default',
                                        p: 2,
                                        borderRadius: 1,
                                        maxHeight: '300px',
                                        overflowY: 'auto'
                                    }}>
                                        <BiddingHistory
                                            sessionId={sessionId}
                                            lootId={loot.id}
                                            isPrivate={loot.isPrivateBidding ?? false}
                                            isLootmaster={isLootmaster}
                                            user={user}
                                            aggregateData={aggregateData}
                                        />
                                    </Box>
                                )}
                            </Stack>
                        </Card>
                    ))}
                </Stack>
            </Box>

            {/* Reason Modal */}
            <Modal open={reasonModalOpen} onClose={closeReasonModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxWidth: 500,
                    width: '90%',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Reasons</Typography>
                    {selectedReasons.map((entry, index) => (
                        <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid', borderColor: theme.palette.divider, borderRadius: 1, backgroundColor: entry.isWinner ? theme.palette.action.hover : 'transparent' }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: entry.isWinner ? 'bold' : 'normal' }}>
                                {entry.inGameName} {entry.isWinner ? '(Winner)' : ''}
                            </Typography>
                            <Typography variant="body2">
                                {selectedLootMethod === 'dkp' ? `Bid: ${entry.value} DKP` : `Roll: ${entry.value}`}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                {entry.reason}
                            </Typography>
                        </Box>
                    ))}
                    <Button variant="contained" onClick={closeReasonModal} sx={{ mt: 2 }}>Close</Button>
                </Box>
            </Modal>
        </Box>
    );
};
