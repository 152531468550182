import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    Button,
    Container,
    Box,
    Grid,
    useTheme,
    Modal,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const AboutThisApp: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const images = [
        '/images/adminlogs.png',
        '/images/dkpmanagement2.png',
        '/images/dkpmanagement1.png',
        '/images/dashboard_edit_profile.png',
        '/images/rollitem.png',
        '/images/placebid.png',
        '/images/bidadded.png',
        '/images/addbiditem.png',
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!modalOpen) {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [images.length, modalOpen]);

    const handleImageClick = (index: number) => {
        setSelectedImageIndex(index);
        setModalOpen(true);
    };

    const handleNext = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevious = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    // Handle keyboard navigation
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (!modalOpen) return;

            switch (event.key) {
                case 'ArrowLeft':
                    handlePrevious();
                    break;
                case 'ArrowRight':
                    handleNext();
                    break;
                case 'Escape':
                    setModalOpen(false);
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [modalOpen]);

    return (
        <>
            {/* Top Section */}
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: { xs: 500, sm: 400, md: 400 },
                    color: 'white',
                    textAlign: 'center',
                    flexDirection: 'column',
                    overflow: 'visible',
                    padding: { xs: 2, md: 0 },
                    zIndex: 10,
                }}
            >
                {/* Blurred Background Image */}
                <Box
                    sx={{
                        backgroundImage: 'url(/images/newsite/bkg.jpg)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        filter: 'blur(1px)',
                        zIndex: 1,
                    }}
                />

                {/* Dark Overlay */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 2,
                    }}
                />

                {/* Content with Side-by-Side Layout */}
                <Container sx={{ zIndex: 7, mt: { xs: 2, sm: 6, md: 8 } }}>
                    <Grid container justifyContent="center" spacing={4}>
                        {/* Slideshow Image Frame */}
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <Box
                                onClick={() => handleImageClick(currentImageIndex)}
                                sx={{
                                    width: '100%',
                                    height: { xs: 250, sm: 300, md: 400 },
                                    backgroundImage: `url(${images[currentImageIndex]})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    border: '2px solid #fff',
                                    borderRadius: 0,
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                                    zIndex: 10,
                                    position: 'relative',
                                    transition: 'background-image 1s ease-in-out',
                                    backgroundColor: 'grey',
                                    marginBottom: { xs: -10, sm: -30, md: -40 },
                                    cursor: 'pointer',
                                }}
                            />
                        </Grid>

                        {/* Overlay Content */}
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <Box
                                sx={{
                                    textAlign: { xs: 'center' }, // Control text alignment based on screen size
                                    mt: { xs: 10, sm: 0 },
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mb: 2,
                                        fontWeight: 'bold',
                                        fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                                    }}
                                >
                                    LOOT MANAGER
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 4,
                                        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.25rem' },
                                    }}
                                >
                                    Our app offers a comprehensive, easy-to-use solution for loot management.
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/login')}
                                    sx={{
                                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                                        padding: { xs: '6px 12px', sm: '8px 16px', md: '10px 20px' },
                                    }}
                                >
                                    Get Started
                                </Button>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>

            </Box>

            {/* Secondary Content Section */}
            <Container
                sx={{
                    maxWidth: 'md',
                    mt: { xs: 10, sm: 15, md: 30 },
                    p: { xs: 2, sm: 3 },
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 0,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                    textAlign: 'left',
                    zIndex: 5,
                    position: 'relative',
                }}
            >
                {/* Feature Grid */}
                <Grid container spacing={3}>
                    {[
                        { title: 'Easy Setup', description: 'Get started in minutes with straightforward registration and guild integration.' },
                        { title: 'Customizable', description: 'Tailor loot management to fit your guild’s needs with flexible DKP and roll-based options.' },
                        { title: 'Versatile', description: 'Supports multiple loot distribution methods, including DKP bidding and dice rolls (1-100).' },
                        { title: 'Mobile-Friendly', description: 'Seamlessly manage loot from your phone, ideal for console players.' },
                        { title: 'Powerful Tracking', description: 'Robust history tracking keeps a detailed record of bids, rolls, and loot distributions.' },
                        { title: 'Real-Time Updates', description: 'Experience smooth, live updates during sessions, keeping everyone informed.' },
                        { title: 'Reliable & Stable', description: 'Built for 24/7 reliability, with consistent uptime for uninterrupted access.' },
                        { title: 'User Dashboard', description: 'Intuitive web interface provides a comprehensive view of loot sessions and guild data.' },
                        { title: 'Dedicated Support', description: 'Friendly devs are available to help you get the most out of our app.' },
                    ].map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box
                                sx={{
                                    p: 3,
                                    backgroundColor: theme.palette.background.default,
                                    borderRadius: 0,
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                                    textAlign: 'center',
                                }}
                            >
                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    {item.title}
                                </Typography>
                                <Typography variant="body2">
                                    {item.description}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            {/* Add Modal at the end of your return statement */}
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'rgba(0, 0, 0, 0.9)',
                }}
            >
                <Box sx={{
                    position: 'relative',
                    maxWidth: '90vw',
                    maxHeight: '90vh',
                    outline: 'none',
                }}>
                    <IconButton
                        onClick={() => setModalOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: -40,
                            top: -40,
                            color: 'white',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <IconButton
                        onClick={handlePrevious}
                        sx={{
                            position: 'absolute',
                            left: -50,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: 'white',
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleNext}
                        sx={{
                            position: 'absolute',
                            right: -50,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: 'white',
                        }}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                    <img
                        src={images[selectedImageIndex]}
                        alt={`Screenshot ${selectedImageIndex + 1}`}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '90vh',
                            objectFit: 'contain',
                        }}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default AboutThisApp;
