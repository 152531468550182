import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import Select, { StylesConfig } from "react-select";
import { Container, Typography, Box, Button, TextField, useTheme } from "@mui/material";

interface OptionType {
  value: string;
  label: string;
}

const Login: React.FC = () => {
  const [guilds, setGuilds] = useState<string[]>([]);
  const [selectedGuild, setSelectedGuild] = useState<OptionType | null>(null);
  const [newGuildName, setNewGuildName] = useState<string>("");
  const [error, setError] = useState<string>(""); 
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); 
  const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(false); 
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false); 
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const fetchGuilds = async () => {
      try {
        const guildsCollection = collection(db, "guilds");
        const guildsSnapshot = await getDocs(guildsCollection);
        let guildList = guildsSnapshot.docs.map((doc) => doc.id);
        guildList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        setGuilds(guildList);
      } catch (error) {
        console.error("Login - Error fetching guilds:", error);
        setError("Failed to load guilds. Please try again later.");
      }
    };

    fetchGuilds();
  }, []);

  const handleDiscordLogin = () => {
    setError("");
    const guildInput = newGuildName.trim();
    const guild = guildInput ? guildInput : selectedGuild?.value;

    if (!guild) {
      setError("Please select a guild or enter a new guild name.");
      return;
    }

    const isNewGuild = !!guildInput;
    if (isNewGuild && guilds.some((existingGuild) => existingGuild.toLowerCase() === guild.toLowerCase())) {
      setError("Guild already exists. Please select an existing guild or choose a different name.");
      return;
    }

    setIsSubmitting(true);
    const state = encodeURIComponent(JSON.stringify({ guild, isNewGuild }));
    const clientId = "1297526766604124203";
    const redirectUri = process.env.REACT_APP_DISCORD_REDIRECT_URL;
    const scope = "identify email";

    if (!redirectUri) {
      console.error("REACT_APP_DISCORD_REDIRECT_URL is not defined");
      setError("An error occurred. Please try again later.");
      setIsSubmitting(false);
      return;
    }

    const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_type=code&scope=${encodeURIComponent(scope)}&state=${state}`;

    window.location.href = discordAuthUrl;
  };

  const guildOptions: OptionType[] = guilds.map((guild) => ({
    value: guild,
    label: guild,
  }));

  const customSelectStyles: StylesConfig<OptionType, false> = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: theme.palette.background.paper,
      borderColor: state.isFocused ? theme.palette.primary.main : "#555",
      boxShadow: state.isFocused ? `0 0 0 1px ${theme.palette.primary.main}` : "none",
      "&:hover": {
        borderColor: theme.palette.primary.main,
      },
      color: theme.palette.text.primary,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? theme.palette.primary.main
        : state.isSelected
        ? theme.palette.primary.main
        : theme.palette.background.default,
      color: state.isFocused || state.isSelected ? "#fff" : theme.palette.text.primary,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.palette.text.primary,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.palette.text.secondary,
    }),
  };

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        zIndex: 1,
        overflow: 'hidden', // Ensures the container covers the full viewport
      }}
    >
      {/* Background Image */}
      <Box
        sx={{
          backgroundImage: 'url(/images/newsite/bkg.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          filter: 'blur(1px)',
          zIndex: -1,
        }}
      />

      {/* Dark Overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darkened for better contrast
          zIndex: 0,
        }}
      />

      <Container maxWidth="sm" sx={{ mt: 8, zIndex: 1, color: '#fff', position: 'relative' }}>
        <Box
          sx={{
            p: 4,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
            textAlign: 'center',
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
            Select or Create Guild
          </Typography>
          <Box sx={{ mb: 3 }}>
            <Typography variant="body2" color="textSecondary">
              Choose from existing guilds or enter a new guild name.
            </Typography>
          </Box>

          <Select
            options={guildOptions}
            value={selectedGuild}
            onChange={(option) => {
              setSelectedGuild(option);
              setNewGuildName("");
              setError("");
              setIsInputDisabled(!!option);
            }}
            placeholder="Select a guild..."
            isClearable
            isDisabled={isSelectDisabled}
            styles={customSelectStyles}
          />
          <Box sx={{ my: 2, color: theme.palette.text.secondary }}>Or create a new guild:</Box>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Enter new guild name"
            value={newGuildName}
            onChange={(e) => {
              setNewGuildName(e.target.value);
              setSelectedGuild(null);
              setError("");
              setIsSelectDisabled(!!e.target.value.trim());
            }}
            disabled={isInputDisabled}
            InputProps={{ style: { color: theme.palette.text.primary } }}
            sx={{ mb: 2 }}
          />
          {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}

          <Button
            variant="contained"
            color="primary"
            onClick={handleDiscordLogin}
            disabled={isSubmitting}
            fullWidth
            sx={{
              mt: 2,
              fontWeight: 'bold',
              fontSize: '1rem',
              padding: '10px 20px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            }}
          >
            {isSubmitting ? "Processing..." : "Continue with Discord"}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
