// src/components/UserDetails.tsx

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../firebase';
import { signInWithCustomToken } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const UserDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authenticating, setAuthenticating] = useState<boolean>(true);
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get('token');

    if (tokenFromUrl) {
      //console.log("UserDetails - Token found in URL:", tokenFromUrl);
      setAuthenticating(true);
      signInWithCustomToken(auth, tokenFromUrl)
        .then(async () => {
          //console.log("UserDetails - Signed in with custom token");
          const currentUser = auth.currentUser;
          if (currentUser) {
            const userDocRef = doc(db, 'users', currentUser.uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
              setUserData(userDoc.data());
              //console.log("UserDetails - User data fetched:", userDoc.data());
            } else {
              console.warn("UserDetails - User document does not exist");
            }
          } else {
            console.warn("UserDetails - No authenticated user after sign-in");
          }
        })
        .catch((error) => {
          console.error("UserDetails - Error signing in with custom token:", error);
          alert("Authentication failed. Please try logging in again.");
          navigate('/login');
        })
        .finally(() => {
          setAuthenticating(false);
        });
    } else {
      //console.log("UserDetails - No token found in URL");
      setAuthenticating(false);
    }
  }, [location, navigate]);

  if (authenticating) {
    //console.log("UserDetails - Authenticating...");
    return <div>Authenticating...</div>;
  }

  if (!auth.currentUser) {
    //console.log("UserDetails - No authenticated user, redirecting to /login");
    navigate('/login');
    return null;
  }

  if (!userData) {
    //console.log("UserDetails - No user data available");
    return <div>Loading user data...</div>;
  }

  //console.log("UserDetails - Rendering user data:", userData);

  return (
    <div className="user-details">
      <h2>User Details</h2>
      <ul>
        <li>
          <strong>Username:</strong> {userData.username}
        </li>
        <li>
          <strong>Guild:</strong> {userData.guild}
        </li>
        <li>
          <strong>DKP:</strong> {userData.dkp}
        </li>
        <li>
          <strong>Role:</strong> {userData.role}
        </li>
        <li>
          <strong>Status:</strong> {userData.status}
        </li>
        <li>
          <strong>Created At:</strong>{' '}
          {userData.createdAt ? userData.createdAt.toDate().toString() : 'N/A'}
        </li>
      </ul>
      <button onClick={() => navigate('/dashboard')}>Go to Dashboard</button>
    </div>
  );
};

export default UserDetails;
