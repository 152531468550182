// src/components/Rejected.tsx

import React from 'react';

const Rejected: React.FC = () => {
  return (
    <div className="rejected-page">
      <h2>Account Rejected</h2>
      <p>Your account has been rejected. Please contact the administrator for more information.</p>
    </div>
  );
};

export default Rejected;
