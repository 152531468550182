import React from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SessionTimer from '../SessionTimer';

interface SessionInfoProps {
  session: any;
  selectedGuild: string;
  linkedEvent: any;
  isLootmaster: boolean;
  availableEvents: any[];
  onLinkEvent: (eventId: string) => void;
  onExpire: () => void;
}

export const SessionInfo: React.FC<SessionInfoProps> = ({
  session,
  selectedGuild,
  linkedEvent,
  isLootmaster,
  availableEvents,
  onLinkEvent,
  onExpire
}) => {
  const theme = useTheme();

  return (
    <Box sx={{
      bgcolor: theme.palette.background.paper,
      borderRadius: 2,
      p: { xs: 2, sm: 3, md: 4 },
      boxShadow: 3,
      mb: 3
    }}>
      {session && (
        <>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            Session: {session.name}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              mb: 2
            }}
          >
            Guild: {selectedGuild}
          </Typography>

          <SessionTimer
            expiresAt={session?.expiresAt || null}
            onExpire={onExpire}
          />

          {isLootmaster && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Link Event</InputLabel>
              <Select
                value={linkedEvent?.id || ''}
                label="Link Event"
                onChange={(event) => onLinkEvent(event.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {availableEvents.map((event) => (
                  <MenuItem key={event.id} value={event.id}>
                    {event.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {linkedEvent && (
            <Box sx={{ mt: 2, p: 2, bgcolor: theme.palette.grey[100], borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                Linked Event: {linkedEvent.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Participants: {linkedEvent.registeredUsers?.length || 0}/{linkedEvent.maxParticipants}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                DKP Value: {linkedEvent.dkpValue || 0}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
