import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface SessionHeaderProps {
  sessionName: string;
  onNavigateBack: () => void;
}

export const SessionHeader: React.FC<SessionHeaderProps> = ({ sessionName, onNavigateBack }) => {
  const theme = useTheme();
  
  return (
    <Box sx={{ position: 'relative', height: '200px', overflow: 'hidden' }}>
      <Box
        sx={{
          backgroundImage: 'url(/images/newsite/bkg.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          filter: 'blur(1px)',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 2,
        }}
      />
      <Container maxWidth="lg" sx={{ height: '100%', position: 'relative', zIndex: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 3 }}>
          <Typography variant="h4" sx={{ color: 'white', fontWeight: 700 }}>
            {sessionName || 'Session Detail'}
          </Typography>
          <Button
            variant="outlined"
            onClick={onNavigateBack}
            sx={{ color: 'white', borderColor: 'white' }}
          >
            Back to Sessions List
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
