import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Typography,
    Box
} from '@mui/material';
import { EventData } from '../events/Event';

interface EventSessionLinkProps {
    open: boolean;
    onClose: () => void;
    onSelectEvent: (eventId: string) => void;
    events: EventData[];
}

const EventSessionLink: React.FC<EventSessionLinkProps> = ({ open, onClose, onSelectEvent, events }) => {
    const activeEvents = events.filter(event => !event.dkpDistributed);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Link Session to Event</DialogTitle>
            <DialogContent>
                {activeEvents.length === 0 ? (
                    <Typography>No active events available</Typography>
                ) : (
                    <List>
                        {activeEvents.map((event) => (
                            <ListItem
                                key={event.id}
                                onClick={() => onSelectEvent(event.id)}
                                sx={{ cursor: 'pointer' }}
                            >
                                <ListItemText
                                    primary={event.title}
                                    secondary={
                                        <Box>
                                            <Typography variant="body2">
                                                Date: {new Date(event.startDate).toLocaleDateString()}
                                            </Typography>
                                            <Typography variant="body2">
                                                Participants: {event.registeredUsers.length}/{event.maxParticipants}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EventSessionLink;
