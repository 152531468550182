// src/hooks/useSessionFirestore.tsx

import { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  doc,
  onSnapshot,
  query,
} from "firebase/firestore";
import { LootDrop, Session } from "../interfaces";

interface UseSessionFirestoreResult {
  session: Session | null;
  participants: string[];
  lootDrops: LootDrop[];
  loading: {
    session: boolean;
    loot: boolean;
  };
}

const useSessionFirestore = (sessionId?: string): UseSessionFirestoreResult => {
  const [session, setSession] = useState<Session | null>(null);
  const [participants, setParticipants] = useState<string[]>([]);
  const [lootDrops, setLootDrops] = useState<LootDrop[]>([]);
  const [loading, setLoading] = useState({
    session: true,
    loot: true,
  });

  useEffect(() => {
    if (!sessionId) {
      setLoading({ session: false, loot: false });
      return;
    }

    const sessionRef = doc(db, "sessions", sessionId);
    const unsubscribeSession = onSnapshot(sessionRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data() as Session;
        setSession({
          ...data,
          id: docSnap.id,
        });
      } else {
        setSession(null);
      }
      setLoading((prev) => ({ ...prev, session: false }));
    });

    return () => unsubscribeSession();
  }, [sessionId]);

  useEffect(() => {
    if (!sessionId) {
      setLootDrops([]);
      setLoading((prev) => ({ ...prev, loot: false }));
      return;
    }

    const lootRef = collection(db, "sessions", sessionId, "lootDrops");
    const unsubscribeLoot = onSnapshot(lootRef, (snapshot) => {
      const lootList: LootDrop[] = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt?.toDate(),
      } as LootDrop));
      setLootDrops(lootList);
      setLoading((prev) => ({ ...prev, loot: false }));
    });

    return () => unsubscribeLoot();
  }, [sessionId]);

  useEffect(() => {
    if (!sessionId) return;

    const participantsRef = collection(db, "sessions", sessionId, "participants");
    const unsubscribeParticipants = onSnapshot(participantsRef, (snapshot) => {
      const participantsList = snapshot.docs.map(doc => doc.id);
      setParticipants(participantsList);
    });

    return () => unsubscribeParticipants();
  }, [sessionId]);

  return { session, participants, lootDrops, loading };
};

export default useSessionFirestore;
