import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    where,
    writeBatch,
    serverTimestamp,
    arrayUnion,
    updateDoc
} from "firebase/firestore";
import { db } from "../firebase";
import { HandleDistributeProps } from "../interfaces/handlers";
import { Bid, LootDrop, UserGuild } from "../interfaces";
import { LogActions, logAction } from "../services/logginServices";

// Helper functions
export async function determineRollWinner(sessionId: string, lootId: string, batch: any) {
    const rollsRef = collection(db, "sessions", sessionId, "lootDrops", lootId, "rolls");
    const rollsSnapshot = await getDocs(rollsRef);
    
    let highestRoll = -1;
    let winner = null;

    for (const doc of rollsSnapshot.docs) {
        const rollData = doc.data();
        if (rollData.roll > highestRoll) {
            highestRoll = rollData.roll;
            winner = {
                uid: rollData.rollerId,
                name: rollData.rollerName,
                method: `Roll: ${highestRoll}`
            };
        }
    }

    return winner;
}

export async function determineDKPWinner(sessionId: string, lootId: string, selectedGuild: string, batch: any) {
    const lootRef = doc(db, "sessions", sessionId, "lootDrops", lootId);
    const lootDoc = await getDoc(lootRef);
    
    if (!lootDoc.exists()) return null;
    
    const lootData = lootDoc.data() as LootDrop;
    
    if (!lootData.currentBidder || lootData.currentBid === 0) return null;
    
    return {
        uid: lootData.currentBidder,
        name: lootData.currentBidderName,
        method: `DKP: ${lootData.currentBid}`
    };
}



export const handleDistributeNew = async ({
    lootId,
    sessionId,
    selectedGuild,
    user,
    setConfirmationTitle,
    setConfirmationMessage,
    setIsError,
    setConfirmationModalVisible,
    setSuccess,
}: HandleDistributeProps): Promise<void> => {
    if (!sessionId || !selectedGuild) return;

    try {
        const lootRef = doc(db, "sessions", sessionId, "lootDrops", lootId);
        const lootDoc = await getDoc(lootRef);
        
        if (lootDoc.exists() && lootDoc.data().status === "distributed") {
            setConfirmationTitle("Error");
            setConfirmationMessage("This item has already been distributed.");
            setIsError(true);
            setConfirmationModalVisible(true);
            return;
        }

        const batch = writeBatch(db);
        const aggregateRef = doc(db, "sessions", sessionId, "aggregates", "distribution");
        const aggregateDoc = await getDoc(aggregateRef);

        if (!lootDoc.exists()) {
            setConfirmationTitle("Error");
            setConfirmationMessage("Loot item not found.");
            setIsError(true);
            setConfirmationModalVisible(true);
            return;
        }

        const lootData = lootDoc.data() as LootDrop;
        let winner;

        if (lootData.lootMethod === "roll") {
            winner = await determineRollWinner(sessionId, lootId, batch);
        } else if (lootData.lootMethod === "dkp") {
            winner = await determineDKPWinner(sessionId, lootId, selectedGuild, batch);
        }

        if (!winner) {
            setConfirmationTitle("Error");
            setConfirmationMessage("Could not determine a winner.");
            setIsError(true);
            setConfirmationModalVisible(true);
            return;
        }

        const winnerRef = doc(db, "userGuilds", `${winner.uid}_${selectedGuild}`);
        batch.update(winnerRef, {
            wonItems: arrayUnion(lootData.itemName)
        });

        if (!aggregateDoc.exists()) {
            batch.set(aggregateRef, {
                distributedItems: {
                    [lootId]: {
                        winnerId: winner.uid,
                        winnerName: winner.name,
                        method: winner.method,
                        timestamp: serverTimestamp()
                    }
                }
            });
        } else {
            batch.update(aggregateRef, {
                [`distributedItems.${lootId}`]: {
                    winnerId: winner.uid,
                    winnerName: winner.name,
                    method: winner.method,
                    timestamp: serverTimestamp()
                }
            });
        }

        const distributionMessage = `${lootData.itemName} distributed to ${winner.name} (${winner.method})`;
        await logAction({
            action: LogActions.ITEM_DISTRIBUTED,
            actor: user?.uid || "system",
            details: distributionMessage,
            sessionId,
            guild: selectedGuild
        });

        batch.update(lootRef, {
            status: "distributed",
            winnerName: winner.name,
            distributedAt: serverTimestamp()
        });

        await batch.commit();
        setSuccess(distributionMessage);
        setConfirmationMessage(distributionMessage);

    } catch (error: any) {
        setConfirmationTitle("Error");
        setConfirmationMessage(error.message);
        setIsError(true);
        setConfirmationModalVisible(true);
    }
};

