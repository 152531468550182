import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Stack,
  Divider
} from '@mui/material';

interface BidModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (bidAmount: number, reason?: string) => void;
  requireReason?: boolean;
}

const BidModal: React.FC<BidModalProps> = ({ open, onClose, onConfirm, requireReason = false }) => {
  const [bidAmount, setBidAmount] = useState<number>(0);
  const [bidReason, setBidReason] = useState<string>('');

  const handleConfirm = () => {
    if (bidAmount > 0 && (!requireReason || bidReason.trim())) {
      onConfirm(bidAmount, bidReason);
      setBidAmount(0);
      setBidReason('');
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        maxWidth: 500,
        width: '90%'
      }}>
        <Stack spacing={3}>
          <Typography variant="h6">
            Enter Bid Amount
          </Typography>

          <Divider />

          <TextField
            fullWidth
            type="number"
            label="Bid Amount"
            value={bidAmount}
            onChange={(e) => setBidAmount(Number(e.target.value))}
            InputProps={{ inputProps: { min: 1 } }}
          />

          {requireReason && (
            <TextField
              required
              fullWidth
              label="Bid Reason"
              value={bidReason}
              onChange={(e) => setBidReason(e.target.value)}
              placeholder="Enter your reason for bidding..."
              multiline
              rows={2}
            />
          )}

          <Button
            variant="contained"
            fullWidth
            disabled={bidAmount <= 0 || (requireReason && !bidReason.trim())}
            onClick={handleConfirm}
          >
            Confirm Bid
          </Button>

          <Button
            variant="outlined"
            fullWidth
            onClick={onClose}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default BidModal;
