import React, { useEffect, useState } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { Bid, LootDrop, Session } from "../interfaces";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Alert,
} from "@mui/material";

interface BiddingHistoryProps {
  sessionId: string;
  lootId: string;
  isPrivate: boolean;
  isLootmaster: boolean;
  user: any;
  aggregateData: {
    currentBids: Record<string, number>;
    bidCounts: Record<string, number>;
  };
}

const BiddingHistory: React.FC<BiddingHistoryProps> = ({
  sessionId,
  lootId,
  isPrivate,
  isLootmaster,
  user,
  aggregateData,
}) => {
  const [bids, setBids] = useState<Bid[]>([]);
  const [userInfo, setUserInfo] = useState<Record<string, any>>({});
  const [guildId, setGuildId] = useState<string>("");
  const [lootData, setLootData] = useState<LootDrop | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Fetch guildId from session
  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const sessionDocRef = doc(db, "sessions", sessionId);
        const sessionDocSnap = await getDoc(sessionDocRef);
        if (sessionDocSnap.exists()) {
          const sessionData = sessionDocSnap.data() as Session;
          setGuildId(sessionData.guild);
        } else {
          setError("Session does not exist.");
        }
      } catch (err) {
        setError("Failed to fetch session data.");
      }
    };
    if (sessionId) {
      fetchSessionData();
    }
  }, [sessionId]);

  useEffect(() => {
    if (!sessionId || !lootId) return;

    const lootRef = doc(db, "sessions", sessionId, "lootDrops", lootId);
    const lootUnsubscribe = onSnapshot(lootRef, (snapshot) => {
      if (snapshot.exists()) {
        setLootData(snapshot.data() as LootDrop);
      } else {
        setError("Loot item does not exist.");
      }
    });

    const bidsRef = collection(
      db,
      "sessions",
      sessionId,
      "lootDrops",
      lootId,
      "bids"
    );
    const q = query(bidsRef, orderBy("timestamp", "desc"));

    const bidsUnsubscribe = onSnapshot(q, async (snapshot) => {
      const bidList: Bid[] = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          bidderId: data.bidderId,
          bidderName: data.bidderName,
          bidAmount: data.amount,
          createdAt: data.timestamp?.toDate() || new Date(),
          timestamp: data.timestamp,
        };
      });
      setBids(bidList);

      const bidderIds = Array.from(
        new Set(bidList.map((bid) => bid.bidderId).filter((id) => id))
      );

      // Fetch user data
      const userInfoPromises = bidderIds.map(async (uid) => {
        let userData = null;

        // Try fetching from userGuilds
        if (guildId) {
          const userGuildDocRef = doc(db, "userGuilds", `${uid}_${guildId}`);
          const userGuildDocSnap = await getDoc(userGuildDocRef);
          if (userGuildDocSnap.exists()) {
            const data = userGuildDocSnap.data();
            userData = {
              username: data.username,
              inGameName: data.inGameName || "N/A",
              primaryWeapon: data.primaryWeapon || "N/A",
              secondaryWeapon: data.secondaryWeapon || "N/A",
              gearScore: data.gearScore || 0,
              inGameRole: data.inGameRole || [],
            };
          }
        }

        // Fallback to users collection if not found in userGuilds
        if (!userData) {
          const userDocRef = doc(db, "users", uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const data = userDocSnap.data();
            userData = {
              username: data.username || "Unknown",
              inGameName: data.inGameName || "N/A",
              primaryWeapon: "N/A",
              secondaryWeapon: "N/A",
              gearScore: 0,
              inGameRole: [],
            };
          }
        }

        return {
          uid,
          data: userData,
        };
      });

      const userInfoArray = await Promise.all(userInfoPromises);

      const newUserInfo: Record<string, any> = {};
      userInfoArray.forEach(({ uid, data }) => {
        if (data) {
          newUserInfo[uid] = data;
        }
      });

      setUserInfo(newUserInfo);
    });

    return () => {
      lootUnsubscribe();
      bidsUnsubscribe();
    };
  }, [sessionId, lootId, guildId]);

  const canSeeBids =
    !isPrivate || isLootmaster || user?.role === "admin" || false;

  if (!canSeeBids) {
    const currentBid = aggregateData.currentBids[lootId] || 0;
    const isHighestBidder = lootData?.currentBidder === user?.uid;
    return (
      <div className="bid-info-container">
        <div className="bid-info">
          {lootData?.minIncrement && (
            <p className="min-increment-notice">
              Minimum bid increment: {lootData.minIncrement} DKP
            </p>
          )}
          <p className="private-bids">
            Bidding is private. Only the lootmaster and admins can see the bids.
            {currentBid > 0 && (
              <>
                <br />
                The highest bid on the item is {currentBid} DKP
                {isHighestBidder ? ", and you are the highest bidder." : "."}
                {!isHighestBidder && (
                  <>
                    <br />
                    Next minimum bid required:{" "}
                    {currentBid + (lootData?.minIncrement || 1)} DKP
                  </>
                )}
              </>
            )}
          </p>
        </div>
      </div>
    );
  }

  return (
    <Box className="container" p={3}>
      <Typography variant="h4" gutterBottom>
        Bidding History
      </Typography>

      {lootData?.minIncrement && (
        <Typography variant="body1" color="textSecondary" mb={2}>
          Minimum bid increment: {lootData.minIncrement} DKP
          {bids.length > 0 && (
            <span>
              {" "}
              (Next minimum bid: {bids[0].bidAmount + lootData.minIncrement} DKP)
            </span>
          )}
        </Typography>
      )}

      {bids.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          No bids placed yet.
        </Typography>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Bidder</strong>
                </TableCell>
                <TableCell>
                  <strong>In-Game Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Weapons</strong>
                </TableCell>
                <TableCell>
                  <strong>Roles</strong>
                </TableCell>
                <TableCell>
                  <strong>Gear Score</strong>
                </TableCell>
                <TableCell>
                  <strong>Bid Amount</strong>
                </TableCell>
                <TableCell>
                  <strong>Time</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bids.map((bid) => {
                const user = userInfo[bid.bidderId];
                return (
                  <TableRow key={bid.id}>
                    <TableCell>
                      {user?.username || bid.bidderName || "Unknown User"}
                    </TableCell>
                    <TableCell>{user?.inGameName || "N/A"}</TableCell>
                    <TableCell>
                      {user
                        ? `${user.primaryWeapon} / ${user.secondaryWeapon}`
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {user?.inGameRole?.join(", ") || "N/A"}
                    </TableCell>
                    <TableCell>{user?.gearScore || 0}</TableCell>
                    <TableCell>{bid.bidAmount} DKP</TableCell>
                    <TableCell>
                      {bid.createdAt ? bid.createdAt.toLocaleString() : "N/A"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default BiddingHistory;
