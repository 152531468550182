// verifyWishlistStatus.ts
import { getDoc, doc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { Box, Container, Typography, Button, Grid, Paper } from '@mui/material';
import useUser from '../../hooks/useUser';


interface WishlistItem {
  itemName: string;
  addedAt: {
    toDate: () => Date;
  };
}

export const verifyWishlistStatus = async (
  userId: string,
  itemName: string,
  session: any,
  wishlistVerification?: {
    method: "none" | "session" | "custom";
    hours?: number;
  }
): Promise<{ isEligible: boolean; message?: string }> => {
  console.log("Starting wishlist verification for:", {
    userId,
    itemName,
    verification: wishlistVerification,
  });

  // Get the guild ID from the session
  const guildId = session?.guild;
  
  if (!guildId) {
    return {
      isEligible: false,
      message: "Guild ID is missing from session, unable to verify wishlist.",
    };
  }

  // Fetch user's wishlist from the correct collection
  const userGuildRef = doc(db, "userGuilds", `${userId}_${guildId}`);
  const userGuildDoc = await getDoc(userGuildRef);

  if (!userGuildDoc.exists()) {
    console.log("User guild document not found");
    return {
      isEligible: false,
      message: "You do not have a wishlist for this guild.",
    };
  }

  const userData = userGuildDoc.data();
  const wishlist = userData.wishlist || [];

  // Find the wishlist item
  const wishlistItem = wishlist.find(
    (item: WishlistItem) => item.itemName === itemName
  );

  if (!wishlistItem) {
    console.log("Item not found in wishlist");
    return {
      isEligible: false,
      message: "This item is not in your wishlist.",
    };
  }

  const wishlistTimestamp = wishlistItem.addedAt?.toDate();

  // Handle cases where addedAt might be missing
  if (!wishlistTimestamp) {
    console.log("No timestamp found for wishlist item");
    if (wishlistVerification?.method === "none") {
      return { isEligible: true }; // No additional verification needed
    } else {
      return {
        isEligible: false,
        message: "Timestamp for wishlist item is missing, unable to verify eligibility.",
      };
    }
  }

  let isValid = false;
  let message = "Eligibility verified based on wishlist settings.";

  switch (wishlistVerification?.method) {
    case "session":
      const sessionStartTime = session?.createdAt?.toDate();
      if (sessionStartTime) {
        isValid = wishlistTimestamp < sessionStartTime;
        if (!isValid) {
          message = "Only wishlisted items added before the session start are eligible.";
        }
      } else {
        message = "Session start time is missing, unable to verify wishlist eligibility.";
      }
      break;
    case "custom":
      const cutoffTime = new Date();
      cutoffTime.setHours(
        cutoffTime.getHours() - (wishlistVerification.hours || 24)
      );
      isValid = wishlistTimestamp < cutoffTime;
      if (!isValid) {
        message = `Only wishlisted items added before ${wishlistVerification.hours || 24} hours are eligible.`;
      }
      break;
    default:
      isValid = true;
  }

  console.log("Verification result:", {
    method: wishlistVerification?.method,
    isValid,
    timestamp: wishlistTimestamp,
  });

  return {
    isEligible: isValid,
    message: isValid ? "You are eligible for this item." : message,
  };
};

export const fetchTopWishlistedItems = async (guildId: string) => {
  const userGuildsRef = collection(db, 'userGuilds');
  const guildUsersQuery = query(userGuildsRef, where('guildId', '==', guildId));
  const querySnapshot = await getDocs(guildUsersQuery);
  
  const itemCounts: { [key: string]: number } = {};
  
  querySnapshot.forEach((doc) => {
    const wishlist = doc.data().wishlist || [];
    wishlist.forEach((item: { itemName: string }) => {
      itemCounts[item.itemName] = (itemCounts[item.itemName] || 0) + 1;
    });
  });

  const sortedItems = Object.entries(itemCounts)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 10)
    .map(([itemName, count]) => ({ itemName, count }));

  return sortedItems;
};
