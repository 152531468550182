import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

export const isSessionExpired = async (sessionId: string): Promise<boolean> => {
    try {
        const sessionRef = doc(db, 'sessions', sessionId);
        const sessionDoc = await getDoc(sessionRef);
        const sessionData = sessionDoc.data();
        const expiresAt = sessionData?.expiresAt?.toDate();
        const now = new Date();

        return expiresAt ? now > expiresAt : false;
    } catch (error) {
        console.error('Error checking session expiration:', error);
        return true;
    }
};
