import React, { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  User as FirebaseUser,
} from "firebase/auth";
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
  DocumentData,
  Unsubscribe,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { UserProfile, UserGuild } from "../interfaces";

interface UserContextProps {
  user: UserProfile | null;
  loading: boolean;
  selectedGuild: string | null;
  setSelectedGuild: (guildId: string) => void;
}

const UserContext = createContext<UserContextProps>({
  user: null,
  loading: true,
  selectedGuild: null,
  setSelectedGuild: () => {},
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedGuild, setSelectedGuild] = useState<string | null>(null);

  useEffect(() => {
    let unsubscribeFromAuth: () => void;
    let unsubscribeFromUserGuild: Unsubscribe | null = null;

    const handleAuthChange = async (firebaseUser: FirebaseUser | null) => {
      if (firebaseUser) {
        try {
          // Fetch only approved memberships for the user
          const userGuildsRef = collection(db, "userGuilds");
          const q = query(
            userGuildsRef,
            where("uid", "==", firebaseUser.uid),
            where("status", "==", "approved")
          );
          const snapshot = await getDocs(q);
          const memberships: UserGuild[] = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              uid: data.uid,
              guildId: data.guildId,
              role: data.role,
              status: data.status,
              username: data.username || "Unknown",
              inGameName: data.inGameName || "",
              dkp: data.dkp || 0,
              gearScore: data.gearScore !== undefined ? data.gearScore : 0,
              primaryWeapon: data.primaryWeapon || "N/A",
              secondaryWeapon: data.secondaryWeapon || "N/A",
              timestamp: data.timestamp ? data.timestamp.toDate() : new Date(),
            };
          });
    
          // Determine primary guild (first approved membership)
          const primaryGuild =
            memberships.length > 0 ? memberships[0].guildId : null;
    
          // Load selected guild from localStorage if exists and is approved
          const storedSelectedGuild = localStorage.getItem("selectedGuild");
          const isStoredGuildApproved = memberships.some(
            (m) => m.guildId === storedSelectedGuild
          );
          const initialSelectedGuild = isStoredGuildApproved
            ? storedSelectedGuild
            : primaryGuild;
    
          // Set selected guild and user profile
          setSelectedGuild(initialSelectedGuild);
    
          const currentSelectedGuild = initialSelectedGuild;
          const currentMembership = memberships.find(
            (m) => m.guildId === currentSelectedGuild
          );
    
          setUser({
            id: firebaseUser.uid,
            uid: firebaseUser.uid,
            username: firebaseUser.displayName || "Unknown",
            inGameName: currentMembership?.inGameName || "",
            dkp: currentMembership?.dkp || 0,
            role: currentMembership?.role || "member",
            status: currentMembership?.status || "approved",
            memberships,
            guild: currentSelectedGuild,
            primaryWeapon: currentMembership?.primaryWeapon || "N/A",
            secondaryWeapon: currentMembership?.secondaryWeapon || "N/A",
            gearScore: currentMembership?.gearScore || 0,
          });
    
          // Set up real-time listener for the selected guild's membership
          if (currentSelectedGuild && firebaseUser.uid) {
            const userGuildDocRef = doc(
              db,
              "userGuilds",
              `${firebaseUser.uid}_${currentSelectedGuild}`
            );
    
            unsubscribeFromUserGuild = onSnapshot(
              userGuildDocRef,
              (docSnapshot) => {
                if (docSnapshot.exists()) {
                  const data = docSnapshot.data() as UserGuild;
    
                  // Update the memberships array
                  const updatedMemberships = memberships.map((m) =>
                    m.guildId === currentSelectedGuild ? { ...m, ...data } : m
                  );
    
                  // Update user state
                  setUser((prevUser) =>
                    prevUser
                      ? {
                          ...prevUser,
                          role: data.role || "member",
                          status: data.status || "approved",
                          dkp: data.dkp || 0,
                          inGameName: data.inGameName || "",
                          primaryWeapon: data.primaryWeapon || "N/A",
                          secondaryWeapon: data.secondaryWeapon || "N/A",
                          gearScore:
                            data.gearScore !== undefined ? data.gearScore : 0,
                          guild: currentSelectedGuild,
                          memberships: updatedMemberships,
                        }
                      : null
                  );
                } else {
                  console.error("UserGuild document does not exist.");
                  // Handle the case where the document doesn't exist
                }
              }
            );
          }
        } catch (error) {
          console.error("Error fetching user guild memberships:", error);
        }
      } else {
        // User is signed out
        setUser(null);
        setSelectedGuild(null);
    
        // Unsubscribe from any userGuild listener
        if (unsubscribeFromUserGuild) {
          unsubscribeFromUserGuild();
        }
      }
      setLoading(false);
    };
    

    unsubscribeFromAuth = onAuthStateChanged(auth, handleAuthChange);

    return () => {
      // Clean up the listeners when the component unmounts
      if (unsubscribeFromAuth) {
        unsubscribeFromAuth();
      }
      if (unsubscribeFromUserGuild) {
        unsubscribeFromUserGuild();
      }
    };
  }, []);

  // Update user role and status when selectedGuild changes
  useEffect(() => {
    let unsubscribeFromUserGuild: Unsubscribe | null = null;

    if (user && selectedGuild) {
      const currentMembership = user.memberships.find(
        (m) => m.guildId === selectedGuild
      );

      // Update user state with new guild information
      setUser((prevUser) =>
        prevUser
          ? {
              ...prevUser,
              role: currentMembership?.role || "member",
              status: currentMembership?.status || "pending",
              dkp: currentMembership?.dkp || 0,
              inGameName: currentMembership?.inGameName || "",
              guild: selectedGuild,
              primaryWeapon: currentMembership?.primaryWeapon || "N/A",
              secondaryWeapon: currentMembership?.secondaryWeapon || "N/A",
              gearScore: currentMembership?.gearScore || 0,
            }
          : null
      );

      // Set up real-time listener for the user's data in the selected guild
      const userGuildDocRef = doc(
        db,
        "userGuilds",
        `${user.uid}_${selectedGuild}`
      );

      unsubscribeFromUserGuild = onSnapshot(
        userGuildDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data() as UserGuild;

            // Update the memberships array
            const updatedMemberships = user.memberships.map((m) =>
              m.guildId === selectedGuild ? { ...m, ...data } : m
            );

            // Update user state
            setUser((prevUser) =>
              prevUser
                ? {
                    ...prevUser,
                    role: data.role || "member",
                    status: data.status || "pending",
                    dkp: data.dkp || 0,
                    inGameName: data.inGameName || "",
                    primaryWeapon: data.primaryWeapon || "N/A",
                    secondaryWeapon: data.secondaryWeapon || "N/A",
                    gearScore:
                      data.gearScore !== undefined ? data.gearScore : 0,
                    guild: selectedGuild,
                    memberships: updatedMemberships,
                  }
                : null
            );
          } else {
            console.error("UserGuild document does not exist.");
            // Handle the case where the document doesn't exist
          }
        }
      );
    }

    return () => {
      // Clean up the listener when selectedGuild changes or component unmounts
      if (unsubscribeFromUserGuild) {
        unsubscribeFromUserGuild();
      }
    };
  }, [selectedGuild]);

  const handleSetSelectedGuild = (guildId: string) => {
    setSelectedGuild(guildId);
    localStorage.setItem("selectedGuild", guildId);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        selectedGuild,
        setSelectedGuild: handleSetSelectedGuild,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

export default useUser;
