import React from 'react';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, useTheme, Container, Grid, Card, CardContent, Tabs, Tab,
    CircularProgress, Divider
} from '@mui/material';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import useUser from '../hooks/useUser';


interface UserDKPData {
    uid: string;
    inGameName: string;
    dkp: number;
    username: string;
    primaryWeapon: string;
    gearScore: number;
    secondaryWeapon: string
}


interface WeaponStats {
    [key: string]: number;
}


interface WeaponCombo {
    primary: string;
    secondary: string;
    count: number;
    percentage: number;
    averageGearScore: number;
}


const UserDKPList: React.FC = () => {
    const theme = useTheme();
    const [userDKPList, setUserDKPList] = React.useState<UserDKPData[]>([]);
    const { selectedGuild } = useUser();
    const [tabValue, setTabValue] = React.useState(0);
    const [weaponStats, setWeaponStats] = React.useState<WeaponStats>({});
    const [averageGearScore, setAverageGearScore] = React.useState<number>(0);
    const [topWeaponCombos, setTopWeaponCombos] = React.useState<WeaponCombo[]>([]);
    const comboGearScores: Record<string, number[]> = {};


    React.useEffect(() => {
        const fetchUserDKP = async () => {
            if (!selectedGuild) return;
        
            const userGuildsRef = collection(db, 'userGuilds');
            const q = query(userGuildsRef, where('guildId', '==', selectedGuild));
            const querySnapshot = await getDocs(q);
        
            const dkpData: UserDKPData[] = [];
            const weaponCounts: WeaponStats = {};
            const comboCounts: Record<string, number> = {};
        
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                dkpData.push({
                    uid: data.uid,
                    inGameName: data.inGameName || 'N/A',
                    dkp: data.dkp || 0,
                    username: data.username || 'N/A',
                    primaryWeapon: data.primaryWeapon || 'N/A',
                    secondaryWeapon: data.secondaryWeapon || 'N/A',
                    gearScore: data.gearScore || 0
                });
        
                if (data.primaryWeapon) {
                    weaponCounts[data.primaryWeapon] = (weaponCounts[data.primaryWeapon] || 0) + 1;
                }
                if (data.secondaryWeapon) {
                    weaponCounts[data.secondaryWeapon] = (weaponCounts[data.secondaryWeapon] || 0) + 1;
                }
        
                if (data.primaryWeapon && data.secondaryWeapon) {
                    const comboKey = `${data.primaryWeapon} + ${data.secondaryWeapon}`;
                    comboCounts[comboKey] = (comboCounts[comboKey] || 0) + 1;
        
                    if (!comboGearScores[comboKey]) {
                        comboGearScores[comboKey] = [];
                    }
                    comboGearScores[comboKey].push(data.gearScore || 0);
                }
            });
        
            const totalWeapons = Object.values(weaponCounts).reduce((a, b) => a + b, 0);
            Object.keys(weaponCounts).forEach(weapon => {
                weaponCounts[weapon] = Number(((weaponCounts[weapon] / totalWeapons) * 100).toFixed(1));
            });
        
            const sortedCombos = Object.entries(comboCounts)
                .map(([combo, count]) => {
                    const gearScores = comboGearScores[combo] || [];
                    const validGearScores = gearScores.filter(score => score > 0);
                    const averageGearScore = validGearScores.length > 0
                        ? Math.round(validGearScores.reduce((a, b) => a + b, 0) / validGearScores.length)
                        : 0;
        
                    return {
                        primary: combo.split(' + ')[0],
                        secondary: combo.split(' + ')[1],
                        count,
                        percentage: Number(((count / dkpData.length) * 100).toFixed(1)),
                        averageGearScore
                    };
                })
                .sort((a, b) => b.count - a.count)
                .slice(0, 5);
        
            const validGearScores = dkpData.filter(user => user.gearScore > 0);
            const totalGearScore = validGearScores.reduce((sum, user) => sum + user.gearScore, 0);
            const averageGS = validGearScores.length > 0
                ? Math.round(totalGearScore / validGearScores.length)
                : 0;
        
            dkpData.sort((a, b) => b.dkp - a.dkp);
            setUserDKPList(dkpData);
            setWeaponStats(weaponCounts);
            setAverageGearScore(averageGS);
            setTopWeaponCombos(sortedCombos);
        };
        

        fetchUserDKP();

    }, [selectedGuild]);


    const renderGuildStats = () => (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Guild Statistics</Typography>


            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>Weapon Type Distribution</Typography>
                {Object.entries(weaponStats)
                    .sort(([, a], [, b]) => b - a)
                    .map(([weapon, percentage]) => (
                        <Box key={weapon} sx={{ mb: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2">{weapon}</Typography>
                                <Typography variant="body2">{percentage}%</Typography>
                            </Box>
                            <Box sx={{
                                width: '100%',
                                height: '4px',
                                bgcolor: theme.palette.grey[200],
                                borderRadius: '2px',
                            }}>
                                <Box sx={{
                                    width: `${percentage}%`,
                                    height: '100%',
                                    bgcolor: theme.palette.primary.main,
                                    borderRadius: '2px',
                                }} />
                            </Box>
                        </Box>
                    ))}
            </Box>


            <Divider sx={{ my: 2 }} />


            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>Popular Weapon Combinations</Typography>
                {topWeaponCombos.map((combo, index) => (
                    <Box key={index} sx={{
                        mb: 2,
                        p: 2,
                        bgcolor: theme.palette.grey[50],
                        borderRadius: 1,
                        border: `1px solid ${theme.palette.grey[200]}`
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography variant="body1" fontWeight="bold">
                                    #{index + 1} {combo.primary} + {combo.secondary}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {combo.count} members ({combo.percentage}%) • Average GS: {combo.averageGearScore}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>


            <Divider sx={{ my: 2 }} />


            <Box>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>Combat Statistics</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography>Average Gear Score:</Typography>
                    <Typography fontWeight="bold">{averageGearScore}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography>Total Members:</Typography>
                    <Typography fontWeight="bold">{userDKPList.length}</Typography>
                </Box>
            </Box>
        </Box>
    );


    const renderDKPRankings = () => (
        <TableContainer
            component={Paper}
            sx={{
                maxHeight: '400px',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.grey[300],
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: theme.palette.grey[100],
                }
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>Rank</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>In-Game Name</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Weapons</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }} align="right">Gear Score</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }} align="right">DKP</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userDKPList.map((user, index) => (
                        <TableRow
                            key={user.uid}
                            sx={{ '&:nth-of-type(odd)': { bgcolor: theme.palette.action.hover } }}
                        >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{user.inGameName}</TableCell>
                            <TableCell>
                                {user.primaryWeapon} {user.secondaryWeapon && `+ ${user.secondaryWeapon}`}
                            </TableCell>
                            <TableCell align="right">{user.gearScore}</TableCell>
                            <TableCell align="right">{user.dkp}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </TableContainer>
    );

    return (
        <Container maxWidth="lg" sx={{ mt: 3, mb: 4 }}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Card sx={{
                        bgcolor: theme.palette.background.paper,
                        color: theme.palette.text.primary
                    }}>
                        <CardContent>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                <Tabs
                                    value={tabValue}
                                    onChange={(_, newValue) => setTabValue(newValue)}
                                    textColor="primary"
                                    sx={{
                                        '& .MuiTab-root': { color: theme.palette.text.primary },
                                        '& .Mui-selected': { color: theme.palette.primary.main },
                                        '& .MuiTabs-indicator': { backgroundColor: theme.palette.primary.main }
                                    }}
                                >
                                    <Tab label="DKP Rankings" />
                                    <Tab label="Guild Statistics" />
                                </Tabs>
                            </Box>

                            {tabValue === 0 ? renderDKPRankings() : renderGuildStats()}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>

    );
};


export default UserDKPList;