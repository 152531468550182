import React from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { doc, deleteDoc } from "firebase/firestore";
import useUser from "../hooks/useUser";

const PendingApproval: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const handleCancelRegistration = async () => {
    try {
      console.log("Starting cancellation process");
      const currentUser = auth.currentUser;
      
      if (currentUser && user && user.memberships && user.memberships.length > 0) {
        const pendingMembership = user.memberships[0];
        const docId = `${currentUser.uid}_${pendingMembership.guildId}`;
        console.log("Attempting to delete document with ID:", docId);
        
        await deleteDoc(doc(db, "userGuilds", docId));
        console.log("Document deleted successfully");
        
        navigate("/login");
      }
    } catch (error) {
      console.error("Detailed error in cancellation:", error);
      alert("An error occurred while cancelling your registration.");
    }
  };

  return (
    <div className="pending-approval">
      <h2>Registration Pending Approval</h2>
      <p>
        Your registration is currently pending approval from your guild administrator. Please check back later.
      </p>
      <button onClick={handleCancelRegistration} className="cancel-button">
        Cancel Registration
      </button>
    </div>
  );
};

export default PendingApproval;
