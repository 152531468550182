import React from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Card,
    CardContent,
    Typography,
    Button,
    CircularProgress
} from '@mui/material';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';

const EventRegistration: React.FC = () => {
    const { eventId } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { user, selectedGuild } = useUser();
    const [verifying, setVerifying] = React.useState(true);
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        const verifyAndRegister = async () => {
            if (!eventId || !user || !selectedGuild) return;

            const code = searchParams.get('code');
            if (!code) {
                setError('Invalid registration link');
                setVerifying(false);
                return;
            }

            try {
                const eventRef = doc(db, 'events', selectedGuild, 'guildEvents', eventId);
                const eventDoc = await getDoc(eventRef);

                if (!eventDoc.exists()) {
                    setError('Event not found');
                    setVerifying(false);
                    return;
                }

                const eventData = eventDoc.data();
                if (eventData.participationCode !== code) {
                    setError('Invalid or expired registration code');
                    setVerifying(false);
                    return;
                }

                const now = new Date();
                const expiryTime = eventData.participationCodeExpiry.toDate();
                if (now > expiryTime) {
                    setError('Registration code has expired');
                    setVerifying(false);
                    return;
                }

                // Handle late registration if enabled
                if (!eventData.registeredUsers.includes(user.uid)) {
                    if (eventData.allowLateRegistration) {
                        await updateDoc(eventRef, {
                            registeredUsers: arrayUnion(user.uid),
                            verifiedParticipants: arrayUnion(user.uid)
                        });
                    } else {
                        setError('You are not registered for this event');
                        setVerifying(false);
                        return;
                    }
                } else {
                    // Just verify if already registered
                    await updateDoc(eventRef, {
                        verifiedParticipants: arrayUnion(user.uid)
                    });
                }

                // Redirect to event details page
                navigate(`/event/${eventId}`);
            } catch (error) {
                setError('Failed to verify registration');
                setVerifying(false);
            }
        };

        verifyAndRegister();
    }, [eventId, user, selectedGuild, searchParams, navigate]);

    if (verifying) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="sm" sx={{ mt: 4 }}>
            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom color="error">
                        {error}
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/events')}
                        fullWidth
                    >
                        Return to Events
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};

export default EventRegistration;
