import React, { useState } from 'react';
import { Container, Typography, Box, useTheme, Tabs, Tab, Paper, Chip, Fade } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';

interface NewsItem {
  id: number;
  date: string;
  title: string;
  content: string | JSX.Element;
  category: string;
}

interface GroupedNews {
  [key: number]: NewsItem[];
}

interface CategoryColors {
  [key: string]: string;
  'Major Feature': string;
  'Feature Release': string;
  'Core Feature': string;
  'Enhancement': string;
  'Security': string;
  'Update': string;
  'Full App Overhaul': string;
  'Feature Update': string;
  'Bot Update': string;
  'Major Update': string;
  'UI Enhancement': string;
  'Role Update': string;
}

const News: React.FC = () => {
  const theme = useTheme();
  const newsItems: NewsItem[] = [
    {
      id: -16,
      date: "November 12, 2024",
      title: "Comprehensive Wishlist System Launch",
      content: "Introducing the new Wishlist System, designed to enhance loot management and user experience. Users can now manage their wishlists in real-time, with the ability to add or remove items seamlessly. Admins have the power to lock or unlock user wishlists, ensuring fair play and adherence to guild rules. The system integrates with session management, allowing for pre-session wishlist verification and custom time requirements. Visual indicators show the status of items as Won or Not Yet Won, providing clear feedback. This update also includes improved search and filtering capabilities, ensuring users can quickly find and manage their desired items.",
      category: "Major Feature"
    },
    {
      id: -15,
      date: "November 11, 2024",
      title: "Event Management System with Analytics",
      content: "Introducing our comprehensive Event Management System with built-in analytics. Create and manage guild events while gaining valuable insights into your raid composition - track average Gear Score trends, popular weapon combinations, and class distribution metrics. Admin/Officers can now make data-driven decisions for raid groups, monitor progression through detailed statistics, and optimize team composition. The system provides DKP distribution and attendance tracking, while members can view upcoming events and register their participation.",
      category: "Major Feature"
    },
    {
      id: -14,
      date: "November 10, 2024",
      title: "Performance and UI Enhancements",
      content: "A comprehensive app update has been pushed live. Refresh the page with CTRL + SHIFT + R to access the latest improvements, including enhanced UI elements and performance optimizations.",
      category: "Full App Overhaul"
    },
    {
      id: -13,
      date: "November 9, 2024",
      title: "Channel Notification Setup for Bot",
      content: "A new /lmsetup command is in development to streamline channel notification configurations. This command will guide users through customizing notifications for specific channels, giving guild leaders better control over where and how updates appear in Discord.",
      category: "Feature Update"
    },
    {
      id: -12,
      date: "November 8, 2024",
      title: "Revamped Bot Interface",
      content: "The new bot UI is live, featuring all actions embedded within the interface. No more replies or direct messages; all actions and responses are contained within a single embed for a cleaner, consolidated experience.",
      category: "Bot Update"
    },
    {
      id: -11,
      date: "November 8, 2024",
      title: "Guild Invite Link Creation",
      content: "Admins can now invite members via a unique guild link available in the user management panel. Generate an invite link directly, which creates a URL like https://lootmanager.net/guild/Website20Test20Guild, simplifying member recruitment and guild management.",
      category: "Feature Update"
    },
    {
      id: -10,
      date: "November 7, 2024",
      title: "Dashboard Streamlining & Social Integration",
      content: "Removed Loot History and Recent Activity from the dashboard to prevent redundancy with the new Admin Logs page. Added \"X Social\" link to the navigation bar for easier access to updates. Improved the navigation bar's layout for small devices and added glitter effects to enhance visual appeal.",
      category: "Update"
    },
    {
      id: -9,
      date: "November 6, 2024",
      title: "Admin Log Page Release",
      content: "The Admin Log page is now live, centralizing all guild management actions in one place. View a complete history of admin actions, including user approvals, DKP adjustments, role updates, and session activity. Quick filtering options allow you to search logs by action, user, or date, making it easier than ever to track and manage guild activities. This release aims to improve oversight and transparency for guild leaders.",
      category: "Feature Release"
    },
    {
      id: -8,
      date: "November 5, 2024",
      title: "TLDB Integration & Session Overhaul",
      content: <>Major update integrating Throne and Liberty Database (<a href="https://tldb.info/" target="_blank" rel="noopener noreferrer" className="news-link">TLDB</a>) into sessions. Search and add items directly from TLDB's comprehensive collection of blue and purple gear. Quick access to item details with direct links to TLDB website. The session interface has been completely revamped with improved item management, smoother bidding controls, and enhanced visual feedback for a more intuitive user experience.</>,
      category: "Major Update"
    },
    {
      id: -7,
      date: "November 3, 2024",
      title: "Officer Role Enhancement",
      content: "Officers now have access to create and manage loot sessions. This update streamlines raid management by allowing officers to initiate sessions without admin intervention. Regular members retain full access to participate in sessions, making multi-raid coordination more efficient.",
      category: "Role Update"
    },
    {
      id: -6,
      date: "November 2, 2024",
      title: "Loot Table UI Improvements & Bid Controls",
      content: "Based on community feedback, I've enhanced the loot table interface. Item icons are now larger for better visibility, the status column has been optimized, and overlapping buttons have been resolved. Added minimum bid increment controls for lootmasters, and improved item trait selection with highlighted text.",
      category: "UI Enhancement"
    },
    {
      id: -5,
      date: "November 1, 2024",
      title: "Discord Bot Interface Update",
      content: "The Discord bot received a visual upgrade featuring highlighted item traits, improved bid history readability, and streamlined action buttons. The new interface focuses on clarity and ease of use.",
      category: "UI Enhancement"
    },
    {
      id: -3,
      date: "October 31, 2024",
      title: "Discord Bot 48-Hour Trial Available",
      content: "Experience the Discord bot with a 48-hour trial. Use /lm commands to manage raids, handle loot rolls, and run DKP bids directly in Discord. Real-time website synchronization included.",
      category: "Feature Release"
    },
    {
      id: -2,
      date: "October 30, 2024",
      title: "Private Bidding & Session Timers",
      content: "Introducing private bidding functionality for lootmasters to prevent bid sniping. Added automated session timers that handle loot distribution, reducing downtime during raids.",
      category: "Feature Release"
    },
    {
      id: -1,
      date: "October 28, 2024",
      title: "Discord Bot Integration",
      content: "The Discord bot is now available. Roll and bid directly through Discord using /lm commands, with real-time website synchronization. Access the bot through the Discord link above.",
      category: "Major Release"
    },
    {
      id: 0,
      date: "October 27, 2024",
      title: "Interface Update",
      content: "Implemented a comprehensive UI refresh focusing on readability and visual appeal. New animations enhance the user experience while maintaining functionality.",
      category: "Enhancement"
    },
    {
      id: 1,
      date: "October 26, 2024",
      title: "Custom Item Creation",
      content: "Expanded beyond Throne and Liberty to support custom item creation. Users can now add items from any game with custom stats and traits.",
      category: "Feature Release"
    },
    {
      id: 2,
      date: "October 25, 2024",
      title: "Multi-Guild Support",
      content: "Added support for managing multiple guilds with separate DKP tracking systems. Switch between guilds seamlessly with the new guild management interface.",
      category: "Major Update"
    },
    {
      id: 3,
      date: "October 23, 2024",
      title: "Enhanced Member Management",
      content: "Streamlined guild management with improved member approval, role assignment, and attendance tracking capabilities. Simplified administrative tasks for guild leaders.",
      category: "Feature Release"
    },
    {
      id: 5,
      date: "October 18, 2024",
      title: "Boss Loot Tables",
      content: "Implemented comprehensive boss loot tables, consolidating drop information into a single, accessible location.",
      category: "Enhancement"
    },
    {
      id: 6,
      date: "October 16, 2024",
      title: "Automated DKP System",
      content: "Introduced automated DKP management. Configure once and let the system handle point calculations and distribution automatically.",
      category: "Feature Release"
    },
    {
      id: 7,
      date: "October 14, 2024",
      title: "GDKP and Roll Systems",
      content: "Added dual loot distribution options: GDKP for gold-based distribution and traditional roll system for simplicity.",
      category: "Core Feature"
    },
    {
      id: 9,
      date: "October 12, 2024",
      title: "Real-Time Bidding",
      content: "Implemented real-time bid updates, enhancing the GDKP experience with live bid tracking and notifications.",
      category: "Core Feature"
    },
    {
      id: 10,
      date: "October 11, 2024",
      title: "Security Enhancement",
      content: "Integrated Firebase authentication to ensure secure guild data management and user access control.",
      category: "Security"
    },
    {
      id: 11,
      date: "October 11, 2024",
      title: "Mobile Responsiveness",
      content: "Optimized the interface for mobile devices and tablets, enabling convenient access during raid breaks.",
      category: "Enhancement"
    }
  ];

  const groupedNews: GroupedNews = newsItems.reduce((acc: GroupedNews, item: NewsItem) => {
    const month = new Date(item.date).getMonth();
    if (!acc[month]) acc[month] = [];
    acc[month].push(item);
    return acc;
  }, {});

  const months = Object.keys(groupedNews).map(Number).sort((a, b) => b - a);
  const [selectedMonth, setSelectedMonth] = useState<number>(months[0] || 0);

  const getCategoryColor = (category: string): string => {
    const colors: CategoryColors = {
      'Major Feature': '#4caf50',     // Green
      'Feature Release': '#2196f3',   // Blue
      'Core Feature': '#9c27b0',      // Purple
      'Enhancement': '#ff9800',       // Orange
      'Security': '#f44336',          // Red
      'Update': '#795548',           // Brown
      'Full App Overhaul': '#e91e63', // Pink
      'Feature Update': '#009688',    // Teal
      'Bot Update': '#673ab7',        // Deep Purple
      'Major Update': '#3f51b5',      // Indigo
      'UI Enhancement': '#00bcd4',    // Cyan
      'Role Update': '#8bc34a'        // Light Green
    };
    return colors[category] || '#757575'; // Default grey color for unknown categories
  };

  return (
    <Box sx={{
      position: 'relative',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      pt: 4
    }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            textAlign: 'center',
            fontWeight: 800,
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            mb: 4
          }}
        >
          Development Timeline
        </Typography>

        <Tabs
          value={months.indexOf(selectedMonth)}
          onChange={(_, index) => setSelectedMonth(months[index])}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ mb: 4 }}
        >
          {months.map((month, index) => (
            <Tab
              key={month}
              label={new Date(2024, month).toLocaleString('default', { month: 'long' })}
              value={index}
            />
          ))}
        </Tabs>

        <Timeline position="alternate">
          {groupedNews[selectedMonth]?.map((item: NewsItem, index: number) => (
            <Fade in={true} timeout={500 + (index * 100)} key={item.id}>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={{ bgcolor: getCategoryColor(item.category) }} />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 3,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: 2,
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        transition: 'transform 0.3s ease-in-out'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="caption" color="textSecondary">
                        {item.date}
                      </Typography>
                      <Chip
                        label={item.category}
                        size="small"
                        sx={{
                          bgcolor: getCategoryColor(item.category),
                          color: 'white'
                        }}
                      />
                    </Box>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.content}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </Fade>
          ))}
        </Timeline>
      </Container>
    </Box>
  );
};

export default News;