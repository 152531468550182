//src\components\sessions\detail\LootMasterPanel.tsx

import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface LootMasterPanelProps {
  selectedGameMode: "Throne and Liberty" | "Custom";
  searchTerm: string;
  filteredItems: any[];
  onGameModeChange: (mode: "Throne and Liberty" | "Custom") => void;
  onSearchChange: (term: string) => void;
  onAddItem: (item: any) => void;
}

export const LootMasterPanel: React.FC<LootMasterPanelProps> = ({
  selectedGameMode,
  searchTerm,
  filteredItems,
  onGameModeChange,
  onSearchChange,
  onAddItem
}) => {
  const theme = useTheme();

  const handleCustomItemClick = () => {
    onGameModeChange("Custom");
    onAddItem({
      name: "",
      imageFilename: "legendarysword.png",
      isCustom: true
    });
  };
  
  const handleItemClick = (item: any) => {
    onAddItem({
      name: item.name,
      itemName: item.name,
      itemImage: `/images/new/${item.imageFilename}`,
      url: item.url,
      isCustom: false
    });
  };

  return (
    <Box sx={{ bgcolor: theme.palette.background.paper, borderRadius: 2, p: 4, boxShadow: 3, mb: 3, minHeight: '250px' }}>
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mb: 3 }}>
        <Button
          variant="contained"
          sx={{ fontWeight: 'bold', px: 3 }}
          onClick={() => onGameModeChange("Throne and Liberty")}
        >
          Throne and Liberty
        </Button>
        <Button
          variant="contained"
          sx={{ fontWeight: 'bold', px: 3 }}
          onClick={handleCustomItemClick}
        >
          Custom
        </Button>
      </Box>

      <Box sx={{
        maxHeight: '300px',
        overflowY: 'scroll',
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: 1,
        p: 2,
        bgcolor: theme.palette.background.default,
      }}>
        <input
          type="text"
          placeholder="Search for items..."
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
          style={{
            width: '100%',
            padding: '8px',
            marginBottom: '8px',
            borderRadius: '4px',
            border: '1px solid #ccc',
          }}
        />

        {filteredItems.map((item, index) => (
          <Box
            key={`${item.name}-${index}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 1,
              mb: 2,
              bgcolor: theme.palette.background.paper,
              borderRadius: 1,
              boxShadow: 1,
            }}
          >
            <Box
              component="img"
              src={`/images/new/${item.imageFilename}`}
              alt={item.name}
              sx={{ width: 50, height: 50, borderRadius: 1, mr: 2 }}
            />
            <Box sx={{ flex: 1, mr: 2 }}>
              <Typography
                component="a"
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
                sx={{
                  fontWeight: 'bold',
                  color: 'primary.main',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' }
                }}
              >
                {item.name}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              onClick={() => handleItemClick(item)}
              sx={{
                whiteSpace: 'nowrap',
                padding: '4px 8px',
                fontSize: '0.875rem',
                color: theme.palette.primary.main,
              }}
            >
              Add to Drop Table
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
